import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";
import styles from "../ViewUser/CreateUser.module.scss";
import { useMaster } from "../../../utils/hooks";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import { dropDownOptionArray } from "../../../utils/helper";
import { pattern } from "../../../utils/validationPattern";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PropTypes from "prop-types";

function FormLayout({ control, view, isEdit }) {
  const { useGetRoles } = useMaster();

  const { data: rolesList, isLoading: isRolesListLoading } = useGetRoles();

  const rolesOption = dropDownOptionArray(
    rolesList?.results,
    "roleName",
    "roleID"
  );

  return (
    <Box className={styles.Form}>
      <Grid container spacing={10}>
        <Grid item xs={5.5} sm={5} md={4} lg={3.5}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              validate: (value) => {
                if (value) {
                  if (!pattern.noSpace.test(value)) {
                    return "Space is not allowed";
                  }
                  if (!pattern.email.test(value)) {
                    return "Invalid email address";
                  }
                }
                return undefined;
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                // placeholder="Enter Email"
                label="Email"
                type="text"
                size="medium"
                disabled={view || isEdit}
                // precontent={<EmailOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={5.5} sm={5} md={4} lg={3.5}>
          <Controller
            name="role"
            control={control}
            rules={{
              required: "Role is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <SearchableDropDown
                {...field}
                // placeholder="Select Role"
                label="User Role"
                options={rolesOption}
                isLoading={isRolesListLoading}
                // precontent={<ManageAccountsOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                isDisabled={view}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={10}>
        <Grid item xs={5.5} sm={5} md={4} lg={3.5}>
          <Controller
            name="fName"
            control={control}
            rules={{
              required: "First name is required",
              minLength: {
                value: 3,
                message: "First Name must Contain min 3 characters",
              },
              maxLength: {
                value: 50,
                message: "First name cannot exceed 50 characters",
              },
              validate: (value) => {
                if (value) {
                  if (!pattern.noSpace.test(value)) {
                    return "Space is not allowed";
                  }
                  if (!pattern.name.test(value)) {
                    return "Enter a valid name";
                  }
                }
                return undefined;
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                // placeholder="Enter First Name"
                label="First Name"
                type="text"
                size="medium"
                // precontent={<PersonOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                disabled={view}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={5.5} sm={5} md={4} lg={3.5}>
          <Controller
            name="lName"
            control={control}
            rules={{
              required: "Last name is required",
              maxLength: {
                value: 50,
                message: "Last name cannot exceed 50 characters",
              },
              validate: (value) => {
                if (value) {
                  if (!pattern.noSpace.test(value)) {
                    return "Space is not allowed";
                  }
                  if (!pattern.name.test(value)) {
                    return "Enter a valid name";
                  }
                }
                return undefined;
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                // placeholder="Enter Last Name"
                label="Last Name"
                type="text"
                size="medium"
                // precontent={<PersonOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                disabled={view}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={10}>
        <Grid item xs={5.5} sm={5} md={4} lg={3.5}>
          <Controller
            name="contact"
            control={control}
            rules={{
              pattern: {
                value: pattern.phoneRegex,
                message: "Enter valid phone number",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                // placeholder="Enter Phone Number"
                label="Phone Number"
                // precontent={<LocalPhoneOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                onChange={(e) => {
                  if (pattern.phoneFieldRegex.test(e.target.value)) {
                    field.onChange(e);
                  }
                }}
                size="medium"
                disabled={view}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

FormLayout.propTypes = {
  control: PropTypes.object.isRequired,
  view: PropTypes.bool,
  isEdit: PropTypes.bool,
};

export default FormLayout;
