import React, { useState, createContext, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateUserProfileData } from "../../controllers/profile";
import { toast } from "react-toastify";
import { isTokenExpired } from "../../helper";
import { TokenExpiry } from "../Auth/TokenExpiry";
import PropTypes from "prop-types";

const ProfileContext = createContext();

export const useProfileContext = () => useContext(ProfileContext);

const useProfile = () => {
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const [showPasswordField, setShowPasswordField] = useState(false);
  const [formField, setFormField] = useState({
    fName: "",
    lName: "",
    contact: "",
  });

  // for adding the error message for the input fields.
  const [inputError, setInputError] = useState({
    passwordError: false,
    confirmPasswordError: false,
    passwordMatch: false,
  });

  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [toggleIcon, setToggleIcon] = useState({
    passwordIcon: false,
    confirmPasswordIcon: false,
  });

  const [enableButton, setEnableButton] = useState(true);

  const queryClient = useQueryClient();

  /**
   * Handles the mutation of user profile data and performs actions based on the success or failure of the mutation.
   * @param {{Function}} mutateUserProfileData - The function to mutate the user profile data.
   */
  const { mutate: mutateUserProfileData } = useMutation(updateUserProfileData, {
    onSuccess: (data) => {
      setShowPasswordField(false);
      if (isTokenExpired(data?.data?.ResponseCode)) {
        TokenExpiry(data?.data?.ResponseCode);
      } else if ( data?.data?.responseCode === 2090) {
        toast.success(data?.data?.messages[0]);
        setEnableButton(true);
        queryClient.refetchQueries("user-details");
      } else {
        toast.success("Password Updated Successfully");
        setEnableButton(true);
        queryClient.refetchQueries("user-details");
      }
      setPassword((prevPassword) => ({
        ...prevPassword,
        currentPassword: "",
        newPassword: "",
      }));
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message);
    },
  });

  /**
   * Handles the user profile data by calling the mutateUserProfileData function with the provided data and userId.
   * @param {any} data - The user profile data to be handled.
   * @param {string} userId - The ID of the user whose profile data is being handled.
   */
  const handleUserProfileData = (data, userId) => {
    mutateUserProfileData({ data, userId });
  };

  return {
    password,
    setPassword,
    showPasswordField,
    setShowPasswordField,
    formField,
    setFormField,
    inputError,
    setInputError,
    passwordMatch,
    setPasswordMatch,
    toggleIcon,
    setToggleIcon,
    handleUserProfileData,
    passwordRequired,
    setPasswordRequired,
    enableButton,
    setEnableButton,
  };
};

export function ProvideProfile({ children }) {
  const profileState = useProfile();
  return (
    <ProfileContext.Provider value={profileState}>
      {children}
    </ProfileContext.Provider>
  );
}
ProvideProfile.propTypes = {
  children: PropTypes.node.isRequired,
};
