import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable';
import { covertDate, dropDownOptionArray, setHeaderProp } from "../../utils/helper";
import { UseUserArchiveListContext } from "../../utils/hooks/Archive";
import { useDebounce } from "../../utils/useDebounce";
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { BasicModal } from '../../components/Modal/Modal';
import styles from "./Archive.module.scss";
import CustomButton from "../../components/Button";
import SectionHeader from '../../components/SectionHeader';
import { SearchableDropDown } from '../../components/SearchableDropdown';
import { Box } from "@mui/material";
import ClientArchiveTable from "./clientArchiveTable";
import ArchiveContactsTable from "./archiveContactsTable";
import ArchiveRequestTable from "./archiveRequestTable";
import { useQuery, useMutation, useQueryClient } from "react-query";

export default function ArchiveTable() {
    const [dropDownName, setDropDownName] = useState("");

    const queryClient = useQueryClient();

    const { useGetUserArchiveList, storeArchiveData, setStoreArchiveData, handleUserUnarchivingData, handleClientList, handleClientContactList, handleArchiveTicketListData } = UseUserArchiveListContext();

    const debouncedSearchValue = useDebounce(storeArchiveData.search, 1000);

    const role = {
        roleName: ""
    }

    const { data: userArchiveListData, isLoading: isUserArchiveListLoading } = useGetUserArchiveList(
        storeArchiveData.page + 1,
        storeArchiveData.count,
        debouncedSearchValue,
        role.roleName
    );

    useEffect(() => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            userArchiveData: userArchiveListData?.results,
        }))

    }, [userArchiveListData, dropDownName])


    let changeBackGround = 7,
        prev = 0,
        toogle = 0;
    const setCellProp = (e) => {
        if (prev === changeBackGround) {
            prev = 0;
            toogle++;
        }
        prev++;
        return {
            style: {
                left: "0",
                background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
                zIndex: 100,
                padding: "0!important",
                textTransform: "capitalize",
                textAlign: "center",
                color: "var(--primary-color)",
                fontWeight: "500",
            },
        };
    };

    /**
  * useEffect hook that resets the page to 0 whenever the debounced search value or roleName changes.
  * @returns None
  */
    useEffect(() => {
        if (storeArchiveData.page > 0) {
            setStoreArchiveData((prevState) => ({
                ...prevState,
                page: 0
            }))
        }
    }, [debouncedSearchValue]);

    const cellOptions = {
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
    };

    const handleChangePage = (_page) => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            page: _page
        }))
    };
    const handleChangePageCount = (_pageCount) => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            count: _pageCount
        }))
    };

    const handleUserUnarchiveModal = (value, userDetails) => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            userName: userDetails.firstName,
            userDetails,
            modalOpen: value + 1
        }))
    }

    const handleModalClose = () => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            // userName: "",
            modalOpen: false
        }))
    }

    const handleUserUnarchiving = () => {
        const userData = storeArchiveData.userDetails

        const requestPayload = {
            "roles": [userData.roleName],
            "fName": userData.firstName,
            "lName": userData.lastName,
            "contact": userData.phone,
            "email": userData.email,
            "isActive": true,
            "status": "Active",
        }

        handleUserUnarchivingData(requestPayload, userData.userId)
    }

    const archiveTableOptions = [
        {
            label: "Requests",
            value: 1
        },
        {
            label: "Clients",
            value: 2
        },
        {
            label: "Client Contacts",
            value: 3
        },
        {
            label: "Users",
            value: 4
        }
    ]

    /**
 * Extracts relevant filter data from the ticketFilter object and assigns default values if necessary.
 
 */
    const filterData = {
        clientName: null,
        ticketStatus: null,
        dueDate: null,
        assignee: null,
    };

    const mode = "list";
    const ticketPage = storeArchiveData.page + 1;
    const ticketPageCount = storeArchiveData.count;
    const ticketSortOrder = { name: "dueDate", direction: "asc" };

    const handleDropdown = (label) => {
        setDropDownName(label);
        const data = {
            page: storeArchiveData.page + 1,
            tableCount: storeArchiveData.count,
            searchValue: debouncedSearchValue
        }

        if (label === "Clients") {
            handleClientList(data)
        } else if (label === "Client Contacts") {
            handleClientContactList(data);
        } else if (label === "Requests") {
            handleArchiveTicketListData(mode, ticketPage, ticketPageCount, ticketSortOrder, debouncedSearchValue, filterData);
        } else {
            queryClient.refetchQueries("userArchiveList");
        }
    }


    /**
    * An array of column configurations for a table component.
    * @type {Array}
    */
    const columns = [
        {
            name: "roleName",
            label: "Role",
            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRenderLite: (value) => {
                    const roleName = storeArchiveData?.userArchiveData?.[value]?.roleName;
                    return (
                        <p>{roleName}</p>
                    );
                },
            },
        },
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRenderLite: (value) => {
                    const firstName = storeArchiveData?.userArchiveData?.[value]?.firstName;
                    return (
                        <p>{firstName}</p>
                    );
                },
            },
        },
        {
            name: "lastname",
            label: "Last Name",
            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRenderLite: (value) => {
                    const lastName = storeArchiveData?.userArchiveData?.[value]?.lastName;
                    return (
                        <p>{lastName}</p>
                    );
                },
            },
        },
        {
            name: "phone",
            label: "Phone Number",
            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRenderLite: (value) => {
                    const phoneNumber = storeArchiveData?.userArchiveData?.[value]?.phone;
                    return (
                        <p>{phoneNumber}</p>
                    );
                },
            },
        },
        {
            name: "email",
            label: "Email Address",
            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRenderLite: (value) => {
                    const emailAddress = storeArchiveData?.userArchiveData?.[value]?.email;
                    return (
                        <p>{emailAddress}</p>
                    );
                },
            },
        },
        {
            name: "deletedAt",
            label: "Archived Date",

            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRender: (value) => {
                    return <p>{covertDate(value)}</p>;
                },
            },
        },
        {
            name: "firstName",
            label: "Unarchive",
            options: {
                filter: false,
                sort: false,
                ...cellOptions,
                customBodyRenderLite: (value) => {
                    const userDetails = storeArchiveData?.userArchiveData?.[value];
                    return (
                        <UnarchiveOutlinedIcon onClick={() => handleUserUnarchiveModal(value, userDetails)} sx={{ cursor: "pointer" }} />
                    );
                },
            },
        },
    ];

    return (
        <>
            <BasicModal open={storeArchiveData.modalOpen} maxWidth="lg">
                <div className={styles.modalContent}>
                    <p>Are you sure you want to unarchive this user - <span>"{storeArchiveData.userName}"</span>?</p>
                    <Box sx={{ display: "flex" }}>
                        <CustomButton
                            sx={{ marginTop: "10px" }}
                            style={{ color: "#f00", fontWeight: "500", marginRight: "10px" }}
                            variant="text"
                            onClick={handleModalClose}
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            sx={{ marginTop: "10px" }}
                            onClick={handleUserUnarchiving}
                        >
                            Yes
                        </CustomButton>
                    </Box>
                </div>
            </BasicModal>
            <SectionHeader placeholder='Search' searchText={storeArchiveData.search} handleSearchValue={(e) => {
                setStoreArchiveData((prevState) => ({
                    ...prevState,
                    search: e.target.value
                }))
            }}>
                <Box sx={{ width: "260px" }}>
                    <Box>
                        <SearchableDropDown
                            placeholder="- Please Select -"
                            onChange={(e) => { handleDropdown(e?.label) }}
                            defaultValue={archiveTableOptions[3]}
                            options={archiveTableOptions}
                            getOptionLabel={(option) => option?.label}
                        />
                    </Box>
                </Box>
            </SectionHeader>
            {dropDownName === "Clients" ? (
                <ClientArchiveTable
                    onChangePage={handleChangePage}
                    onChangeCountPerPage={handleChangePageCount}
                    handleClientList={handleClientList}
                    debouncedSearchValue={debouncedSearchValue}
                />
            ) : dropDownName === "Client Contacts" ? (
                <ArchiveContactsTable
                    onChangePage={handleChangePage}
                    onChangeCountPerPage={handleChangePageCount}
                    handleClientContactList={handleClientContactList}
                    debouncedSearchValue={debouncedSearchValue}
                />
            ) : dropDownName === "Requests" ? (
                <ArchiveRequestTable
                    onChangePage={handleChangePage}
                    onChangeCountPerPage={handleChangePageCount}
                    handleArchiveTicketListData={handleArchiveTicketListData}
                    debouncedSearchValue={debouncedSearchValue}
                />
            ) : (
                <CustomTable
                    data={userArchiveListData?.results || []}
                    columns={columns}
                    noDataText="No Data Found"
                    isLoading={isUserArchiveListLoading}
                    pagination
                    paginationServer
                    totalCount={userArchiveListData?.totalActiveRecords || 0}
                    page={storeArchiveData.page}
                    countPerPage={storeArchiveData.count}
                    onChangePage={handleChangePage}
                    onChangeCountPerPage={handleChangePageCount}
                />
            )}
        </>
    )
}
