import React, { useState, useEffect } from "react";
import Organization from "./Organization";
import { useCustomerDetailsContext } from "../../utils/hooks/Auth/Organization";
import { useAuth } from "../../utils/hooks";

export default function Organizationwrapper() {
  const { mutateCustomerCode } = useCustomerDetailsContext();
  const { setOrganizationCode } = useAuth();

  // states for the input fields.
  const [formData, setFormData] = useState({
    organizationName: "",
  });

  // for adding the error message for the input fields.
  const inputError = {
    organizationError: false,
  };

  // for adding the error message.
  const [errorMessage, setErrorMessage] = useState(false);

  // for button enable/disable.
  const [loginButtonEnabled, setLoginButtonEnabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validation rules
    // Set form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset general error message
    setErrorMessage(false);
  };

  // use effect to check for empty fields.
  useEffect(() => {
    if (formData.organizationName === "") {
      setLoginButtonEnabled(true);
    } else {
      setLoginButtonEnabled(false);
    }
  }, [formData, inputError]);

  const handleFormSubmission = (event) => {
    // Disallow space at the beginning
    if (event.key === " ") {
      event.preventDefault();
      return false;
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleCustomerCode();
    }
  };

  const handleCustomerCode = () => {
    setOrganizationCode(formData.organizationName);
    mutateCustomerCode(formData.organizationName);
    localStorage.setItem("org_name", formData.organizationName);
  };

  return (
    <div>
      <Organization
        handleInputChange={handleInputChange}
        formData={formData}
        errorMessage={errorMessage}
        inputError={inputError}
        handleFormSubmission={handleFormSubmission}
        loginButtonEnabled={loginButtonEnabled}
        handleCustomerCode={handleCustomerCode}
      />
    </div>
  );
}
