import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import SectionHeader from "../../components/SectionHeader";
import { SearchableDropDown } from "../../components/SearchableDropdown";
import { EditUnderLineIcon } from "../../assets/Icons/EditIcon";
import ThreeDotMenu from "../../components/ThreeDotMenu";
import { ThreeDots } from "../../assets/Icons/ThreeDot";
import { ModifyIcon } from "../../assets/Icons/ModifyIcon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { IconButton, Box } from "@mui/material";
import styles from "./UserManagement.module.scss";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../utils/useDebounce";
import { useAuth, useMaster, useUser } from "../../utils/hooks";
import { dropDownOptionArray, setHeaderProp } from "../../utils/helper";
import BulkUploadUsers from "./BulkUploadUsers";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CustomButton from "../../components/Button";
import { BasicModal } from "../../components/Modal/Modal";
import { useUserBulkUpload } from "../../utils/hooks/User/BulkUpload";
import BulkUploadUserModal from "./BulkUploadUserModal";
import { TrashIcon } from "../../assets/Icons/TrashIcon";
import DeleteModal from "../../components/DeleteModal";
import ErrorModal from "../../components/DeleteModal/ErrorModal";

const UserManagementTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [roleName, setRoleName] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    setViewUser,
    useGetUserList,
    setUserId,
    openDeleteModal,
    setOpenDeleteModal,
    mutateDeleteUser,
    isDeleteUserLoading,
    errorText,
    setErrorText,
  } = useUser();
  const { useGetRoles } = useMaster();
  const { data: rolesList, isLoading: isRolesListLoading } = useGetRoles();

  const { bulkUploadData } = useUserBulkUpload();

  const rolesOption = dropDownOptionArray(
    rolesList?.results,
    "roleName",
    "roleName"
  );

  const debouncedSearchValue = useDebounce(search, 1000);

  const { data: userListData, isLoading: isUserListLoading } = useGetUserList(
    page + 1,
    count,
    debouncedSearchValue,
    roleName?.value || null
  );

  /**
   * useEffect hook that resets the page to 0 whenever the debounced search value or roleName changes.
   * @returns None
   */
  useEffect(() => {
    if (page > 0) {
      setPage(0);
    }
  }, [debouncedSearchValue, roleName]);

  /**
   * Array of objects representing menu items with name, onClickFunction, icon, and hide properties.
   * @type {Array<{name: string, onClickFunction: Function, icon: JSX.Element, hide: boolean}>}
   */
  const threeDotMenuItem = [
    {
      name: "View Details",
      onClickFunction: () => {
        setAnchorEl(null);
        setViewUser(true);
        setUserId(selectedRow?.userId);
      },
      icon: (
        <RemoveRedEyeOutlinedIcon
          sx={{ color: "#565E6DFF", fontSize: "20px" }}
        />
      ),
    },
    {
      name: "Edit Details",
      onClickFunction: () => {
        navigate(`edit/${selectedRow?.userId}`);
        setAnchorEl(null);
      },
      icon: <EditUnderLineIcon />,
      hide: `${user?.UserID}` === `${selectedRow?.userId}`,
    },
  ];
  const handleOpenRowMenu = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseThreeDotMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleModify = (rowData) => {
    const data = JSON.parse(localStorage.getItem("user-details"));
    if (rowData?.email !== data?.email) navigate(`edit/${rowData?.userId}`);
    // setAnchorEl(null);
  };

  /**
   * the variable below is storing the blob url for the csv template which is then used in the below function to trigger the download.
   */
  const csvData =
    "https://dmindsblobstorage.blob.core.windows.net/dminds-dev/upload_users_template_3_090830920.csv";
  /**
   * the function below is triggering the download of the csv template. it creates an a element  with href attribute and click event.
   * @returns
   */


  const handleCsvDownload = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/upload_users_template_3_090830920.csv`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = "upload_users_template.csv";
    link.click();
  };

  let changeBackGround = 6,
    prev = 0,
    toogle = 0;
  const setCellProp = (e) => {
    if (prev === changeBackGround) {
      prev = 0;
      toogle++;
    }
    prev++;
    return {
      style: {
        left: "0",
        background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
        zIndex: 100,
        padding: "0!important",
        textTransform: "capitalize",
        textAlign: "center",
        color: "var(--primary-color)",
        fontWeight: "500",
      },
    };
  };

  const cellOptions = {
    setCellProps: setCellProp,
    setCellHeaderProps: setHeaderProp,
  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedRow(row);
    setOpenDeleteModal(true);
  };

  const handleDeleteUser = () => {
    const finalData = {
      id: selectedRow.userId,
      status: "Archived",
      isActive: false,
      fName: selectedRow.firstName,
      lName: selectedRow.lastName,
      email: selectedRow.email,
      contact: selectedRow.phone,
      roles: [selectedRow.roleName],
    };
    mutateDeleteUser(finalData);
  };

  const columns = [
    {
      name: "roleName",
      label: "Role Name",
      options: cellOptions,
    },
    {
      name: "firstName",
      label: "First Name",
      options: cellOptions,
    },
    {
      name: "lastName",
      label: "Last Name",
      options: cellOptions,
    },
    {
      name: "phone",
      label: "Phone Number",
      options: cellOptions,
    },
    {
      name: "email",
      label: "User Email Address",
      options: cellOptions,
    },
    {
      name: "action",
      label: "Action",
      options: {
        ...cellOptions,
        customBodyRender: (value, tableMeta) => {
          const rowData = userListData?.results[tableMeta.rowIndex];
          return (
            <>
              {`${user?.UserID}` !== `${rowData?.userId}` && (
                <>
                  <IconButton onClick={() => handleModify(rowData)}>
                    <ModifyIcon />
                  </IconButton>
                  <IconButton onClick={(e) => handleDeleteClick(e, rowData)}>
                    <TrashIcon />
                  </IconButton>
                </>
              )}
              <IconButton onClick={(e) => handleOpenRowMenu(e, rowData)}>
                <ThreeDots />
              </IconButton>
              <ThreeDotMenu
                id={`User_table_features_${rowData?.userId}`}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleCloseThreeDotMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                menuItem={threeDotMenuItem}
              />
            </>
          );
        },
      },
    },
  ];

  const Navigate = useNavigate();
  const onClickNewUser = () => {
    Navigate("/user/add");
  };

  const handleChangePage = (_page) => {
    setPage(_page);
  };
  const handleChangePageCount = (_pageCount) => {
    setCount(_pageCount);
    const totalPages = Math.ceil(userListData?.totalActiveRecords / _pageCount)

    if(page >= totalPages) {
      setPage(totalPages - 1)
    }
  };

  return (
    <Box className={styles.user_management_dashboard}>
      <BasicModal open={bulkUploadData.showBulkUploadModal}>
        <BulkUploadUserModal />
      </BasicModal>
      <SectionHeader
        buttonText="Add New User"
        onButtonClick={onClickNewUser}
        searchText={search}
        handleSearchValue={(e) => setSearch(e.target.value)}
      >
        <Box className={styles.Section_container}>
          <Box className={styles.Section_dropdown_container}>
            <SearchableDropDown
              placeholder="Role Type"
              value={roleName}
              onChange={(e) => {
                setRoleName(e);
              }}
              isClearable
              options={rolesOption}
              isLoading={isRolesListLoading}
              getOptionLabel={(option) => option?.name}
            />
          </Box>
        </Box>
      </SectionHeader>
      <div className={styles.bulkUploadBtnWrapper}>
        <CustomButton onClick={handleCsvDownload}>
          <DownloadForOfflineIcon />
          Download Template
        </CustomButton>
        <BulkUploadUsers />
      </div>

      <CustomTable
        data={userListData?.results || []}
        columns={columns}
        isLoading={isUserListLoading}
        noDataText={
          debouncedSearchValue || roleName ? "No Users Found" : "No Users Added"
        }
        pagination
        paginationServer
        totalCount={userListData?.totalActiveRecords || 0}
        page={page}
        countPerPage={count}
        onChangePage={handleChangePage}
        onChangeCountPerPage={handleChangePageCount}
      />
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          handleCancel={() => setOpenDeleteModal(false)}
          handleDeleteClick={handleDeleteUser}
          primaryText={`Are you sure want to delete this user? - "${selectedRow.firstName} ${selectedRow.lastName}"`}
          isButtonDisabled={isDeleteUserLoading}
        />
      )}
      {errorText && (
        <ErrorModal
          open={!!errorText}
          handleClose={() => setErrorText("")}
          errorText={errorText}
        />
      )}
    </Box>
  );
};

export default UserManagementTable;
