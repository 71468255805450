import { getApi, postApi, putApi } from "../apis";

/**
 * post api to get all the user's archive list with pagination
 */
export const getUserArchiveListPagination = (page, count, search, filterData) =>
    postApi(`user/archiveList?page=${page}&count=${count}&search=${search}`, filterData);

/**
* put api to edit the user details by id
*/
export const unarchiveUser = (data) => {
    const userId = data.userId;
    const userData = data.data;
    return putApi(`user?userId=${userId}`, userData);
};

/**
 * get api to fetch the archived clients list.
 */

export const fetchArchivedClientList = (tableData) => {
    const page = tableData.data.page;
    const count = tableData.data.tableCount;
    const search = tableData.data.searchValue;
    return getApi(`client/all/archivedclients?page=${page}&count=${count}&search=${search}`);
}

/**
 * get api to fetch the archived clients contacts list.
 */

export const fetchArchivedClientContactsList = (tableData) => {
    const page = tableData.data.page;
    const count = tableData.data.tableCount;
    const search = tableData.data.searchValue;
    return getApi(`client/all/archivedcontact?page=${page}&count=${count}&search=${search}`);
}

/**
 * post api to get the ticket list.
 */
export const getArchiveTicketList = (
    finalData
) => {
    const filterData = {
        assignee: finalData.finalData.assignee,
        clientName: finalData.finalData.clientName,
        dueDate: finalData.finalData.dueDate,
        ticketStatus: finalData.finalData.ticketStatus
    }
    return postApi(`Ticket/allArchive?mode=${finalData.finalData.mode}&page=${finalData.finalData.ticketPage}&count=${finalData.finalData.ticketPageCount}&search=${finalData.finalData.debouncedSearchValue}&searchColumn=${finalData.finalData.ticketSortOrder.name === "ticketStatus" ? "status" : finalData.finalData.ticketSortOrder.name}&sortType=${finalData.finalData.ticketSortOrder.direction}`, filterData);
}


/**
 * edit api to unarchive a particula ticket entry.
 */
export const unarchiveTicket = (data) => {
    const ticketId = data.ticketId;
    const post = {
        status: data.data.status,
        ticketOwnersDetails: data.data.ticketOwnersDetails
    }
    return putApi(`Ticket?ticketId=${ticketId}`, post);
};

/**
 * Unarchive client data by sending a PUT request to the API with the updated client information.
 */
export const unarchiveClientData = (data) => {
    const id = data.clientId;
    const clientData = data.data;
    return putApi(`Client?ClientId=${id}`, clientData);
};

/**
 * Edits the contact archived data for a client with the given ID.
 */
export const editClientContactArchiveData = (data) => {
    const id = data.clientId;
    const contact = {
        clientContactId: data.data.clientContactId,
        firstName: data.data.firstName,
        isActive: true,
        lastName: data.data.lastName,
        phone: data.data.phone,
        status: "Active"
    }

    return putApi(`Client/contact?clientId=${id}`, contact);
};



