import React, { useContext, createContext, useState } from "react";

import { clientBulkUpload } from "../../controllers/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TokenExpiry } from "../Auth/TokenExpiry";
import { isTokenExpired } from "../../helper";

const ClientBulkUploadContext = createContext();

export const useClientBulkUpload = () => useContext(ClientBulkUploadContext);

function useProvideClientBulkUpload() {

    const [bulkUploadData, setBulkUploadData] = useState({
        showBulkUploadModal: false,
        storeCsvFile: null
    })

    const queryClient = useQueryClient();

    const { mutate: mutateCreateClientBulkUpload, isLoading: isCreateClientLoading } =
        useMutation(clientBulkUpload, {
            onSuccess: (data) => {
                const status = data.data;
                if (status.responseCode === 3010 && status.result === "Successfully Added.") {
                    toast.success("All clients added successfully");
                    setBulkUploadData((prevState) => ({
                        ...prevState,
                        showBulkUploadModal: false,
                        storeCsvFile: null,
                    }));
                    queryClient.refetchQueries("FilteredclientList");
                } else if ((status.responseCode === 3018) || (status.responseCode === 422)) {
                    toast.error(status.errors[0]);
                } else {
                    setBulkUploadData((prevState) => ({
                        ...prevState,
                        showBulkUploadModal: true,
                        storeCsvFile: status.result,
                    }));
                    queryClient.refetchQueries("FilteredclientList");
                }
            },
            onError: (err) => {
                console.log(err);
            },
        });


    return {
        mutateCreateClientBulkUpload,
        bulkUploadData,
        setBulkUploadData
    }

}

export function ProviderClientBulkUpload({ children }) {
    const ClientBulkUploadData = useProvideClientBulkUpload();
    return (
        <ClientBulkUploadContext.Provider value={ClientBulkUploadData}>
            {children}
        </ClientBulkUploadContext.Provider>
    );
}