import React, { useState, useEffect } from "react";
import Forgetpassword from "./Forget-password";
import { useProvideResetRequest } from "../../utils/hooks/Auth/Forgotpassword";
import { pattern } from "../../utils/validationPattern";

export default function Forgotpasswordwrapper() {
    const { modalOpen, setModalOpen, handleResetRequest, resetTimer } = useProvideResetRequest()
    const getOrgName = localStorage.getItem("org_name");

    // states for the input fields.
    const [formData, setFormData] = useState({
        organizationName: getOrgName || "",
        email: "",
    });

    const [inputError, setInputError] = useState({
        organizationError: false,
        emailError: false,
    });

    // for adding the error message.
    const [errorMessage, setErrorMessage] = useState(false);

    const [resendEmailTimer, setResendEmailTimer] = useState(60);
    const [isEmailTimerRunning, setIsEmailTimerRunning] = useState(false);
    const [resendTimer, setResendTimer] = useState(false);


    /**
     * Handles the input change event by updating the input error state, form data, and error message.
     * @param {Event} e - The input change event object.
     * @returns None
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errorState = { ...inputError };
        const trimmedEmail = value.trim();

        // Validation rules
        if (name === "email") {
            errorState.emailError =
                trimmedEmail !== "" ? !pattern.email.test(trimmedEmail) : false;
        }

        // Set error state
        setInputError(errorState);
        // Set form data
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Reset general error message
        setErrorMessage(false);
    };

    /**
     * Starts the email timer by setting the state variable isEmailTimerRunning to true.
     * @returns None
     */
    const startTimer = () => {
        setIsEmailTimerRunning(true);
    };

    /**
     * Resets the email timer by setting the resend email timer to 60 seconds and stopping the timer.
     * @returns None
     */
    const resetEmailTimer = () => {
        setResendEmailTimer(60);
        setIsEmailTimerRunning(false);
    };


    /**
     * useEffect hook that handles the countdown timer for resending an email.
     * It starts the timer when isEmailTimerRunning is true and decrements the timer
     * every second. Once the timer reaches 0, it resets the timer and clears the interval.
     * @param {{boolean}} isEmailTimerRunning - Flag to indicate if the timer is running.
     * @param {{number}} resendEmailTimer - The current value of the timer.
     * @returns None
     */
    useEffect(() => {
        let interval;

        if (isEmailTimerRunning) {
            interval = setInterval(() => {
                setResendEmailTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
            }, 1000);

            if (resendEmailTimer === 0) {
                resetEmailTimer();
                clearInterval(interval);
            }
        }

        return () => clearInterval(interval);
    }, [isEmailTimerRunning, resendEmailTimer]);

    const handleResendTimer = () => {
        startTimer();
    };

    // modal open function
    /**
     * Handles the submission of the email modal form for resetting the password.
     * It creates an object with customer code and email from the form data,
     * checks if the organization name and email are not empty, sets an error message if they are,
     * and calls the handleResetRequest function with the forgotPasswordDetails object.
     * @returns None
     */
    const handleEmailModal = () => {
        const forgotPasswordDetails = {
            customerCode: formData.organizationName,
            email: formData.email
        }
        if (formData.organizationName === '' || formData.email === '') {
            setErrorMessage(true);
        } else {
            handleResetRequest(forgotPasswordDetails)
        }
    }

    /**
     * Handles the resend email functionality by setting a resend timer, preparing the forgot password details
     * object with customer code and email, and then triggering the resend timer and reset request.
     * @returns None
     */
    const handleResendEmail = () => {
        setResendTimer(true)
        const forgotPasswordDetails = {
            customerCode: formData.organizationName,
            email: formData.email
        }
        handleResendTimer()
        handleResetRequest(forgotPasswordDetails)
        // setModalOpen(false)
    }

    // modal close function
    /**
     * Handles the closing of a modal by setting various state variables.
     * This function sets the modalOpen state to false, resets the resend timer state,
     * sets the resend email timer state to 60, and calls functions to reset the timer and email timer.
     * @returns None
     */
    const handleModalClose = () => {
        setModalOpen(false);
        setResendTimer(false);
        setResendEmailTimer(60);
        resetTimer();
        resetEmailTimer();
    };

    /**
     * Handles form submission events by checking if the key pressed is a space and the email field is empty,
     * preventing the default behavior and returning false. If the key pressed is "Enter", it calls the 
     * handleEmailModal function.
     * @param {{Event}} event - The event object triggered by the form submission.
     * @returns None
     */
    const handleFormSubmission = (event) => {
        if (event.key === " " && formData.email.trim() === "") {
            event.preventDefault();
            return false;
        } else if (event.key === "Enter") {
            handleEmailModal();
        }
    }

    return (
        <div>
            <Forgetpassword modalOpen={modalOpen} handleInputChange={handleInputChange} handleModalClose={handleModalClose} handleEmailModal={handleEmailModal} inputError={inputError} formData={formData} errorMessage={errorMessage} handleResendEmail={handleResendEmail} isEmailTimerRunning={isEmailTimerRunning} resendEmailTimer={resendEmailTimer} resendTimer={resendTimer} handleFormSubmission={handleFormSubmission} />
        </div>
    )
}
