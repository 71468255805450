import React, { useState } from "react";
import PropTypes from "prop-types";
import { BasicModal } from "../../../components/Modal/Modal";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import { IconButton } from "@mui/material";
import styles from "./CreateTicketModal.module.scss";
import CustomButton from "../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useTicket } from "../../../utils/hooks";
import { dropDownOptionArray } from "../../../utils/helper";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const DocumentTypeModal = ({
  open,
  handleClose,
  setValue,
  selectedValue,
  option,
  isLoading,
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    selectedValue || []
  );
  const { mutateUpdateTicketDocument } = useTicket();
  const { id } = useParams();

  const filteredDocumentTypeList = option?.filter(
    (item) => item.idrDocument !== "Others"
  );

  const documentTypeOption = dropDownOptionArray(
    filteredDocumentTypeList,
    "idrDocument",
    "idrDocumentID"
  );

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      // If a new option is being created, check its length
      const newOption = actionMeta.option.value;
      if (newOption.length <= 50) {
        setSelectedDocumentType(newValue);
      } else {
        // Notify user or handle the exceeding character limit
        toast.error("Document type cannot exceed 50 character");
      }
    } else {
      setSelectedDocumentType(newValue);
    }
  };

  /**
   * Handles the deletion of document types from the saved document list.
   * @param {{Array}} savedDocumentList - The list of saved documents.
   * @returns None
   */
  const handleDeleteDocumentType = (savedDocumentList) => {
    const savedDocumentIdList = savedDocumentList.map(
      (documentType) => documentType.ticketIDRDocumentID
    );
    const deleteDocumentTypeList = selectedValue.filter(
      (documentType) =>
        !savedDocumentIdList.includes(documentType.ticketIDRDocumentID) &&
        documentType.ticketIDRDocumentID
    );
    const finalData = {
      ticketId: id,
      list: deleteDocumentTypeList.map((documentType) => ({
        ticketDocumentID: documentType.ticketIDRDocumentID,
        isActive: false,
        status: "Deleted",
      })),
    };
    if (deleteDocumentTypeList.length > 0) {
      mutateUpdateTicketDocument(finalData);
    }
  };

  /**
   * Handles saving the selected document types by mapping each selected item to a new object
   * with relevant information. It then updates the "ticketIDRDocuments" value, deletes the selected
   * document types, and closes the modal.
   * @returns None
   */
  const handleSave = () => {
    const selectedDocumentTypeList = selectedDocumentType.map((item) => ({
      ticketIDRDocumentID: selectedValue.find(
        (elem) => elem.value === item.value && elem.label === item.label
      )?.ticketIDRDocumentID,
      idrDocumentID:
        option?.find((docType) => docType.idrDocument === item.label)
          ?.idrDocumentID ||
        option?.find((docType) => docType.idrDocument === "Others")
          ?.idrDocumentID,
      documentName: item.label,
      documents:
        selectedValue.find(
          (elem) => elem.value === item.value && elem.label === item.label
        )?.documents || [],
      received:
        selectedValue.find(
          (elem) => elem.value === item.value && elem.label === item.label
        )?.received || false,
      elseWhere:
        selectedValue.find(
          (elem) => elem.value === item.value && elem.label === item.label
        )?.elseWhere || false,
    }));
    setValue("ticketIDRDocuments", selectedDocumentTypeList);
    handleDeleteDocumentType(selectedDocumentTypeList);
    handleClose();
  };

  return (
    <BasicModal open={open} close={handleClose} maxWidth={false}>
      <div className={styles.documentTypeModal}>
        <div className={styles.documentTypeModalHeader}>
          <p>Add Documents</p>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.documentTypeSelect}>
          <SearchableDropDown
            isMulti
            value={selectedDocumentType}
            type="creatable"
            options={documentTypeOption}
            onChange={handleChange}
            isLoading={isLoading}
            closeMenuOnSelect={false}
          />
        </div>
        <div className={styles.buttonContainer}>
          <CustomButton
            variant="text"
            size="medium"
            onClick={handleClose}
            style={{ color: "#f00" }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            disabled={selectedDocumentType?.length === 0}
            onClick={handleSave}
            size="medium"
          >
            Save
          </CustomButton>
        </div>
      </div>
    </BasicModal>
  );
};

DocumentTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.array.isRequired,
  option: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export default DocumentTypeModal;
