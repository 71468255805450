import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Resetpassword.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { BasicModal } from "../../components/Modal/Modal";
import Commonerrormessage from "../../components/Errormessage";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Stack } from "@mui/material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useAuth, useResetPassword } from "../../utils/hooks";
import { HtmlTooltip } from "../../components/Tooltip";
import PropTypes from "prop-types";
import { Loader } from "../../components/Loader";

const doneIcon = {
  fontSize: "18px",
  paddingTop: "8px",
  marginRight: "4px",
};

const validationTopRow = {
  paddingBottom: "0.5rem",
  color: "#95969D",
};

const validationBottomRow = {
  color: "#95969D",
};

export default function Resetpassword({
  modalOpen,
  handleEmailModal,
  formData,
  handleInputChange,
  errorMessage,
  inputError,
  toggleIcon,
  togglePasswordIcon,
  passwordMatch,
  oneSpecialCharacter,
  oneDigit,
  oneUppercase,
  eightNumber,
  fourteenNumber,
  toggleConfirmPasswordIcon,
  oneLowercase,
  handleResetFormSubmission,
}) {
  const { errorResponseCode, buttonDisabled } = useResetPassword();
  const { useGetConfigData } = useAuth();
  const navigate = useNavigate();
  const [qParams] = useSearchParams();
  const customerCode = qParams.get("code");

  const { data: userConfigData, isLoading: isUserConfigDataLoading } =
    useGetConfigData(customerCode);

  const renderModalContent = () => {
    return (
      <div className={styles.customModalContentWrapper}>
        {errorResponseCode ? (
          <>
            <h6>Link Expired!</h6>
            <p>
              Please click on the button below to request for a new password.
            </p>
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                width: "80%",
                backgroundColor: "var(--secondary-color)",
                borderRadius: "0",
                padding: "8px",
                ":hover": { backgroundColor: "var(--secondary-color)" },
              }}
              onClick={() => {
                navigate("/forgetpassword");
              }}
            >
              Forgot Password
            </Button>
          </>
        ) : (
          <>
            <h6>Password changed!</h6>
            <p>You have successfully reset your Password.</p>
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                width: "80%",
                backgroundColor: "var(--secondary-color)",
                borderRadius: "0",
                padding: "8px",
                ":hover": { backgroundColor: "var(--secondary-color)" },
              }}
              onClick={() => {
                localStorage.removeItem("loginFormData");

                navigate("/");
              }}
            >
              Login Now
            </Button>
          </>
        )}
      </div>
    );
  };

  if (isUserConfigDataLoading) {
    return <Loader />;
  }
  
  return (
    <Container maxWidth="lg">
      <Box className={styles.container} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={5}>
            <div className={styles.imgWrapper}>
              <img
                src={userConfigData?.result?.logoURLReference}
                alt="Placeholder"
              />
            </div>
          </Grid>
          <Grid xs={12} md={7}>
            <div className={styles.formWrapper}>
              <h1>Reset Password</h1>
              <h4>Please set your new password</h4>
              <form className={styles.customFormWrapper}>
                <div className={styles.passwordWrapper}>
                  <h4 className={styles.label}>New password </h4>
                  <input
                    type={toggleIcon.passwordIcon ? "text" : "password"}
                    onChange={handleInputChange}
                    name="password"
                    value={formData.password}
                    onKeyDown={handleResetFormSubmission}
                    placeholder="Enter new password"
                  />
                  {toggleIcon.passwordIcon ? (
                    <HtmlTooltip title="Hide">
                      <VisibilityOffOutlinedIcon
                        sx={{
                          position: "relative",
                          top: "15px",
                          transform: "translateY(-50%)",
                          marginLeft: "-30px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => togglePasswordIcon()}
                      />
                    </HtmlTooltip>
                  ) : (
                    <HtmlTooltip title="View">
                      <VisibilityOutlinedIcon
                        sx={{
                          position: "relative",
                          top: "15px",
                          transform: "translateY(-50%)",
                          marginLeft: "-30px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => togglePasswordIcon()}
                      />
                    </HtmlTooltip>
                  )}
                </div>
                <div className={styles.pwdValidationSection}>
                  <div className={styles.pwdValidationSection1}>
                    <Stack
                      style={validationTopRow}
                      direction="row"
                      ref={oneSpecialCharacter}
                    >
                      <DoneOutlinedIcon style={doneIcon} />
                      <p>One special character</p>
                    </Stack>

                    <Stack
                      style={validationBottomRow}
                      direction="row"
                      ref={oneDigit}
                    >
                      <DoneOutlinedIcon style={doneIcon} />
                      <p>One number</p>
                    </Stack>
                  </div>
                  <div className={styles.pwdValidationSection2}>
                    <Stack
                      style={validationTopRow}
                      direction="row"
                      ref={oneUppercase}
                    >
                      <DoneOutlinedIcon style={doneIcon} />
                      <p>One uppercase</p>
                    </Stack>

                    <Stack
                      style={validationBottomRow}
                      direction="row"
                      ref={eightNumber}
                    >
                      <DoneOutlinedIcon style={doneIcon} />
                      <p>8 characters minimum</p>
                    </Stack>
                  </div>
                  <div className={styles.pwdValidationSection2}>
                    <Stack
                      style={validationTopRow}
                      direction="row"
                      ref={fourteenNumber}
                    >
                      <DoneOutlinedIcon style={doneIcon} />
                      <p>16 characters maximum</p>
                    </Stack>
                    <Stack
                      style={validationTopRow}
                      direction="row"
                      ref={oneLowercase}
                    >
                      <DoneOutlinedIcon style={doneIcon} />
                      <p>One lowercase</p>
                    </Stack>
                  </div>
                </div>
                <div className={styles.passwordWrapper}>
                  <h4 className={styles.label2}>Re-enter password</h4>
                  <input
                    type={toggleIcon.confirmPasswordIcon ? "text" : "password"}
                    onChange={handleInputChange}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onKeyDown={handleResetFormSubmission}
                    placeholder="Re-Enter new password"
                  />
                  {inputError.confirmPasswordError && (
                    <p className={styles.inputFieldErrorMessage}>
                      Enter a valid password *
                    </p>
                  )}
                  {toggleIcon.confirmPasswordIcon ? (
                    <VisibilityOffOutlinedIcon
                      sx={{
                        position: "relative",
                        top: "15px",
                        transform: "translateY(-50%)",
                        marginLeft: "-30px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleConfirmPasswordIcon()}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      sx={{
                        position: "relative",
                        top: "15px",
                        transform: "translateY(-50%)",
                        marginLeft: "-30px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleConfirmPasswordIcon()}
                    />
                  )}
                </div>
                {(passwordMatch.passwordMatchError ||
                  passwordMatch.confirmPasswordMatchError) && (
                  <Commonerrormessage message="Passwords don't match" />
                )}
              </form>
              {errorMessage && (
                <Commonerrormessage message="All fields are required *" />
              )}
              <Button
                disabled={buttonDisabled}
                variant="contained"
                sx={{
                  marginTop: "20px",
                  backgroundColor: "var(--secondary-color)",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "76%",
                  marginLeft: "-30px",
                  ":hover": { backgroundColor: "var(--secondary-color)" },
                }}
                onClick={() => handleEmailModal()}
              >
                Reset Password
              </Button>
            </div>
            <BasicModal open={modalOpen}>{renderModalContent()}</BasicModal>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

Resetpassword.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleEmailModal: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func,
  errorMessage: PropTypes.bool,
  inputError: PropTypes.object,
  toggleIcon: PropTypes.object.isRequired,
  togglePasswordIcon: PropTypes.func.isRequired,
  passwordMatch: PropTypes.object,
  oneSpecialCharacter: PropTypes.object,
  oneDigit: PropTypes.object,
  oneUppercase: PropTypes.bool,
  eightNumber: PropTypes.object,
  fourteenNumber: PropTypes.object,
  toggleConfirmPasswordIcon: PropTypes.func.isRequired,
  oneLowercase: PropTypes.object,
  handleResetFormSubmission: PropTypes.func.isRequired,
};
