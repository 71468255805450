import React from "react";
import styles from "./document-requested.module.scss";

export default function CustomSidebarComponent({logo, name}) {
  return (
    <div className={styles.sideBarComponentWrapper}>
      <div className={styles.logoWrapper}>
        <img src={logo} alt="Logo" />
        <p>{name} - PBC Request</p>
      </div>
    </div>
  );
}
