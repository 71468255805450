import { getApi, postApi } from "../apis";

/**
 * api to get list of roles
 */
export const getRolesList = () => getApi("Master/roles");

/**
 * api to get list of roles
 */
export const getDocumentTypeList = () => getApi("Master/document");

/**
 * api to get list of roles
 */
export const getTicketStatusList = () => getApi("Master/status");

/**
 * api to upload the files
 */
export const masterUpload = (data) => {
  const { organizationCode, formData } = data;
  return postApi(
    `Master/upload?code=${organizationCode}`,
    formData,
    {
      headers: { Authorization: "Bearer undefined" },
    }
  );
};
