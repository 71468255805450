import React, { useCallback, useEffect } from 'react';
import { useSideBarContext } from "../../utils/hooks";
import Header from "./Header";

export default function Headercomponent({ userConfigData }) {
  const { isCompact, setIsCompact } = useSideBarContext();

  /**
   * useEffect hook that retrieves the 'sidebarState' from localStorage and updates the 'isCompact' state accordingly.
   * If 'sidebarState' is found in localStorage, it is parsed as JSON and used to set the 'isCompact' state.
   * The effect runs whenever the 'isCompact' state changes.
   */
  useEffect(() => {
    // Check local storage for the previous state
    const storedState = localStorage.getItem("sidebarState");
    setIsCompact(storedState ? JSON.parse(storedState) : true);
  }, [isCompact]);

  /**
   * Function to open the sidebar by setting the state to false and updating the local storage.
   * @returns None
   */
  const openSidebar = useCallback(() => {
    const newState = false;
    setIsCompact(newState);
    // Save the state to local storage
    localStorage.setItem("sidebarState", JSON.stringify(newState));
  }, [isCompact, setIsCompact]);

  /**
   * Closes the sidebar by setting the sidebar state to true and updating the local storage.
   * @returns None
   */
  const closeSidebar = useCallback(() => {
    const newState = true;
    setIsCompact(newState);
    // Save the state to local storage
    localStorage.setItem("sidebarState", JSON.stringify(newState));
  }, [isCompact, setIsCompact]);

  if (isCompact === null) {
    return null;
  }

  return (
    <div>
      <Header
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        userConfigData={userConfigData}
      />
    </div>
  );
}
