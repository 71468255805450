import React, { forwardRef, useMemo } from "react";
import { Box, FormHelperText, Typography, styled } from "@mui/material";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { withAsyncPaginate, AsyncPaginate } from "react-select-async-paginate";
import EllipsisText from "react-ellipsis-text";
import styles from "./SearchableDropdown.module.scss";

const AvatarLabel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  ".email": {
    fontSize: "14px",
  },
}));

const Control = ({ children, precontent, ...props }) => {
  return (
    <components.Control {...props}>
      {precontent || null}
      {children}
    </components.Control>
  );
};

export const SearchableDropDown = forwardRef(
  (
    {
      label,
      options,
      onChange,
      value,
      placeholder,
      error,
      helperText,
      menuPortalTarget,
      isDisabled,
      avatarLabel,
      isClearable,
      isSearchable = true,
      hideIndicator,
      isMulti,
      getOptionValue,
      getOptionLabel,
      controlShouldRenderValue,
      hideSelectedOptions,
      defaultValue,
      type,
      loadOptions,
      cacheOptions,
      defaultOptions,
      debounceTimeout,
      additional,
      menuHeight = "150px",
      closeMenuOnScroll,
      menuShouldBlockScroll,
      menuPlacement,
      backspaceRemovesValue,
      cacheUniqs,
      noBorder,
      view,
      labelLength = 20,
      emailLength = 20,
      precontent,
      height = "auto",
      ...props
    },
    ref
  ) => {
    const customStyles = {
      maxHeight: "10px",
      backgroundColor: "#FFF",
      fontFamily: "Poppins",

      menu: (base) => ({
        ...base,
        marginTop: "5px",
        backgroundColor: "#FFF",
        zIndex: 9999,
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "white" : "black",
        minHeight: "35px",
        paddingTop: "10px",
        fontSize: "13px",
        opacity: state.isDisabled ? "0.5" : "1",
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        backgroundColor: state.isSelected ? "#6F7787FF" : "white",
        fontFamily: "Poppins",
        "&:hover": {
          color: !state.isDisabled && "black",
          background: !state.isDisabled && "#BCC1CAFF",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "2px",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),

      control: (base, state) => ({
        ...base,
        width: "100%",
        paddingRight: "6px",
        paddingLeft: "8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        borderWidth: "1px",
        outline: "none",
        border: state.isDisabled ? "none !important" : "1px solid #9095A0",
        borderRadius: "10px",
        minHeight: "40px",
        fontFamily: "Poppins",
        height: height,
        cursor: "pointer",

        pointerEvents: "auto",
        backgroundColor: state.isDisabled ? "#F8F9FA" : "#FFF",

        color: "#fff !important",

        boxShadow: noBorder && "none",
        "&:focus": {
          color: "#BCC1CAFF",
          background: "#FFFFFFFF",
          borderColor: "#9095A0FF",
          cursor: "pointer",
        },
        "&:hover": {
          color: "#BCC1CAFF",
          background: "#FFFFFFFF",
          borderColor: "#6F7787FF",
        },

        "&:disabled": {
          border: state.isDisabled ? "none !important" : "1px solid #9095A0",
          color: "#BCC1CAFF",
          background: "#FFFFFF",
        },
        input: {
          height: "auto !important",
        },
      }),
      placeholder: (base, state) => ({
        ...base,
        backgroundColor: state.isDisabled ? "#F8F9FA" : "#FFFFFF",
      }),
      menuList: (base) => ({
        ...base,
        textAlign: "start",
        maxHeight: menuHeight,
      }),
      singleValue: (base) => ({
        ...base,
        marginTop: "2px",
        color: "#171A1FFF",
        fontFamily: "Poppins",
      }),
      multiValueRemove: (base, state) => ({
        ...base,
        ...(state.isDisabled
          ? {
            visibility: "hidden",
            width: "4px",
          }
          : {}),
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: "0px",
        display: hideIndicator ? "none" : undefined,
        height: "20px",
      }),
    };

    /**
     * Handles the formatting of the option label based on the provided data.
     * If an avatar label is present, it wraps the label and email in an AvatarLabel component.
     * @param {{object}} data - The data object containing label and email information.
     * @returns The formatted option label.
     */
    const handleFormatOptionLabel = (data) => {
      if (avatarLabel) {
        return (
          <Box>
            {data && (
              <AvatarLabel>
                <Box className="label">
                  <Box>
                    {data?.label && (
                      <EllipsisText
                        text={data?.label}
                        length={labelLength}
                        tooltip={data?.label}
                      />
                    )}
                  </Box>
                  <Box className="email">
                    {data?.email && (
                      <EllipsisText
                        text={data?.email}
                        length={emailLength}
                        tooltip={data?.email}
                      />
                    )}
                  </Box>
                </Box>
              </AvatarLabel>
            )}
          </Box>
        );
      }
      return data.label;
    };

    const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

    /**
     * Returns a memoized component based on the type provided.
     * @returns {Component} - The component based on the type.
     */
    const RenderComponent = useMemo(() => {
      if (type === "creatable") {
        return CreatableSelect;
      }
      if (type === "asyncCreatable") {
        return CreatableAsyncPaginate;
      }
      if (type === "asyncPaginate") {
        return AsyncPaginate;
      }
      return ReactSelect;
    }, []);

    return (
      <Box width="100%">
        {label && <Box className={styles.InputLabelComponent}>{label}</Box>}
        <RenderComponent
          data-testid="dropDown-input"
          {...props}
          components={{
            Control: (props) => Control({ ...props, precontent }),
          }}
          isMulti={isMulti}
          controlShouldRenderValue={controlShouldRenderValue}
          styles={customStyles}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          cacheUniqs={cacheUniqs}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={isDisabled}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          hideSelectedOptions={hideSelectedOptions}
          defaultValue={defaultValue}
          formatOptionLabel={(data) => handleFormatOptionLabel(data)}
          loadOptions={loadOptions}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          backspaceRemovesValue={backspaceRemovesValue}
          debounceTimeout={debounceTimeout}
          additional={additional}
          menuHeight={menuHeight}
          menuPlacement={menuPlacement}
          menuPortalTarget={menuPortalTarget}
          closeMenuOnScroll={closeMenuOnScroll}
          menuShouldBlockScroll={menuShouldBlockScroll}
        />
        {error && helperText && (
          <FormHelperText className="HelperText">
            <Typography variant="body2 Danger">{helperText}</Typography>
          </FormHelperText>
        )}
      </Box>
    );
  }
);

Control.propTypes = {
  children: PropTypes.node,
  precontent: PropTypes.node,
};

SearchableDropDown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  menuPortalTarget: PropTypes.instanceOf(Element),
  isDisabled: PropTypes.bool,
  avatarLabel: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  isMulti: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  controlShouldRenderValue: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.oneOf(["creatable", "asyncCreatable", "asyncPaginate"]),
  loadOptions: PropTypes.func,
  cacheOptions: PropTypes.any,
  defaultOptions: PropTypes.any,
  debounceTimeout: PropTypes.number,
  additional: PropTypes.any,
  menuHeight: PropTypes.string,
  closeMenuOnScroll: PropTypes.bool,
  menuShouldBlockScroll: PropTypes.bool,
  menuPlacement: PropTypes.string,
  backspaceRemovesValue: PropTypes.bool,
  cacheUniqs: PropTypes.bool,
  noBorder: PropTypes.bool,
  view: PropTypes.string,
  labelLength: PropTypes.number,
  emailLength: PropTypes.number,
  precontent: PropTypes.element,
};
