import React from "react";

const TicketManagementIcon = ({ color = "#111111" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/frame">
        <g id="frame">
          <path
            id="Vector"
            d="M11.5625 11.3125C13.7875 12.125 16.2125 12.125 18.4375 11.3125"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M21.0254 2.5H8.97539C6.31289 2.5 4.15039 4.675 4.15039 7.325V24.9375C4.15039 27.1875 5.76289 28.1375 7.73789 27.05L13.8379 23.6625C14.4879 23.3 15.5379 23.3 16.1754 23.6625L22.2754 27.05C24.2504 28.15 25.8629 27.2 25.8629 24.9375V7.325C25.8504 4.675 23.6879 2.5 21.0254 2.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M21.0254 2.5H8.97539C6.31289 2.5 4.15039 4.675 4.15039 7.325V24.9375C4.15039 27.1875 5.76289 28.1375 7.73789 27.05L13.8379 23.6625C14.4879 23.3 15.5379 23.3 16.1754 23.6625L22.2754 27.05C24.2504 28.15 25.8629 27.2 25.8629 24.9375V7.325C25.8504 4.675 23.6879 2.5 21.0254 2.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default TicketManagementIcon;
