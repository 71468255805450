import { putApi, getApi } from "../apis";

/**
 * Creates a document for the given payload data.
 */
export const createDocumentReceived = (payloadData) => {
    const { data, id } = payloadData;
    return putApi(`Ticket/document?ticketId=${id}`, data);
}

export const checkboxDataReceived = (payloadData) => {
    const { data, id } = payloadData;
    return putApi(`Ticket/document?ticketId=${id}`, data);
}

/**
 * creates a get request to fetch the encrypted file url for the file viewer.
 */
export const fetchEncryptedURL = (fileUrl, customerCode) => {
    return getApi(`Master/viewFile?fileurl=${fileUrl}&customerCode=${customerCode}`)
}

