import { Box } from "@mui/material";
import React from "react";
import UserManagementTable from "./UserManagementTable";
import { useUser } from "../../utils/hooks/User";
import { ViewUser } from "./ViewUser";
import ModuleWrapper from "../../components/ModuleWrapper";
import users from "../../assets/Images/dashboard-images/profile-2user.svg";

const UserManagement = () => {
  const { viewUser, setViewUser } = useUser();
  return (
    <Box>
      <ModuleWrapper moduleHeader="Manage Users" moduleImage={users}>
        <UserManagementTable />
      </ModuleWrapper>

      {viewUser && (
        <ViewUser
          open={viewUser}
          isView
          handleClose={() => setViewUser(false)}
          maxwidth="lg"
        />
      )}
    </Box>
  );
};

export default UserManagement;
