import React from 'react';
import GroupDetails from './Groups';
import ModuleWrapper from '../../../components/ModuleWrapper';
import people from "../../../assets/Images/people.svg";

export default function Groups() {
    return (
        <ModuleWrapper moduleHeader='Add Group' moduleImage={people}>
            <GroupDetails />
        </ModuleWrapper>
    )
}
