// Hook (use-auth.js)
import React, { useContext, createContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createTicket,
  editTicket,
  getTicketList,
  getTicketQuestions,
  ticketDocumentUpdate,
  updateQuestionAnswer,
  updateTicketQuestions,
} from "../../controllers/ticket";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { dateFormatter, isTokenExpired } from "../../helper";
import { TokenExpiry } from "../Auth/TokenExpiry";
import PropTypes from "prop-types";

const TicketContext = createContext();

export const useTicket = () => useContext(TicketContext);

function useProvideTicket() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openCreateTicket, setOpenCreateTicket] = useState(false);
  const [openEditTicket, setOpenEditTicket] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const [deleteDocumentData, setDeleteDocumentData] = useState(false);
  const [deleteQuestion, setDeleteQuestion] = useState();
  const [storeTableRowData, setStoreTableRowData] = useState("");
  const [errorText, setErrorText] = useState("");

  /**
   * Custom hook to fetch a list of tickets based on the provided parameters.
   * @param {string} mode - The mode of fetching tickets.
   * @param {number} page - The page number of the ticket list.
   * @param {number} count - The number of tickets to fetch per page.
   * @param {string} search - The search query for filtering tickets.
   * @param {string} filter - The filter criteria for fetching tickets.
   * @param {string} sortOrder - The order in which tickets should be sorted.
   * @returns The result of the query to fetch ticket list.
   */
  const useGetTicketList = (mode, page, count, search, filter, sortOrder) =>
    useQuery(
      ["ticketList", mode, page, count, search, filter, sortOrder],
      () => getTicketList(mode, page, count, search, filter, sortOrder),
      {
        enabled: true,
        select: (data) => data.data,
        onSuccess: (data) => TokenExpiry(data?.ResponseCode),
        cacheTime: 0,
      }
    );

  const useGetTicketQuestions = (ticketId) =>
    useQuery(
      ["ticketQuestions", ticketId],
      () => getTicketQuestions(ticketId),
      {
        enabled: !!ticketId,
        select: (data) => data.data,
        onSuccess: (data) => TokenExpiry(data?.ResponseCode),
        cacheTime: 0,
      }
    );

  /**
   * Custom hook that uses the useMutation hook to create a ticket.
   * It handles success and error cases by refetching the ticket list, displaying toasts,
   * and navigating to different pages based on the response.
   * @returns {{
   *   mutateCreateTicket: Function,
   *   isCreateTicketLoading: boolean
   * }} An object containing the mutateCreateTicket function for creating a ticket
   * and a boolean flag isCreateTicketLoading to indicate if the ticket creation is in progress.
   */
  const { mutate: mutateCreateTicket, isLoading: isCreateTicketLoading } =
    useMutation(createTicket, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data.data.success) {
          queryClient.refetchQueries("ticketList");
          toast.success("Successfully created a ticket");
          navigate("ticket");
        } else {
          toast.error(data.data.errors[0]);
        }
      },
      onError: (err) => {
        if (err.response.status === 504) {
          toast.success("Successfully created a ticket");
          queryClient.refetchQueries("ticketList");
          navigate("ticket");
        } else {
          toast.error(err.message);
        }
      },
    });

  /**
   * Custom hook that uses a mutation to edit a ticket and handles success and error cases.
   * @returns {{
   *   mutateUpdateTicket: Function, // Function to trigger the mutation
   *   isUpdateTicketLoading: boolean // Flag indicating if the mutation is in progress
   * }}
   */
  const { mutate: mutateUpdateTicket, isLoading: isUpdateTicketLoading } =
    useMutation(editTicket, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data.data.success) {
          queryClient.refetchQueries("ticketList");
          toast.success("Successfully updated a ticket");
          navigate(-1);
        } else {
          toast.error(data.data.errors[0]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  /**
   * Custom hook that uses a mutation to update a ticket document.
   * @param {{Function}} ticketDocumentUpdate - The function to update the ticket document.
   * @returns {{
   *   mutate: Function - Function to trigger the mutation,
   *   isLoading: boolean - Flag indicating if the mutation is in progress
   * }}
   */
  const {
    mutate: mutateUpdateTicketDocument,
    isLoading: isUpdateTicketDocumentLoading,
  } = useMutation(ticketDocumentUpdate, {
    onSuccess: (data) => {
      if (isTokenExpired(data?.data?.ResponseCode)) {
        TokenExpiry(data?.data?.ResponseCode);
      } else if (data.data.success) {
        queryClient.refetchQueries("ticketList");
        setOpenDeleteModal(false);
      } else {
        toast.error(data.data.errors[0]);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  /**
   * Custom hook that uses a mutation to delete a ticket document.
   * @param {{Function}} mutateDeleteTicketDocument - Function to delete a ticket document.
   * @param {{boolean}} isDeleteTicketDocumentLoading - Flag indicating if the delete operation is in progress.
   * @returns None
   */
  const {
    mutateAsync: mutateDeleteTicketDocument,
    isLoading: isDeleteTicketDocumentLoading,
  } = useMutation(ticketDocumentUpdate, {
    onSuccess: (data) => {
      queryClient.refetchQueries("ticketById");
      if (isTokenExpired(data?.data?.ResponseCode)) {
        TokenExpiry(data?.data?.ResponseCode);
      } else if (data.data.success) {
        queryClient.refetchQueries("ticketList");
        setOpenDeleteModal(false);
        setOpenDeleteDocumentModal(false);
      } else {
        toast.error(data.data.errors[0]);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const {
    mutateAsync: mutateUpdateQuestionAnswers,
    isLoading: isUpdateQuestionAnswersLoading,
  } = useMutation(updateQuestionAnswer, {
    onSuccess: () => {
      setOpenDeleteModal(false);
      setDeleteQuestion(undefined);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const {
    mutateAsync: mutateUpdateTicketQuestion,
    isLoading: isUpdateTicketQuestionLoading,
  } = useMutation(updateTicketQuestions, {
    onSuccess: () => {
      queryClient.refetchQueries("ticketList");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const {
    mutateAsync: mutateDeleteRequest,
    isLoading: isDeleteRequestLoading,
  } = useMutation(editTicket, {
    onSuccess: (data) => {
      setOpenDeleteModal(false);
      if (data?.data.success) {
        queryClient.refetchQueries("ticketList");
      } else {
        setErrorText(data?.data?.errors[0]);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  /**
   * Handles the creation of a ticket by formatting the data object and calling the mutateCreateTicket function.
   * @param {{object}} data - The data object containing ticket information.
   * @returns None
   */
  const handleCreateTicket = (data) => {
    const finalData = {
      ...data,
      clientId: data.client.value,
      clientContactId: data.contactPerson.value,
      status: data.status.value,
      dueDate: dateFormatter(data.dueDate),
      userId: 2,
      ticketOwner: {
        ticketOwnerIDs: data.assignee.map((item) => item.value),
      },
      idrQuestions: data.idrQuestions.map((item) => ({
        ...item,
        answered: !!item.answers,
      })),
    };

    delete finalData.client;
    delete finalData.contactPerson;
    delete finalData.assignee;
    delete finalData.email;
    delete finalData.ticketID;

    mutateCreateTicket(finalData);
  };

  /**
   * Updates the document list by filtering and mapping the data.
   * @param {{object[]}} data - The array of document data to be processed.
   * @returns {object[]} The updated document list after filtering and mapping.
   */
  const updatedDocumentList = (data) => {
    const filterUploadedDocument = data.map((documentType) => ({
      ...(!documentType.ticketIDRDocumentID && {
        idrDocumentId: documentType.idrDocumentID,
        documentName: documentType.documentName,
      }),
      ticketDocumentID: documentType.ticketIDRDocumentID || 0,
      received: documentType.received,
      isActive: true,
      Status: "Active",
      document: documentType.documents
        .filter((document) => !document.ticketIDRMultipleDocumentID)
        .map((document) => ({
          ...document,
          ticketIDRMultipleDocumentID: 0,
          isActive: true,
        })),
    }));

    const finalDocumentTypeList = filterUploadedDocument.filter(
      (documentType) =>
        documentType.document.length > 0 || documentType.ticketDocumentID === 0
    );

    return finalDocumentTypeList;
  };

  /**
   * Handles updating a ticket with the provided data.
   * @param {{object}} data - The data object containing information to update the ticket.
   */
  const handleUpdateTicket = (data) => {
    const finalData = {
      clientContactId: data.contactPerson.value,
      status: data.status.value,
      ticketOwnersDetails: data.assignee,
      ticketID: data.ticketID,
      dueDate: dateFormatter(data.dueDate),
      email: data.emailId,
    };

    const documentUpdate = {
      ticketId: data.ticketID,
      list: updatedDocumentList(data.ticketIDRDocuments),
    };

    const questionUpdate = {
      ticketId: data.ticketID,
      questions: data.idrQuestions
        .filter((item) => !item.questionID)
        .map((item) => ({
          question: item.question,
          answered: !!item.answers,
          answers: item.answers || "",
        })),
    };

    const answerUpdate = {
      answers: data.idrQuestions
        .filter((item) => item.questionID)
        .map((item) => ({
          questionID: item.questionID,
          question: item.question,
          ticketID: data.ticketID,
          answers: item.answers || "",
          answered: !!item.answers,
          isActive: true,
        })),
    };

    mutateUpdateTicket(finalData);
    if (documentUpdate.list.length > 0) {
      mutateUpdateTicketDocument(documentUpdate);
    }
    if (questionUpdate.questions.length > 0) {
      mutateUpdateTicketQuestion(questionUpdate);
    }
    if (answerUpdate.answers.length > 0) {
      mutateUpdateQuestionAnswers(answerUpdate);
    }
  };

  return {
    openCreateTicket,
    setOpenCreateTicket,
    setStoreTableRowData,
    storeTableRowData,
    setOpenEditTicket,
    openEditTicket,
    useGetTicketList,
    handleCreateTicket,
    isCreateTicketLoading,
    handleUpdateTicket,
    isUpdateTicketLoading,
    isUpdateTicketDocumentLoading,

    deleteDocumentData,
    setDeleteDocumentData,
    openDeleteModal,
    setOpenDeleteModal,
    mutateUpdateTicketDocument,
    isDeleteTicketDocumentLoading,
    mutateDeleteTicketDocument,
    openDeleteDocumentModal,
    setOpenDeleteDocumentModal,
    useGetTicketQuestions,
    deleteQuestion,
    setDeleteQuestion,
    mutateUpdateQuestionAnswers,
    isUpdateQuestionAnswersLoading,
    mutateUpdateTicketQuestion,
    isUpdateTicketQuestionLoading,

    //delete request
    mutateDeleteRequest,
    isDeleteRequestLoading,
    errorText,
    setErrorText,
  };
}

export function ProvideTicket({ children }) {
  const ticketData = useProvideTicket();
  return (
    <TicketContext.Provider value={ticketData}>
      {children}
    </TicketContext.Provider>
  );
}
ProvideTicket.propTypes = {
  children: PropTypes.node.isRequired,
};
