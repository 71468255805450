import React, { useEffect } from 'react';
import Dashboard from "./Dashboard";
import ModuleWrapper from "../../components/ModuleWrapper";
import Headercomponent from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useUserDetailsContext } from "../../utils/hooks/UserDetails";
import { useAuth } from '../../utils/hooks';
import homeImage from "../../assets/Images/navigation-images/link-1.svg";

export default function Dashboardmain() {
    const { user } = useAuth();

    const { useGetUserDetails, storeUserDetails, setStoreUserDetails } = useUserDetailsContext();

    const { data, isSuccess } = useGetUserDetails(user?.UserID);

    /**
     * useEffect hook that updates the user details in the store and local storage when the data is successfully fetched.
     * @param {Function} isSuccess - A function that returns a boolean indicating if the data fetch was successful.
     * @param {Object} data - The data fetched from the API.
     * @param {Function} setStoreUserDetails - A function to set the user details in the store.
     * @param {Function} localStorage.setItem - A function to set an item in the local storage.
     * @returns None
     */
    useEffect(() => {
        if (isSuccess) {
            setStoreUserDetails(data);
            localStorage.setItem("user-details", JSON.stringify(data.result));
        }
    }, [data, storeUserDetails])

    return (
        <>
            <Headercomponent />
            <ModuleWrapper moduleHeader="Quick Access" moduleImage={homeImage}>
                <Sidebar />
                <Dashboard />
            </ModuleWrapper>
        </>
    )
}
