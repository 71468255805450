import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Forgotpassword.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { BasicModal } from "../../components/Modal/Modal";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Commonerrormessage from "../../components/Errormessage";
import { useProvideResetRequest } from "../../utils/hooks/Auth/Forgotpassword";
import { Loader } from "../../components/Loader";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PropTypes from 'prop-types';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useAuth } from "../../utils/hooks";

export default function Forgetpassword({ modalOpen, handleInputChange, handleEmailModal, handleModalClose, formData, errorMessage, inputError, handleResendEmail, isEmailTimerRunning, resendEmailTimer, resendTimer, handleFormSubmission }) {
    const navigate = useNavigate();
    const { useGetConfigData } = useAuth();
  
    const { data: userConfigData, isLoading: isUserConfigDataLoading } =
      useGetConfigData();

    const { resetRequestLoader, timer, isTimerRunning } = useProvideResetRequest()

    const renderModalContent = () => {

        /**
         * Determines the state of the button based on the timer status.
         * @returns {boolean} True if the button should be enabled, false otherwise.
         */
        const handleButtonState = () => {
            if (resendTimer) {
                if (isEmailTimerRunning) {
                    return true
                }
            } else if (isTimerRunning) {
                if (timer) {
                    return true
                }
            }
        }

        return (
            <div className={styles.customModalContentWrapper}>
                <h6>Check your email!</h6>
                <Box className={styles.close} onClick={handleModalClose}>
                    <ClearIcon />
                </Box>
                <p>
                    Thanks! An email was sent that will ask you to click on a link to
                    verify that you own this account.If you don't get the email,
                    please contact.{' '}
                    <span>
                        <a href="support@idr.com">support@idr.com</a>
                    </span>
                </p>
                {resendTimer ? (
                    <>
                        {isEmailTimerRunning && (
                            <Box className={styles.timerClass}>
                                <AccessTimeIcon />
                                <span>00:{resendEmailTimer} Sec</span>
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        {isTimerRunning && (
                            <Box className={styles.timerClass}>
                                <AccessTimeIcon />
                                00:{timer} Sec
                            </Box>
                        )}
                    </>
                )}
                <Button
                    variant="contained"
                    sx={{
                        marginTop: "20px",
                        width: "80%",
                        backgroundColor: "var(--secondary-color)",
                        borderRadius: "0",
                        padding: "8px",
                        "&:hover": { background: "var(--secondary-color)" },
                    }}
                    onClick={handleResendEmail}
                    disabled={handleButtonState()}
                >
                    Resend Email
                </Button>
            </div>
        );
    };

    if (isUserConfigDataLoading) {
      return <Loader />;
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1 }} className={styles.container}>
                <Grid container spacing={4}>
                    <Grid xs={12} md={5}>
                        <div className={styles.imgWrapper}>
                        <img src={userConfigData?.result?.logoURLReference} alt="Placeholder" />
                        </div>
                    </Grid>
                    <Grid xs={12} md={7}>
                        <div className={styles.formWrapper}>
                            <h1>Forgot Password?</h1>
                            <h4>
                                Enter your email, so that we can send your password reset link
                            </h4>
                            <form className={styles.customFormWrapper}>
                                <div className={styles.emailWrapper}>
                                    <CorporateFareIcon
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: "10px",
                                            fontSize: "20px",
                                        }}
                                    />
                                    <input
                                        type="text"
                                        name="organizationName"
                                        value={formData.organizationName}
                                        placeholder="Enter Organization Code"
                                        onChange={handleInputChange}
                                        onKeyDown={handleFormSubmission}
                                        disabled
                                    />
                                </div>
                                <div className={styles.emailWrapper}>
                                    <EmailOutlinedIcon
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: "10px",
                                            fontSize: "20px",
                                        }}
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your Email"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        onKeyDown={handleFormSubmission}
                                    />
                                    {inputError.emailError && (
                                        <p className={styles.inputFieldErrorMessage}>
                                            Enter a valid email address *
                                        </p>
                                    )}
                                </div>
                            </form>
                            {errorMessage && (
                                <Commonerrormessage message="All fields are required *" />
                            )}
                            <div className={styles.triggerEmailButtonWrapper}>
                                <p className={styles.backToLoginText}>
                                    <span onClick={() => navigate("/login")} onKeyDown={() => { }}>
                                        <ArrowBackIosOutlinedIcon
                                            sx={{ fontSize: "18px", marginRight: "5px" }}
                                        />
                                        Back to Login
                                    </span>
                                </p>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginTop: "20px",
                                        width: "40%",
                                        backgroundColor: "var(--secondary-color)",
                                        borderRadius: "10px",
                                        padding: "8px",
                                        fontWeight: "600",
                                        textTransform: "capitalize",
                                        "&:hover": { background: "var(--secondary-color)" },
                                    }}
                                    onClick={() => handleEmailModal()}
                                >
                                    {resetRequestLoader ? <Loader type='button' /> : "Send Email"}
                                    <ArrowCircleRightOutlinedIcon sx={{
                                        marginLeft: "10px",
                                        fontSize: "20px"
                                    }} />
                                </Button>
                            </div>
                        </div>

                        <BasicModal open={modalOpen} onClose={handleModalClose}>
                            {renderModalContent()}
                        </BasicModal>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

Forgetpassword.propTypes = {
    modalOpen: PropTypes.bool,
    handleInputChange: PropTypes.func,
    handleEmailModal: PropTypes.func,
    handleModalClose: PropTypes.func,
    formData: PropTypes.object,
    errorMessage: PropTypes.bool,
    inputError: PropTypes.object,
    handleResendEmail: PropTypes.func,
    isEmailTimerRunning: PropTypes.bool,
    resendEmailTimer: PropTypes.number,
    resendTimer: PropTypes.bool,
    handleFormSubmission: PropTypes.func
};