import React, { useEffect, useRef } from "react";
import ModuleWrapper from "../../components/ModuleWrapper";
import Settings from "./Settings";
import setting from "../../assets/Images/setting-2.svg";
import styles from "./Settings.module.scss";
import { useForm } from "react-hook-form";
import { useAuth } from "../../utils/hooks";
import { Loader } from "../../components/Loader";
import LoadingOverlay from "../../components/LoadingOverlay";

const SettingsWrapper = () => {
  const colorRef = useRef(null);
  const fileRef = useRef(null);
  const {
    mutateUpdateUserConfigData,
    isUpdateUserConfigDataLoading,
    useGetConfigData,
  } = useAuth();

  const { data: userConfigData, isLoading: isUserConfigDataLoading } =
    useGetConfigData();

  /**
   * Initializes the form with the specified mode and default values.
   * @param {{string}} mode - The mode of the form (e.g., "all").
   * @param {{object}} defaultValues - The initial values for the form fields.
   * @returns An object containing functions for handling form submission, controlling form fields, setting field values, resetting the form, and watching form field changes.
   */
  const { handleSubmit, control, reset, watch } = useForm({
    mode: "all",
    defaultValues: {
      name: userConfigData?.result?.name || "",
      color: userConfigData?.result?.secondaryColour || "#ffffff",
      logo: userConfigData?.result?.logoURLReference || "",
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();

    if (data.logo?.name) {
      formData.append("file", data.logo);
    } else {
      formData.append("file", null);
    }

    const finalData = {
      name: data.name,
      color: encodeURIComponent(data.color),
      formData: formData,
    };

    mutateUpdateUserConfigData(finalData);
  };

  useEffect(() => {
    reset({
      name: userConfigData?.result?.name || "",
      color: userConfigData?.result?.secondaryColour || "",
      logo: userConfigData?.result?.logoURLReference || "",
    });
  }, [userConfigData]);

  return (
    <ModuleWrapper moduleHeader={"Settings"} moduleImage={setting}>
      {isUserConfigDataLoading ? (
        <Loader />
      ) : (
        <form
          className={styles.settingsContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Settings
            control={control}
            watch={watch}
            colorRef={colorRef}
            fileRef={fileRef}
          />
        </form>
      )}
      <LoadingOverlay
        isLoading={isUserConfigDataLoading || isUpdateUserConfigDataLoading}
      />
    </ModuleWrapper>
  );
};

export default SettingsWrapper;
