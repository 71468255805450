import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./FilePreview.module.scss";
import { Loader } from "../../components/Loader";

const FilePreview = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const src = searchParams.get("src");

  const fileType = src.split(".").pop().toLowerCase();

  if (!fileType) {
    return <Loader />;
  }

  return (
    <div className={styles.filePreviewContainer}>
      {fileType === "pdf" || fileType === "csv" ? (
        <iframe
          style={{ width: "100vw", height: "100vh" }}
          title="docs viewer"
          src={`https://docs.google.com/gview?embedded=true&url=${src}`}
          frameBorder="0"
        ></iframe>
      ) : (
        <iframe
          style={{ width: "100vw", height: "100vh" }}
          title="ms office viewer"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}&amp;embedded=true`}
          frameBorder="0"
        ></iframe>
      )}
    </div>
  );
};

export default FilePreview;
