import React, { useContext, createContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getAllGroupName, addNewGroupName, noGroupNames, updateGroup } from "../../controllers/Group";
import { TokenExpiry } from "../Auth/TokenExpiry";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
// import { isTokenExpired } from "../../helper";
import { useAuth } from "../Auth";

const GroupContext = createContext();

export const UseGroupContext = () => useContext(GroupContext);

function useGroupContextDetails() {
    const [showBtn, setShowBtn] = useState({
        addClientBtn: true,
        formSubmitBtn: true,
        storeInputValue: "",
        storeClientData: [],
        storeDynamicSelectValues: []
    })

    const navigate = useNavigate();
    const { user } = useAuth();
    const queryClient = useQueryClient();

    /**
     * custom react query hook to call the server and fetch all group names.
     * @returns 
     */

    const useGetAllGroup = () =>
        useQuery(["groupNames"], () => getAllGroupName(), {
            enabled: true,
            select: (data) => data.data,
            onSuccess: (data) => TokenExpiry(data?.ResponseCode),
            cacheTime: 0
        });

    /**
* use mutation hook to send the group name to the server.
* @returns 
*/

    const { mutate: mutateStoreInputValue, isLoading: isCreateGroupNameLoading } =
        useMutation(addNewGroupName, {
            onSuccess: (data) => {
                if (data?.data.success) {
                    if (showBtn.storeDynamicSelectValues.length === 0) {
                        toast.success("Successfully added a new group name.");
                        navigate("/client");
                        queryClient.refetchQueries("groupNames");
                    } else {
                        handleGroupClientData(showBtn.storeDynamicSelectValues, user?.UserID, showBtn.storeInputValue)

                    }
                } else {
                    toast.error(data.data.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        });

    /**
* custom react query hook to call the server and fetch all group names which aren't part of any groups. 
* @returns 
*/

    const useGetNoGroupNames = () =>
        useQuery(["noGroupNames"], () => noGroupNames(), {
            enabled: true,
            select: (data) => data.data,
            onSuccess: (data) => TokenExpiry(data?.ResponseCode),
            cacheTime: 0
        });

    const { mutate: mutateGroupClientData } = useMutation(updateGroup, {
        onSuccess: (data) => {
            if (data?.data.success) {
                toast.success("Client name successfully added to the group.");
                navigate(-1);
                setShowBtn((prevState) => ({
                    ...prevState,
                    formSubmitBtn: true,
                    storeClientData: [],
                    storeDynamicSelectValues: []
                }))
            }

        },
        onError: (err) => {
            console.log(err);
            toast.error(err.message);
        },
    });

    const handleGroupClientData = (data, UserID, groupName) => {
        mutateGroupClientData({ data, UserID, groupName });
    }

    return {
        showBtn,
        setShowBtn,
        useGetAllGroup,
        isCreateGroupNameLoading,
        mutateStoreInputValue,
        useGetNoGroupNames
    }

}

export function ProviderGroupDetails({ children }) {
    const groupState = useGroupContextDetails();
    return (
        <GroupContext.Provider value={groupState}>
            {children}
        </GroupContext.Provider>
    );
}