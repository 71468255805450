export const initialTicketData = {
  ticketID: '',
  client: '',
  contactPerson: '',
  emailId: '',
  dueDate: null,
  status: {
    label: 'Open',
    value: 'Open',
  },
  assignee: '',
  ticketIDRDocuments: [],
};

export const ticketLabelMappingColor = {
  Late: "#A30D11",
  ["Due soon"]: "#CD6200",
  Completed: "#1F9254",
};
export const ticketlabelMappingBackground = {
  Late: "#FBE7E8",
  ["Due soon"]: "#FEF2E5",
  Completed: "#EBF9F1",
};