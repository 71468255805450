import HomeIcon from "../../assets/Icons/navigation-icon/Home";
import TicketManagementIcon from "../../assets/Icons/navigation-icon/TicketManagementIcon";
import ClientManagementIcon from "../../assets/Icons/navigation-icon/ClientManagementIcon";
import UserManagementIcon from "../../assets/Icons/navigation-icon/UserManagementIcon";
import ProfileIcon from "../../assets/Icons/navigation-icon/ProfileIcon";
import { ArchiveIcon } from "../../assets/Icons/ArchiveIcon";

export const sideBarContentAdmin = [
  {
    pageName: "Quick Access",
    navMenuName: "Quick Access",
    sideNavIcon: <HomeIcon color="#cccccc" />,
    activeSideNavIcon: <HomeIcon />,
    id: 1,
    pageRoute: "/dashboard",
    page: "/dashboard",
  },
  {
    pageName: "Request Management",
    sideNavIcon: <TicketManagementIcon color="#cccccc" />,
    activeSideNavIcon: <TicketManagementIcon />,
    id: 2,
    pageRoute: "/ticket",
    page: "/ticket",
  },
  {
    pageName: "Client Management",
    sideNavIcon: <ClientManagementIcon color="#cccccc" />,
    activeSideNavIcon: <ClientManagementIcon />,
    id: 3,
    pageRoute: "/client",
    page: "/client",
  },
  {
    pageName: "User Management",
    sideNavIcon: <UserManagementIcon color="#cccccc" />,
    activeSideNavIcon: <UserManagementIcon />,
    id: 4,
    pageRoute: "/user",
    page: "/user",
  },
  {
    pageName: "Profile",
    sideNavIcon: <ProfileIcon color="#cccccc" />,
    activeSideNavIcon: <ProfileIcon />,
    id: 5,
    pageRoute: "/profile",
    page: "/profile",
  },
  {
    pageName: "Archive",
    sideNavIcon: <ArchiveIcon color="#cccccc" />,
    activeSideNavIcon: <ArchiveIcon />,
    id: 6,
    pageRoute: "/archive",
    page: "/archive",
  },
];
