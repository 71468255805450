import React, { useState, useContext, createContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { createResetRequest } from "../../controllers/forgot-password";
import { toast } from "react-toastify";
import PropTypes from "prop-types";


const resetRequestContext = createContext();

export const useProvideResetRequest = () => useContext(resetRequestContext);

const useHandleResetRequestData = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    const startTimer = () => {
        setIsTimerRunning(true);
    };

    /**
     * Resets the timer to 60 seconds and stops the timer from running.
     * @returns None
     */
    const resetTimer = () => {
        setTimer(60);
        setIsTimerRunning(false);
    };


    /**
     * useEffect hook that handles a countdown timer functionality.
     * It decrements the timer by 1 every second if the timer is running.
     * If the timer reaches 0, it resets the timer and clears the interval.
     * @returns None
     */
    useEffect(() => {
        let interval;

        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
            }, 1000);

            if (timer === 0) {
                resetTimer();
                clearInterval(interval);
            }
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    const handleTimer = () => {
        startTimer();
    };

    // use use mutation for post and put/edit api.
    const { mutate: mutateResetRequest, isLoading: resetRequestLoader } =
        useMutation(createResetRequest, {
            onSuccess: (data) => {
                if (data && data.data.responseCode === 1001) {
                    toast.error("Couldn't find your organization code. Please check and try again");
                } else if (data && data.data.responseCode === 2042) {
                    toast.error("Couldn't find your email id. Please check and try again.");
                } else {
                    setModalOpen(true)
                    handleTimer();
                }
            },
            onError: (err) => {
                console.log(err);
            },
        });

    /**
     * Handles a reset request by mutating the reset request data.
     * @param {{resetRequestData}} resetRequestData - The data for the reset request.
     * @returns None
     */
    const handleResetRequest = (resetRequestData) => {
        mutateResetRequest(resetRequestData)
    }

    return {
        modalOpen,
        setModalOpen,
        handleResetRequest,
        resetRequestLoader,
        isTimerRunning,
        timer,
        setTimer,
        setIsTimerRunning,
        resetTimer
    }
}

export function ProvideResetRequest({ children }) {
    const resetRequestProvide = useHandleResetRequestData();
    return <resetRequestContext.Provider value={resetRequestProvide}>{children}</resetRequestContext.Provider>;
}
ProvideResetRequest.propTypes = {
    children: PropTypes.node.isRequired,
};
