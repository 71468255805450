import React from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Papa from "papaparse";
import CustomButton from "../../../components/Button";
import { PlusIcon } from "../../../assets/Icons/PlusIcon";
import styles from "./CreateTicketModal.module.scss";

const BulkUpload = ({ selectedDocumentType, documentTypeList, setValue }) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "text/csv": [],
    },
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      const selectedDocument = selectedDocumentType?.map(
        (item) => item.documentName
      );
      reader.onload = () => {
        const result = reader.result;
        Papa.parse(result, {
          skipEmptyLines: true,
          header:true,
          complete: (parsed) => {
            const uploadedDocument = parsed.data
              .map((item) => item['Document Type'])
              .filter((item) => !selectedDocument.includes(item));

            const uniqueDocuments = [...new Set(uploadedDocument)];
            const newDocumentList = uniqueDocuments.map((item) => ({
              idrDocumentID:
                documentTypeList?.find(
                  (docType) => docType.idrDocument === item
                )?.idrDocumentID ||
                documentTypeList?.find(
                  (docType) => docType.idrDocument === "Others"
                )?.idrDocumentID,
              documentName: item,
              documents: [],
              received: false,
            }));
            setValue("ticketIDRDocuments", [
              ...selectedDocumentType,
              ...newDocumentList,
            ]);
          },
        });
      };

      reader.readAsText(acceptedFiles[0]);
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.length > 4) {
        toast.error("Please select maximum 4 files at once");
      } else {
        rejectedFiles.forEach((file) =>
          toast.error(
            `${file.file.name} is not a valid file type or exceeds the size limit of 5MB`
          )
        );
      }
    },
  });

  const onDownloadClick = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/upload_documentType_template.csv`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = "upload_documentType_template.csv";
    link.click();
  };

  return (
    <div className={styles.bulkUploadContainer}>
      <CustomButton size="medium" onClick={onDownloadClick}>
        Download Template
      </CustomButton>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <>
          <CustomButton type="button" size="medium">
            <PlusIcon />
            Bulk Upload
          </CustomButton>
        </>
      </div>
    </div>
  );
};

export default BulkUpload;
