import React from "react";
import { Grid } from "@mui/material";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import BasicDatePicker from "../../../components/DatePicker";
import { useClient, useMaster, useUser } from "../../../utils/hooks";
import { dropDownOptionArray } from "../../../utils/helper";
import PropTypes from 'prop-types';


const TicketFilter = ({ ticketFilter, setTicketFilter }) => {
  const { useGetAllClientName } = useClient();
  const { useGetTicketStatus } = useMaster();

  const { data: ticketStatusList, isLoading: isTicketStatusListLoading } =
    useGetTicketStatus();

    const { data: allClientListname, isLoading: isAllClientListLoading } =
      useGetAllClientName();

  const ticketStatusOption = dropDownOptionArray(
    ticketStatusList,
    "status",
    "status"
  );

  const clientNameOption = dropDownOptionArray(
    allClientListname?.results,
    "name",
    "id"
  );

  const { useGetAllUser } = useUser();

  const { data: allUserList, isLoading: isAllUserListLoading } =
    useGetAllUser();

  /**
   * Maps over a list of users and creates an array of objects with label and value properties.
   */
  const assigneeOption = allUserList?.results?.map((item) => ({
    label: `${item.fName} ${item.lName}`,
    value: item.userID,
  }));

  return (
    <Grid container spacing={2} justifyContent="right">
    
      <Grid item lg={2.8} md={5.5} xs={12}>
        <SearchableDropDown
          placeholder="Client Name"
          label=""
          isClearable
          value={ticketFilter.clientName}
          onChange={(e) => {
            setTicketFilter((prev) => ({ ...prev, clientName: e }));
          }}
          isLoading={isAllClientListLoading}
          options={clientNameOption}
        />
      </Grid>
      <Grid item lg={2.9} md={5.5} xs={12}>
        <SearchableDropDown
          placeholder="Ticket Status"
          label=""
          isClearable
          value={ticketFilter.ticketStatus}
          onChange={(e) => {
            setTicketFilter((prev) => ({ ...prev, ticketStatus: e }));
          }}
          options={ticketStatusOption}
          isLoading={isTicketStatusListLoading}
        />
      </Grid>
      <Grid item lg={2.4} md={5.5} xs={12}>
        <BasicDatePicker
          placeholderText="Due Date"
          value={ticketFilter.dueDate}
          isClearable
          onChange={(e) => {
            setTicketFilter((prev) => ({ ...prev, dueDate: e }));
          }}
        />
      </Grid>

      <Grid item lg={2.6} md={5.5} xs={12}>
        <SearchableDropDown
          placeholder="Assignee"
          label=""
          isClearable
          avatarLabel
          value={ticketFilter.assignee}
          onChange={(e) => {
            setTicketFilter((prev) => ({ ...prev, assignee: e }));
          }}
          isLoading={isAllUserListLoading}
          options={assigneeOption}
        />
      </Grid>
    </Grid>
  );
};

TicketFilter.propTypes = {
  ticketFilter: PropTypes.object,
  setTicketFilter: PropTypes.func,

};

export default TicketFilter;
