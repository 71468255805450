import React from 'react';
import HomeScreen from "./Home";
import Headercomponent from '../../../layouts/Header';
import ModuleWrapper from '../../../components/ModuleWrapper';

export default function IrsHomeScreen() {
    return (
        <>
            <Headercomponent />
            <ModuleWrapper moduleHeader="Good Morning, Madison!">
                <HomeScreen />
            </ModuleWrapper>
        </>
    )
}
