import React from "react";

const FileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 8.33464V12.5013C18.3337 16.668 16.667 18.3346 12.5003 18.3346H7.50033C3.33366 18.3346 1.66699 16.668 1.66699 12.5013V7.5013C1.66699 3.33464 3.33366 1.66797 7.50033 1.66797H11.667"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3337 8.33464H15.0003C12.5003 8.33464 11.667 7.5013 11.667 5.0013V1.66797L18.3337 8.33464Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
