import React from "react";
import PropTypes from "prop-types";
import "./ViewSwitchButton.scss";
import KanbanViewIcon from "../../assets/Icons/KanbanViewIcon";
import ListViewIcon from "../../assets/Icons/ListViewIcon";

const ViewSwitchButton = ({ buttonClicked, onListClick, onKanbanClick }) => {
  return (
    <div className="toggleButtonContainer">
      <div
        className="viewToggleButton"
        style={{
          backgroundColor: buttonClicked
            ? "#FFFFFFFF"
            : "var(--secondary-color)",
        }}
        onClick={onKanbanClick}
        onKeyDown={() => {}}
      >
        <KanbanViewIcon color={buttonClicked ? "black" : "white"} />
      </div>
      <div
        className="viewToggleButton"
        style={{
          backgroundColor: !buttonClicked
            ? "#FFFFFFFF"
            : "var(--secondary-color)",
        }}
        onClick={onListClick}
        onKeyDown={() => {}}
      >
        <ListViewIcon color={buttonClicked ? "white" : "black"} />
      </div>
    </div>
  );
};

ViewSwitchButton.propTypes = {
  buttonClicked: PropTypes.bool.isRequired,
  onListClick: PropTypes.func.isRequired,
  onKanbanClick: PropTypes.func.isRequired,
};

export default ViewSwitchButton;
