// Hook (use-auth.js)
import React, { useContext, createContext, useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createNewUserLogin,
  resendOtp,
  userOtpLogin,
} from "../../controllers/login";
import { getStorageValue, setStorageValue } from "../../../pages/Login/cookies";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InvalidTokenError, jwtDecode } from "jwt-decode";
import { getUserDetails } from "../../controllers/user-details";
import { TokenExpiry } from "./TokenExpiry";
import PropTypes from "prop-types";
import { loginWithSso } from "../../controllers/sso";
import { getConfigData, updateUserConfigData } from "../../controllers/org";
import { changeFavIcon } from "../../helper";

const authContext = createContext();

const userCustomerCode = localStorage.getItem("org_name");

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [accessToken, setAccessToken] = useState(
    getStorageValue("access_token")
  );
  const [organizationCode, setOrganizationCode] = useState(userCustomerCode);
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const REACT_APP_PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR;
  // const REACT_APP_SECONDERY_COLOR = process.env.REACT_APP_SECONDERY_COLOR;

  /**
   * function to render the primary and secondery colors taken from the env file.
   *  sets the primary and secondary colors
   * in the document's root element CSS variables. I
   * @returns None
   */

  // const renderAppColor = () => {
  //   document.documentElement.style.setProperty(
  //     "--primary-color",
  //     REACT_APP_PRIMARY_COLOR
  //   );
  //   document.documentElement.style.setProperty(
  //     "--secondary-color",
  //     REACT_APP_SECONDERY_COLOR
  //   );
  // };

  // useEffect(() => {
  //   renderAppColor();
  // }, []);

  /**
   * Decodes the access token using jwtDecode and also sets the user state with the decoded token.
   * Handles errors by logging specific messages for InvalidTokenError and generic error messages.
   * @returns None
   */
  const decodedToken = () => {
    try {
      const decodedToken = jwtDecode(accessToken);
      const Feature = decodedToken.Feature || "";
      setUser({
        ...decodedToken,
        Feature: Feature !== "" ? JSON.parse(Feature) : [],
      });
    } catch (error) {
      if (error instanceof InvalidTokenError) {
        console.error("Invalid token");
      } else {
        console.error("Error decoding token:", error.message);
      }
    }
  };

  /**
   * useEffect hook that triggers the decodedToken function when the accessToken changes.
   * @param {Function} decodedToken - Function to decode the token
   * @param {string} accessToken - The access token to check for changes
   * @returns None
   */
  useEffect(() => {
    if (accessToken) {
      decodedToken();
    }
  }, [accessToken]);

  // use use mutation for post and put/edit api.
  const { mutate: mutateUserLogin, isLoading: loginLoader } = useMutation(
    createNewUserLogin,
    {
      onSuccess: (data) => {
        sessionStorage.setItem("authToken", data?.data.accessToken);
        localStorage.setItem("responseCode", data?.data.responseCode);
        if (data.data.success && data.data.responseCode === 2004) {
          return navigate(`/resetPassword?token=${data?.data?.token}`);
        } else if (data?.data.responseCode === 2011) {
          navigate(`/otpLogin?token=${data?.data?.token}`, {
            state: { qrCode: data?.data?.result },
          });
        } else if (data?.data.responseCode === 2001) {
          toast.error(data?.data?.errors[0]);
        } else if (data?.data.responseCode === 2002) {
          toast.error(
            "Couldn't find your email id. Please check and try again."
          );
        } else if (data && data.data.responseCode === 0) {
          toast.error(
            "Couldn't find your organization code. Please check and try again."
          );
        } else if (data.data.success && data.data.responseCode === 2000) {
          setAccessToken(data.data.token);
          setStorageValue("access_token", data.data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
          toast.success("Successfully Logged In");
        } else {
          toast.error(
            "Wrong password. Try again or click Forgot Password to reset it."
          );
        }
      },
      onError: (err) => {
        const errorResponse = err.response;
        toast.error(errorResponse?.data.errors.Password[0]);
      },
    }
  );

  const { mutate: mutateUserSsoLogin, isLoading: ssoLoginLoader } = useMutation(
    loginWithSso,
    {
      onSuccess: (data) => {
        sessionStorage.setItem("authToken", data?.data.token);
        localStorage.setItem("responseCode", data?.data.responseCode);
        if (data.data.success && data.data.responseCode === 2097) {
          setAccessToken(data.data.token);
          setStorageValue("access_token", data.data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
          toast.success("Successfully Logged In");
        } else {
          toast.error("Email id doesn't exists.");
          sessionStorage.clear();
        }
      },
      onError: (err) => {
        const errorResponse = err.response;
        toast.error(errorResponse?.data.errors.Password[0]);
      },
    }
  );

  const decodeOtpToken = (token) => {
    const decodeToken = jwtDecode(token);
    const cookieKey = `${decodeToken.CustomerCode}_${decodeToken.email}`.toLowerCase();
    setStorageValue(cookieKey, token);
  };

  const { mutate: mutateUserOtpLogin, isLoading: isUserOtpLoginLoading } =
    useMutation(userOtpLogin, {
      onSuccess: (data) => {
        if (data.data.responseCode === 2000) {
          setAccessToken(data.data.token);
          setStorageValue("access_token", data.data.token);
          decodeOtpToken(data.data.otpToken);
          axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
          toast.success("Successfully Logged In");
        } else {
          toast.error("Please enter the valid otp");
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  const { mutate: mutateResendOtp, isLoading: isResendOtpLoading } =
    useMutation(resendOtp, {
      onSuccess: (data) => {
        if (data.data.success) {
          toast.success("Otp sent successfully");
        } else {
          toast.error("Failed to send otp");
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  const useGetConfigData = (customerCode) =>
    useQuery(
      ["user-config-data", organizationCode, customerCode],
      () => getConfigData(customerCode || organizationCode),
      {
        enabled: !!organizationCode || !!customerCode,
        select: (data) => data.data,
        onSuccess: (data) => {
          TokenExpiry(data?.ResponseCode);
          if (data.result) {
            document.documentElement.style.setProperty(
              "--secondary-color",
              data.result?.secondaryColour
            );
            changeFavIcon(data.result?.logoURLReference, data.result?.name);
          }
        },
      }
    );

  /**
   * Executes a query to fetch user details and updates the user state with the retrieved data.
   * @param {string[]} ["loggedIn-user", user?.UserID] - The query key containing the user ID.
   * @param {Function} () => getUserDetails(user?.UserID) - The function to fetch user details.
   * @param {Object} { enabled: boolean, select: Function, onSuccess: Function } - Query options object.
   * @returns The user details fetched from the query.
   */
  const { data: useDetails } = useQuery(
    ["loggedIn-user", user?.UserID],
    () => getUserDetails(user?.UserID),
    {
      enabled: !!user?.UserID,
      select: (data) => data.data,
      onSuccess: (data) => {
        TokenExpiry(data?.ResponseCode);
        setUser({ ...user, RoleName: data?.result?.roleName });
      },
    }
  );

  const {
    mutate: mutateUpdateUserConfigData,
    isLoading: isUpdateUserConfigDataLoading,
  } = useMutation(updateUserConfigData, {
    onSuccess: () => {
      queryClient.refetchQueries("user-config-data");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  /**
   * Handles the user login by calling the mutateUserLogin function with the provided data.
   * @param {{any}} data - The data needed for user login.
   * @returns None
   */
  const handleUserLogin = (data) => {
    mutateUserLogin(data);
  };

  const handleLoginWithSso = (data) => {
    mutateUserSsoLogin(data);
  };

  return {
    handleUserLogin,
    loginLoader,
    checkedStatus,
    setCheckedStatus,
    accessToken,

    //logged in user details
    user,
    useDetails,
    handleLoginWithSso,
    ssoLoginLoader,

    organizationCode,
    setOrganizationCode,

    //user configuration
    mutateUpdateUserConfigData,
    isUpdateUserConfigDataLoading,
    useGetConfigData,

    //otp login
    isUserOtpLoginLoading,
    mutateUserOtpLogin,

    mutateResendOtp,
    isResendOtpLoading,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
