import React from "react";

const ClientManagementIcon = ({ color = "#111111" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/building">
        <g id="building">
          <path
            id="Vector"
            d="M15.6254 27.5004H5.10039C3.65039 27.5004 2.46289 26.3379 2.46289 24.9129V6.36294C2.46289 3.08794 4.90039 1.60044 7.88789 3.06294L13.4379 5.78794C14.6379 6.37544 15.6254 7.93794 15.6254 9.26294V27.5004Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M27.4625 18.8234V23.5484C27.4625 26.2484 26.2125 27.4984 23.5125 27.4984H15.625V13.0234L16.2125 13.1484L21.8375 14.4109L24.375 14.9734C26.025 15.3359 27.375 16.1859 27.45 18.5859C27.4625 18.6609 27.4625 18.7359 27.4625 18.8234Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M6.875 11.25H11.2125"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M6.875 16.25H11.2125"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_5"
            d="M21.8379 14.4109V18.4359C21.8379 19.9859 20.5754 21.2484 19.0254 21.2484C17.4754 21.2484 16.2129 19.9859 16.2129 18.4359V13.1484L21.8379 14.4109Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M27.4504 18.5891C27.3754 20.0641 26.1504 21.2516 24.6504 21.2516C23.1004 21.2516 21.8379 19.9891 21.8379 18.4391V14.4141L24.3754 14.9766C26.0254 15.3391 27.3754 16.1891 27.4504 18.5891Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ClientManagementIcon;
