import React from "react";
import styles from "./DeleteModal.module.scss";
import { BasicModal } from "../../components/Modal/Modal";
import CustomButton from "../../components/Button";
import PropTypes from "prop-types";

const ErrorModal = ({ open, handleClose, errorText }) => {
  return (
    <BasicModal open={open} close={handleClose}>
      <div className={styles.errorModalContainer}>
        <p className={styles.errorText}>{errorText}</p>
        <div className={styles.errorModalButtons}>
          <CustomButton onClick={handleClose}>OK</CustomButton>
        </div>
      </div>
    </BasicModal>
  );
};
ErrorModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired,
};

export default ErrorModal;
