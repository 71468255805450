import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Commonerrormessage from "../../components/Errormessage";
import { useAuth } from "../../utils/hooks/Auth";
import { Loader } from "../../components/Loader";
import CheckBox from "../../components/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import microsoftLogo from "../../assets/Images/microsoft-logo.svg";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


const Login = React.memo(({ handleInputChange, handleLoginForm, formData, errorMessage, inputError, handleRememberMe, toggleIcon, togglePasswordIcon, handleFormSubmission, loginButtonEnabled, handleMicrosoftLogin }) => {
    const {
      loginLoader,
      checkedStatus,
      organizationCode,
      useGetConfigData,
    } = useAuth();
    const { data: userConfigData, isLoading: isUserConfigDataLoading } =
      useGetConfigData();
    const fetchSsoDetails = JSON.parse(sessionStorage.getItem("sso_details"));
    const loginWithSSO = fetchSsoDetails?.sso;

    const navigate = useNavigate();

    if(isUserConfigDataLoading) {
        return <Loader />
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1 }} className={styles.loginContainer}>
                <Grid container spacing={4}>
                    <Grid xs={12} md={5}>
                        <div className={styles.imgWrapper}>
                            <img src={userConfigData?.result?.logoURLReference} alt="Placeholder" />
                        </div>
                    </Grid>
                    <Grid xs={12} md={7}>
                        <div className={styles.formWrapper}>
                            <h1>Welcome to alliantDIGITAL Dox</h1>
                            <h4>Login to your account</h4>
                            <form className={styles.customFormWrapper}>
                                <div className={styles.inputWrapper}>
                                    <CorporateFareIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px', fontSize: "20px" }} />
                                    <input type="text" placeholder="Enter Organization Code" onChange={handleInputChange} name="organizationName" value={organizationCode || ''} disabled onKeyDown={handleFormSubmission} style={inputError.organizationError ? { border: "1px solid #f00" } : { border: "none" }} />
                                    {inputError.organizationError && <p className={styles.inputFieldErrorMessage}>Enter a valid organization name *</p>}
                                </div>
                                <div className={styles.inputWrapper}>
                                    <EmailOutlinedIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px', fontSize: "20px" }} />
                                    <input type="email" placeholder="Enter your Email" onChange={handleInputChange} name="email" value={formData.email} onKeyDown={handleFormSubmission} style={inputError.emailError ? { border: "1px solid #f00" } : { border: "none" }} />
                                    {inputError.emailError && <p className={styles.inputFieldErrorMessage}>Enter a valid email address *</p>}
                                </div>
                                <div className={styles.inputWrapper}>
                                    <HttpsOutlinedIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px', fontSize: "20px" }} />
                                    <input type={toggleIcon ? "text" : "password"} maxLength="16" placeholder='Enter your Password' onChange={handleInputChange} name="password" value={formData.password} onKeyDown={handleFormSubmission} style={inputError.passwordError ? { border: "1px solid #f00" } : { border: "none" }} />
                                    {toggleIcon ? (<HtmlTooltip title="Hide">

                                        <VisibilityOffOutlinedIcon sx={{ position: 'relative', top: '18px', transform: 'translateY(-50%)', marginLeft: '-35px', fontSize: "20px", cursor: "pointer" }} onClick={() => togglePasswordIcon()} />
                                    </HtmlTooltip>

                                    ) : (
                                        <HtmlTooltip title="View">
                                            <VisibilityOutlinedIcon sx={{ position: 'relative', top: '18px', transform: 'translateY(-50%)', marginLeft: '-35px', fontSize: "20px", cursor: "pointer" }} onClick={() => togglePasswordIcon()} />
                                        </HtmlTooltip>

                                    )}
                                    <HtmlTooltip
                                        title={
                                            <p className={styles.tooltipContent}>Password should consists of one uppercase, one lowercase, one special character, minimum 8 characters and maximum 16 characters.</p>
                                        }
                                    >
                                        <InfoIcon sx={{ position: "absolute", bottom: "24px", right: "40px", fontSize: "20px", cursor: "pointer", borderRight: "1px solid #ccc", paddingRight: "5px", color: inputError.passwordError ? "#f00" : "#000" }} />
                                    </HtmlTooltip>

                                    {inputError.passwordError && <p className={styles.inputFieldErrorMessage}>Enter a valid password *</p>}
                                </div>
                            </form>
                            <div className={styles.forgotPasswordWrapper}>
                                <p onClick={() => navigate("/")} onKeyDown={() => { }}><KeyboardBackspaceRoundedIcon sx={{ fontSize: "20px", paddingRight: "2px" }} />Go Back</p> {!loginWithSSO && <p><CheckBox {...label} sx={{ padding: "0" }} onChange={handleRememberMe} checked={checkedStatus} /> Remember me</p>}
                                <p onClick={() => navigate("/forgetpassword")} onKeyDown={() => { }}>Forgot Password?</p>
                            </div>
                            {errorMessage && <Commonerrormessage message="All fields are required *" />}
                            {loginWithSSO ? (
                                <div className={styles.loginButtonWrapper}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginTop: "20px",
                                            width: "80%",
                                            backgroundColor: "var(--secondary-color)",
                                            borderRadius: "0",
                                            padding: "8px",
                                            fontSize: '16px',
                                            '&:hover': { background: 'var(--secondary-color)' }
                                        }}
                                        onClick={() => handleLoginForm()}
                                        disabled={loginButtonEnabled}
                                    >
                                        {loginLoader ? <Loader type='button' /> : "Login"}
                                    </Button>
                                    <p>OR</p>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginTop: "20px",
                                            width: "80%",
                                            backgroundColor: "var(--secondary-color)",
                                            borderRadius: "0",
                                            padding: "8px",
                                            fontSize: '16px',
                                            '&:hover': { background: 'var(--secondary-color)' }
                                        }}
                                        onClick={() => handleMicrosoftLogin()}
                                    >
                                        Login with Microsoft{' '}
                                        <img src={microsoftLogo} alt="microsoft-logo" />
                                    </Button>
                                </div>
                            ) : (
                                <div className={styles.buttonWrapper}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginTop: "20px",
                                            width: "50%",
                                            backgroundColor: "var(--secondary-color)",
                                            borderRadius: "10px",
                                            padding: "12px",
                                            fontSize: '16px',
                                            textTransform: "capitalize",
                                            fontWeight: "600",
                                            '&:hover': { background: 'var(--secondary-color)' }
                                        }}
                                        onClick={() => handleLoginForm()}
                                        disabled={loginButtonEnabled}
                                    >
                                        {loginLoader ? <Loader type='button' /> : "Login"}
                                        <ArrowCircleRightOutlinedIcon sx={{
                                            marginLeft: "10px",
                                            fontSize: "20px"
                                        }} />
                                    </Button>
                                </div>
                            )}

                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
});

export default Login;

Login.propTypes = {
    handleInputChange: PropTypes.func,
    handleLoginForm: PropTypes.func,
    formData: PropTypes.object,
    errorMessage: PropTypes.bool,
    inputError: PropTypes.object,
    handleRememberMe: PropTypes.func,
    handleMicrosoftLogin: PropTypes.func,
    toggleIcon: PropTypes.bool,
    togglePasswordIcon: PropTypes.func,
    handleFormSubmission: PropTypes.func,
    loginButtonEnabled: PropTypes.bool,
};

