import React, { useContext, createContext, useState } from "react";
import {
  viewTicketDetails,
  documentRequestlist,
  ticketDocumentSave,
  requestQuestionList,
  getZippedDocument,
  clientUpdateAnswer,
} from "../../controllers/ticket";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { TokenExpiry } from "../Auth/TokenExpiry";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const DocumentsListContext = createContext();

export const useDocumentListContext = () => useContext(DocumentsListContext);

function useProvideDocumentList() {
  const [documentType, setDocumentType] = useState([]);
  const [storeTableData, setStoreTableData] = useState([]);
  const [storeKeyNames, setStoreKeyNames] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  /**
   * Custom React query hook to fetch ticket details by ID.
   * @param {number} id - The ID of the ticket to fetch.
   * @returns {useQuery} - The query object with specific configurations for fetching ticket details.
   */
  const useGetTicketById = (id) =>
    useQuery(["ticketById", id], () => viewTicketDetails(id), {
      enabled: !!id,
      select: (data) => data?.data,
      onSuccess: (data) => {
        TokenExpiry(data?.ResponseCode);
        if (data?.httpStatus === 204) {
          navigate("ticket");
          toast.error("Ticket not found");
        }
      },
      cacheTime: 0,
    });

  /**
   * Custom hook that fetches a list of document requests using the provided token.
   * @param {string} token - The token used to authenticate the request.
   * @returns {QueryResult} The result of the query containing the document request list.
   */
  const useGetDocumentRequestList = (token) =>
    useQuery(["document-request", token], () => documentRequestlist(token), {
      enabled: !!token,
      select: (data) => data.data,
      onError: (err) => {
        toast.error(err.message);
      },
    });

  const useGetRequestQuestionList = (token) =>
    useQuery(["request-question", token], () => requestQuestionList(token), {
      enabled: !!token,
      select: (data) => data.data,
      onError: (err) => {
        toast.error(err.message);
      },
    });

  /**
   * Custom hook that uses a mutation to save ticket documents.
   * @returns {{
   *   mutate: Function, // Function to trigger the mutation
   *   isLoading: boolean // Flag indicating if the mutation is in progress
   * }}
   */
  const { mutate: mutateDocumentSave, isLoading: isTicketDocumentSaveLoading } =
    useMutation(ticketDocumentSave, {
      onSuccess: (data) => {
        if (data.data.success) {
          toast.success("Document request updated successfully");
          setOpenModal(true);
        } else {
          toast.error(data.data.errors[0]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  /**
   * Custom hook that uses a mutation to save ticket documents.
   * @returns {{
   *   mutate: Function, // Function to trigger the mutation
   *   isLoading: boolean // Flag indicating if the mutation is in progress
   * }}
   */
  const {
    mutate: mutateClientUpdateAnswer,
    isLoading: isClientUpdateAnswerLoading,
  } = useMutation(clientUpdateAnswer, {
    onSuccess: (data) => {
      if (data.data.success) {
        setOpenModal(true);
      } else {
        toast.error(data.data.errors[0]);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const { mutate: mutateDownloadZip, isLoading: isDownloadZipLoading } =
    useMutation(getZippedDocument, {
      onMutate: () => {
        const id = toast.loading("Zipping in progress", {
          position: "bottom-right",
          autoClose: false,
        });
        return id;
      },
      onSuccess: (data, variables, context) => {
        toast.update(context, {
          render: "Document zipped successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          position: "bottom-right",
        });
        const blob = new Blob([data.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "request.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      },
      onError: (error, variables, context) => {
        toast.update(context, {
          render: "Failed to zip the document.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          position: "bottom-right",
        });
      },
    });

  return {
    documentType,
    setDocumentType,
    storeTableData,
    setStoreTableData,
    storeKeyNames,
    setStoreKeyNames,
    useGetTicketById,
    useGetDocumentRequestList,
    mutateDocumentSave,
    isTicketDocumentSaveLoading,
    openModal,
    setOpenModal,
    useGetRequestQuestionList,
    mutateDownloadZip,
    isDownloadZipLoading,
    mutateClientUpdateAnswer,
    isClientUpdateAnswerLoading,
  };
}

export function ProviderDocumentList({ children }) {
  const documentListState = useProvideDocumentList();
  return (
    <DocumentsListContext.Provider value={documentListState}>
      {children}
    </DocumentsListContext.Provider>
  );
}
ProviderDocumentList.propTypes = {
  children: PropTypes.node.isRequired,
};
