import React from "react";
import PropTypes from "prop-types";
import styles from "./ModuleWrapper.module.scss";
import Layout from "../../layouts";
import { useLocation } from "react-router-dom";

const ModuleWrapper = ({ moduleHeader, moduleImage, children }) => {

  const location = useLocation();

  return (
    <Layout>
      <div className={styles.moduleContainer}>
        <p className={styles.moduleHeader}>{moduleHeader}
          <span>
            {location.pathname === "/irs-homescreen" || location.pathname === "/document" ? null : <img src={moduleImage} alt="module image" />}
          </span>
        </p>
        {children}
      </div>
    </Layout>
  );
};

ModuleWrapper.propTypes = {
  moduleHeader: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModuleWrapper;
