import React from "react";
import styles from "./Sidebar.module.scss";
import CustomButton from "../../components/Button";
import { BasicModal } from "../../components/Modal/Modal";
import PropTypes from "prop-types";

const LogoutModal = ({ open, handleCancel, handleLogoutClick }) => {
  return (
    <BasicModal open={open} close={handleCancel}>
      <div className={styles.logoutModalContainer}>
        <div>
          <p>Are you sure want to Logout?</p>
        </div>
        <div className={styles.logoutModalButtons}>
          <CustomButton onClick={handleCancel} variant="outlined">
            Cancel
          </CustomButton>
          <CustomButton onClick={handleLogoutClick}>Logout</CustomButton>
        </div>
      </div>
    </BasicModal>
  );
};
LogoutModal.propTypes = {
  handleLogoutClick: PropTypes.func,
  handleCancel: PropTypes.func,
  open: PropTypes.bool,
};


export default LogoutModal;
