import React from "react";

const LogoutIcon = ({ color = "#111111" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/logout">
        <g id="logout">
          <path
            id="Vector"
            d="M11.125 9.45078C11.5125 4.95078 13.825 3.11328 18.8875 3.11328H19.05C24.6375 3.11328 26.875 5.35078 26.875 10.9383V19.0883C26.875 24.6758 24.6375 26.9133 19.05 26.9133H18.8875C13.8625 26.9133 11.55 25.1008 11.1375 20.6758"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M18.7504 15H4.52539"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M7.3125 10.8125L3.125 15L7.3125 19.1875"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoutIcon;
