import { getApi, postApi, putApi } from "../apis";

/**
 * post api to get all the user list with pagination
 */
export const getUserListPagination = (page, count, search, filterData) =>
  postApi(`user/all?page=${page}&count=${count}&search=${search}`, filterData);

/**
 * post api to get all the user list with pagination
 */
export const getAllUserList = () =>
  getApi(`user/names`);

/**
 * get api to get user details by id
 */
export const getUserById = (id) => getApi(`user?userId=${id}`);

/**
 * post api to create a new user
 */
export const createNewUser = (data) => postApi("user", data);

/**
 * put api to edit the user details by id
 */
export const editUser = (data) => {
  const { id, ...post } = data;
  console.log(id, "===== id");
  return putApi(`user?userId=${id}`, post);
};

/**
 * post api to upload bulk csv for the users
 */

export const userBulkUpload = (data) => postApi("user/bulkupload", data);


