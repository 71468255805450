import React from 'react';
import styles from "./Archive.module.scss";
import ArchiveTable from "./archiveTable";

export default function ArchiveDashboard() {
    return (
        <div className={styles.ArchiveTableWrapper}>
            <ArchiveTable />
        </div>
    )
}
