import React, { useEffect } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const AuthCallbackPage = () => {
  const { error, accounts } = useMsalAuthentication('redirect');
  const navigate = useNavigate();

  /**
   * useEffect hook that triggers when there is an error or when there are accounts available.
   * If there is an error, it logs the error message. If there are accounts available, it logs
   * the first account and navigates to the '/dashboard' route.
   * @param {{error}} error - The error object, if any.
   * @param {{Array}} accounts - An array of accounts.
   * @param {{function}} navigate - The function to navigate to a specific route.
   * @returns None
   */
  useEffect(() => {
    if (error) {
      console.error('Authentication error:', error);
      // Handle authentication error
    } else if (accounts.length > 0) {
      console.log('Authentication successful:', accounts[0]);
      // Redirect to the dashboard page upon successful authentication
      // navigate('/dashboard');
    }
  }, [error, accounts, navigate]);

  return (
    <div>
      {/* Display loading or redirecting message */}
      <p>Redirecting...</p>
    </div>
  );
};

export default AuthCallbackPage;