import React from "react";
import Headercomponent from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import { useAuth, useSideBarContext } from "../utils/hooks";
import styles from "./Layout.module.scss";
import PropTypes from "prop-types";
import { Loader } from "../components/Loader";

const Layout = ({ children, disabled }) => {
  const { isCompact } = useSideBarContext();
  const { useGetConfigData } = useAuth();
  const { data: userConfigData, isLoading: isUserConfigDataLoading } =
    useGetConfigData();

  if (isUserConfigDataLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Headercomponent userConfigData={userConfigData} />
      <Sidebar disabled={disabled} />
      <div
        className={styles.layoutContainer}
        style={
          isCompact
            ? { marginLeft: "60px", transition: "0.5s ease" }
            : {
                marginLeft: "200px",
                transition: "0.5s ease",
              }
        }
      >
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default Layout;
