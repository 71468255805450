import React, { forwardRef, useState } from "react";
import { FormHelperText, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.scss";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CalendarIcon from "../../assets/Icons/CalendarIcon";

const BasicDatePicker = forwardRef(
  (
    {
      value,
      onChange,
      closeOnScroll,
      placeholderText,
      dateFormat,
      error,
      helperText,
      label,
      labelsx,
      isClearable,
      disabled,
      minDate,
      maxDate,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePicker = () => {
      setIsOpen(!isOpen);
    };
    return (
      <Box>
        {label && (
          <Box className="InputLabelComponent" sx={labelsx}>
            {label}
          </Box>
        )}
        <DatePicker
          selected={value}
          closeOnScroll={closeOnScroll}
          onChange={onChange}
          placeholderText={placeholderText}
          dateFormat={dateFormat}
          isClearable={isClearable}
          showIcon
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          open={isOpen}
          onFocus={togglePicker}
          onClickOutside={() => setIsOpen(false)}
          onSelect={() => setIsOpen(false)}
          icon={
            <button
              type="button"
              onClick={togglePicker}
              style={{
                right: 8,
                border: "none",
                height: "24px",
                width: "24px",
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              {(!value || !isClearable) && <CalendarIcon />}
            </button>
          }
        />
        {error && helperText && (
          <FormHelperText className="HelperText">
            <Typography variant="body2 Danger">{helperText}</Typography>
          </FormHelperText>
        )}
      </Box>
    );
  }
);

BasicDatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  closeOnScroll: PropTypes.bool,
  placeholderText: PropTypes.string,
  dateFormat: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelsx: PropTypes.object,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default BasicDatePicker;
