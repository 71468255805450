import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import styles from "./Checkbox.module.scss";
import { forwardRef } from "react";
import PropTypes from "prop-types";

const CheckBox = forwardRef((props, ref) => {
  return (
    <div className={styles.main}>
      <Checkbox
        {...props}
        sx={{
          color: "grey",
          "&.Mui-checked": {
            color: "var(--secondary-color)",
          },
        }}
      />
      {props.label && (
        <Typography variant="h6" className={styles.textlabel}>
          {props.label}
        </Typography>
      )}
    </div>
  );
});

CheckBox.propTypes = {
  label: PropTypes.string, // label prop should be a string
};

export default CheckBox;
