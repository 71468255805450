import React from "react";

const HomeIcon = ({ color = "#111111" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/outline/home-2">
        <g id="home-2">
          <path
            id="Vector"
            d="M22.2375 28.4394H7.7625C4.3375 28.4394 1.5625 25.6519 1.5625 22.2269V12.9644C1.5625 11.2644 2.6125 9.12692 3.9625 8.07692L10.7 2.82692C12.725 1.25192 15.9625 1.17692 18.0625 2.65192L25.7875 8.06442C27.275 9.10192 28.4375 11.3269 28.4375 13.1394V22.2394C28.4375 25.6519 25.6625 28.4394 22.2375 28.4394ZM11.85 4.30192L5.1125 9.55192C4.225 10.2519 3.4375 11.8394 3.4375 12.9644V22.2269C3.4375 24.6144 5.375 26.5644 7.7625 26.5644H22.2375C24.625 26.5644 26.5625 24.6269 26.5625 22.2394V13.1394C26.5625 11.9394 25.7 10.2769 24.7125 9.60192L16.9875 4.18942C15.5625 3.18942 13.2125 3.23942 11.85 4.30192Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M15 23.4375C14.4875 23.4375 14.0625 23.0125 14.0625 22.5V18.75C14.0625 18.2375 14.4875 17.8125 15 17.8125C15.5125 17.8125 15.9375 18.2375 15.9375 18.75V22.5C15.9375 23.0125 15.5125 23.4375 15 23.4375Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
