import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import styles from "./MultiDocumentUpload.module.scss";
import { masterUpload } from "../../../../utils/controllers/master";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Loader } from "../../../../components/Loader";
import { useTicket, UseDocumentUpdateContext } from "../../../../utils/hooks";
import { IconButton } from "@mui/material";
import EllipsisText from "react-ellipsis-text";
import { HtmlTooltip } from "../../../../components/Tooltip";
import { useParams } from "react-router-dom";
import UploadIcon from "../../../../assets/Icons/UploadIcon";
import { TrashIcon } from "../../../../assets/Icons/TrashIcon";
import EyeOpenIcon from "../../../../assets/Icons/EyeOpenIcon";
import DeleteModal from "../../../../components/DeleteModal";
import FilePreviewModal from "../../../../components/FilePreviewModal";

const MultiDocumentUpload = ({
  onChange,
  label,
  labelsx,
  uploadedFile,
  disabled = false,
  isDocumentRequest,
  organizationCode,
}) => {
  const [files, setFiles] = useState(uploadedFile || []);
  const [selectedFile, setSelectedFile] = useState();
  const {
    mutateDeleteTicketDocument,
    openDeleteDocumentModal,
    setOpenDeleteDocumentModal,
    isDeleteTicketDocumentLoading,
  } = useTicket();
  const { fileViewerUrl, setFileViewerUrl } =
    UseDocumentUpdateContext();
  const { id } = useParams();

  const acceptedFileTypes = {
    "application/pdf": [],
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    "image/jpeg": [],
    "image/png": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [],
    "text/csv": [],
  };
  const maxSize = 5 * 1024 * 1024;

  /**
   * Custom hook that uses the useMutation hook to handle file uploads.
   * @param {{Function}} masterUpload - The function to upload the file.
   * @returns {{mutate: Function, isLoading: boolean}} An object containing the mutate function and a boolean indicating if the file upload is in progress.
   */
  const { mutate: mutateFileUpload, isLoading: isFileUploadLoading } =
    useMutation(masterUpload, {
      onSuccess: (data) => {
        const updatedFiles = [
          ...files,
          ...data.data.map((file) => ({
            filestoragereference: file.fileStorageReference,
            fileName: file.fileName,
          })),
        ];
        setFiles(updatedFiles);
        onChange(updatedFiles);
      },
      onError: (err) => {
        if (err.response.status === 413) {
          toast.error("The total document size should be below 5MB");
        } else {
          toast.error(err.message);
        }
      },
    });

  /**
   * Configures the dropzone functionality for file uploads.
   * @param {{string[]}} acceptedFileTypes - Array of accepted file types.
   * @param {{number}} maxSize - Maximum size of the file.
   * @param {{function}} mutateFileUpload - Function to handle file upload mutation.
   * @returns {{object}} - Object containing properties to be used in the dropzone component.
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: maxSize,
    multiple: true,
    disabled: disabled,
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((item) => {
        formData.append("formFile", item);
      });
      if (acceptedFiles.length > 0) {
        mutateFileUpload({ organizationCode, formData });
      }
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.length > 4) {
        toast.error("Please select maximum 4 files at once");
      } else {
        rejectedFiles.forEach((file) =>
          toast.error(
            `${file.file.name} is not a valid file type or exceeds the size limit of 5MB`
          )
        );
      }
    },
  });

  /**
   * Opens a new window to view the document based on the file storage reference.
   * @param {{fileData}} fileData - The data object containing the file storage reference.
   * @returns None
   */
  const handleDocumentView = (fileData) => {
    const { filestoragereference, idrDocumentURL } = fileData;
    setFileViewerUrl(idrDocumentURL || filestoragereference);
  };

  /**
   * Handles the deletion of a file based on the file's properties.
   * If the file has a ticketIDRMultipleDocumentID, it creates deleteData object
   * and updates the files list, triggers onChange callback, and calls mutateDeleteTicketDocument.
   * If the file does not have ticketIDRMultipleDocumentID, it updates the files list,
   * triggers onChange callback, and closes the delete document modal.
   * @param {{object}} file - The file object to be deleted.
   * @returns None
   */
  const handleDelete = (file) => {
    setSelectedFile(null);
    if (file.ticketIDRMultipleDocumentID) {
      const deleteData = {
        ticketId: id,
        list: [
          {
            ticketDocumentID: file.ticketIDRDocumentID,
            isActive: true,
            Status: "Active",
            document: [
              {
                ticketIDRMultipleDocumentID: file.ticketIDRMultipleDocumentID,
                isActive: false,
              },
            ],
          },
        ],
      };
      const updatedFiles = files.filter(
        (item) =>
          item.ticketIDRMultipleDocumentID !== file.ticketIDRMultipleDocumentID
      );
      setFiles(updatedFiles);
      onChange(updatedFiles);
      mutateDeleteTicketDocument(deleteData);
    } else {
      const updatedFiles = files.filter(
        (item) => item.filestoragereference !== file.filestoragereference
      );
      setFiles(updatedFiles);
      onChange(updatedFiles);
      setOpenDeleteDocumentModal(false);
    }
  };

  return (
    <div>
      {label && (
        <div className={styles.inputLabelComponent} style={labelsx}>
          {label}
        </div>
      )}
      <div className={styles.fileUploadContainer}>
        {files.length > 0 && (
          <div className={styles.uploadedFileList}>
            {files.map((file) => (
              <div
                key={file.filestoragereference}
                className={styles.fileNameContainer}
              >
                <p>
                  <EllipsisText
                    text={file.fileName}
                    length={20}
                    tooltip={file.fileName}
                  />
                </p>
                <div className={styles.postContent}>
                  <HtmlTooltip title="View">
                    <div
                      className={styles.innerIconWrap}
                      onKeyDown={() => {}}
                      onClick={() => handleDocumentView(file)}
                    >
                      <EyeOpenIcon width={16} height={16} />
                    </div>
                  </HtmlTooltip>
                  {(!isDocumentRequest ||
                    !file.ticketIDRMultipleDocumentID) && (
                    <HtmlTooltip title="Delete">
                      <div
                        onClick={() => {
                          if (!disabled) {
                            setSelectedFile(file);
                            setOpenDeleteDocumentModal(true);
                          }
                        }}
                        className={styles.innerIconWrap}
                        onKeyDown={() => {}}
                      >
                        <TrashIcon width={16} height={16} />
                      </div>
                    </HtmlTooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {isFileUploadLoading ? (
          <Loader type="button" />
        ) : (
          <div {...getRootProps({ className: "dropzone" })}>
            {files.length === 0 ? (
              <div className={styles.fileUploadField}>
                <div className={styles.placeholder}>Upload/Drag and drop</div>
                <div className={styles.innerIconWrap}>
                  <UploadIcon />
                </div>
              </div>
            ) : (
              <HtmlTooltip title="Upload">
                <IconButton sx={{ color: "black" }}>
                  <UploadIcon color="black" />
                </IconButton>
              </HtmlTooltip>
            )}
            <input
              {...getInputProps()}
              disabled={disabled}
              data-testid="dropzone"
            />
          </div>
        )}
      </div>
      {openDeleteDocumentModal && selectedFile && (
        <DeleteModal
          open={openDeleteDocumentModal}
          handleCancel={() => {
            setOpenDeleteDocumentModal(false);
            setSelectedFile(null);
          }}
          primaryText={`Are you sure want to delete this document?-"${selectedFile?.fileName}"`}
          handleDeleteClick={() => handleDelete(selectedFile)}
          isButtonDisabled={isDeleteTicketDocumentLoading}
        />
      )}
      {fileViewerUrl && (
        <FilePreviewModal
          open={!!fileViewerUrl}
          fileData={fileViewerUrl}
          handleClose={() => setFileViewerUrl(undefined)}
        />
      )}
    </div>
  );
};

MultiDocumentUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelsx: PropTypes.object,
  uploadedFile: PropTypes.array,
  disabled: PropTypes.bool,
  isDocumentRequest: PropTypes.bool,
  organizationCode: PropTypes.string,
};

export default MultiDocumentUpload;
