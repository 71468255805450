import { getApi, postApi } from "../apis";

/**
 * Creates a reminder request for a ticket with the given data.
 */
export const createReminderRequest = (reminderRequestData) => {
  const { id, data } = reminderRequestData;
  return postApi(`Ticket/reminder?ticketId=${id}`, data);
};

/**
 * creates a post request to save the auto reminder data.
 */

export const saveAutoReminderRequest = (autoReminderRequestData) => {
  return postApi(`ReminderSetting`, autoReminderRequestData);
};

/**
 * creates a get request to fetch the auto reminder sent details.
 */

export const getReminderRequest = (ticketId) => {
  return getApi(`GetReminderSetting?ticketId=${ticketId}`);
};


