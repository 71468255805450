import React from 'react';
import { Navigate } from 'react-router-dom';
import { getStorageValue } from "../pages/Login/cookies";
import PropTypes from "prop-types";

const PublicRoute = ({ children }) => {
    const accessLoginToken = getStorageValue("access_token");

    if (accessLoginToken) {
        return <Navigate to="/dashboard" />
    }
    return children;
};
PublicRoute.propTypes = {
    children: PropTypes.node.isRequired,
  };


export default PublicRoute;