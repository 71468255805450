import React from 'react';
import DocumentDashboard from "./Documents";
import Headercomponent from '../../../layouts/Header';
import ModuleWrapper from '../../../components/ModuleWrapper';

export default function DocumentsMain() {
    return (
        <>
            <Headercomponent />
            <ModuleWrapper moduleHeader='Documents'>
                <DocumentDashboard />
            </ModuleWrapper>
        </>
    )
}
