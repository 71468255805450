import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSideBarContext, useAuth } from "../../utils/hooks";
import styles from "./Sidebar.module.scss";
import { clearData } from "../../pages/Login/cookies";
import LogoutModal from "./LogoutModal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { sideBarContentAdmin } from "./Sidebar-data-admin";
import SettingIcon from "../../assets/Icons/navigation-icon/SettingIcon";
import LogoutIcon from "../../assets/Icons/navigation-icon/LogoutIcon";

const Sidebar = React.memo(({ disabled = false }) => {
  const { isCompact } = useSideBarContext();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const onSidebarNavClick = (pageRoute, pageName) => {
    if (!disabled) {
      navigate(pageRoute);
    }
  };

  const handleLogoutModal = () => {
    setOpenLogoutModal(true);
  };

  const handleLogoutClick = () => {
    clearData();
  };

  const sideBarContent = sideBarContentAdmin.filter(
    (item) => user?.Feature?.includes(item.pageName) || item.common
  );

  const sideBarContentClassName = (page) => {
    if (location.pathname.includes(page)) {
      return styles.activeLink;
    }
    return styles.inactiveLink;
  };

  const isSettingVisible = user?.Feature?.includes("Setting");

  return (
    <div className={styles.sidenavWrapper}>
      <div
        className={isCompact ? styles.sideBarCompact : styles.sideBarExtended}
      >
        <ul className={isCompact ? styles.compactList : styles.extendedList}>
          {isCompact
            ? sideBarContent?.map(
              ({
                id,
                pageRoute,
                sideNavIcon,
                activeSideNavIcon,
                pageName,
                page,
              }) => (
                <li key={id}>
                  <Tooltip
                    title={pageName}
                    arrow
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                    TransitionComponent={Zoom}
                  >
                    <span
                      className={sideBarContentClassName(page)}
                      onKeyDown={() => { }}
                      onClick={() => onSidebarNavClick(pageRoute, pageName)}
                    >
                      {location.pathname.includes(page)
                        ? activeSideNavIcon
                        : sideNavIcon}
                    </span>
                  </Tooltip>
                </li>
              )
            )
            : sideBarContent?.map(
              ({
                id,
                pageRoute,
                sideNavIcon,
                activeSideNavIcon,
                pageName,
                page,
              }) => (
                <li
                  key={id}
                  onKeyDown={() => { }}
                  onClick={() => onSidebarNavClick(pageRoute, pageName)}
                >
                  <span className={sideBarContentClassName(page)}>
                    {location.pathname.includes(page)
                      ? activeSideNavIcon
                      : sideNavIcon}
                  </span>
                  <span className={sideBarContentClassName(page)}>
                    {pageName}
                  </span>
                </li>
              )
            )}
        </ul>
        <ul
          className={
            isCompact
              ? styles.sidebarBelowcompactList
              : styles.sidebarBelowextendedList
          }
        >
          {isCompact ? (
            <>
              <li>
                <Tooltip
                  title="Logout"
                  arrow
                  placement="right"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                  TransitionComponent={Zoom}
                >
                  <span
                    onClick={() => handleLogoutModal()}
                    onKeyDown={() => { }}
                  >
                    <LogoutIcon color={"#cccccc"} />
                  </span>
                </Tooltip>
              </li>
              {isSettingVisible && (
                <li>
                  <Tooltip
                    title="Settings"
                    arrow
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                    TransitionComponent={Zoom}
                  >
                    <Link
                      to="/settings"
                      style={{ display: "flex", textDecoration: "none" }}
                    >
                      <span
                        className={
                          location.pathname === "/settings"
                            ? styles.activeLink
                            : styles.inactiveLink
                        }
                      >
                        {
                          <SettingIcon
                            color={
                              location.pathname === "/settings"
                                ? "#111111"
                                : "#cccccc"
                            }
                          />
                        }
                      </span>
                    </Link>
                  </Tooltip>
                </li>
              )}
            </>
          ) : (
            <>
              <li>
                <span>
                  <LogoutIcon color={"#cccccc"} />
                </span>
                <span
                  onClick={() => handleLogoutModal()}
                  onKeyDown={() => { }}
                  style={{ color: "#cccccc" }}
                >
                  Logout
                </span>
              </li>
              {isSettingVisible && (
                <li>
                  <Link
                    to="/settings"
                    style={{ display: "flex", textDecoration: "none" }}
                  >
                    <span
                      className={
                        location.pathname === "/settings"
                          ? styles.activeLink
                          : styles.inactiveLink
                      }
                    >
                      <SettingIcon
                        color={
                          location.pathname === "/settings"
                            ? "#111111"
                            : "#cccccc"
                        }
                      />
                    </span>
                    <span
                      className={
                        location.pathname === "/settings"
                          ? styles.activeLink
                          : styles.inactiveLink
                      }
                    >
                      Settings
                    </span>
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
      {openLogoutModal && (
        <LogoutModal
          open={openLogoutModal}
          handleCancel={() => setOpenLogoutModal(false)}
          handleLogoutClick={handleLogoutClick}
        />
      )}
    </div>
  );
});

Sidebar.propTypes = {
  disabled: PropTypes.bool,
};

export default Sidebar;
