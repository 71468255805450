import React, { useState, createContext, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getSsoStatus } from "../../controllers/org";
import { TokenExpiry } from "../Auth/TokenExpiry";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const CustomerDetailsContext = createContext();

export const useCustomerDetailsContext = () => useContext(CustomerDetailsContext);

const useCustomerDetails = () => {
    const storedItem = sessionStorage.getItem("sso_details");
    const initialState = storedItem && JSON.parse(storedItem);
    const [storeSsoStatus, setStoreSsoStatus] = useState(initialState);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const useGetCustomerDetails = (customerCode) =>
        useQuery(["sso-status", customerCode], () => getSsoStatus(customerCode), {
            enabled: !!customerCode,
            select: (data) => data.data,
            onSuccess: (data) => TokenExpiry(data?.ResponseCode),
            cacheTime: 0,
        });

    const { mutate: mutateCustomerCode, isLoading: dataLoader } = useMutation(
        getSsoStatus,
        {
            onSuccess: (data) => {
                const fetchResults = data?.data;
                if (fetchResults.responseCode === 2087) {
                    toast.error("Invalid Organization Code");
                } else {
                    navigate("/login");
                    queryClient.refetchQueries("user-config-data");
                    setStoreSsoStatus(fetchResults?.result);
                    sessionStorage.setItem("sso_details", JSON.stringify(fetchResults?.result));
                    axios.defaults.headers.common.Authorization = `Bearer ${fetchResults?.token}`;
                }
            },
            onError: (err) => {
                console.log(err, "===== error");
            },
        }
    );

    return {
        useGetCustomerDetails,
        storeSsoStatus,
        mutateCustomerCode,
        dataLoader
    };
};

export function ProvideCustomerDetails({ children }) {
    const customerDetailsState = useCustomerDetails();
    return (
        <CustomerDetailsContext.Provider value={customerDetailsState}>
            {children}
        </CustomerDetailsContext.Provider>
    );
}
ProvideCustomerDetails.propTypes = {
    children: PropTypes.node.isRequired,
};
