import React, { useEffect } from 'react';
import styles from "./View-ticket.module.scss";
import { useDocumentListContext, UseReminderRequestContext } from '../../../utils/hooks';
import CloseIcon from '@mui/icons-material/Close';
import CustomTable from "../../../components/CustomTable";
import CustomButton from "../../../components/Button";
import { Loader } from "../../../components/Loader";
import PropTypes from 'prop-types';
import documentImage from "../../../assets/Images/document.svg";
import Grid from "@mui/material/Unstable_Grid2";
import BasicDatePicker from '../../../components/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useAuth } from "../../../utils/hooks/Auth";
import moment from "moment";
import { useParams } from "react-router-dom";
import CheckBox from '../../../components/Checkbox';

const Modalcontent = React.memo(({ handleModalClose, handleSendReminder, handleTextArea, handleSendReminderButton }) => {
    const { storeTableData } = useDocumentListContext();

    const { sendReminderLoader, disableButton, storeTextAreaValue, autoReminderData, setAutoReminderData, mutateAutoReminderRequestData, autoReminderLoader, mutateGetReminderRequest, getReminderRequestLoader, storeReminderRequests } = UseReminderRequestContext();

    const { user } = useAuth();

    const { id } = useParams();

    useEffect(() => {
        mutateGetReminderRequest(id);
    }, [])


    /**
     * Filters the storeTableData array to find keys with idrDocumentReceived set to false,
     * and populates keysWithFalseVerification array with objects containing documentType and ticketIDRDocumentID.
     */
    const keysWithFalseVerification = [];
    storeTableData.filter((key) => {
        if (key.idrDocumentReceived === false) {
            return keysWithFalseVerification.push({
                documentType: key.document_type,
                ticketIDRDocumentID: key.ticketIDRDocumentID
            });
        }
    })

    const columns = [
        {
            name: "request_name",
            label: "Request Name",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (value) => {
                    return (
                        <>
                            {keysWithFalseVerification.map((type, index) => {
                                if (index === value) {
                                    return (
                                        <div key={type.ticketIDRDocumentID} className={styles.documentNameWrapper}>
                                            <img src={documentImage} />
                                            <p style={{ textAlign: "left" }}>{type.documentType}</p>
                                        </div>
                                    )
                                }
                                return null; // Ensure to have a return for each iteration
                            })}
                        </>
                    )
                },
                setCellProps: () => ({
                    style: {
                        left: "0",
                        background: "#ffffff",
                        color: '#4A4A4A',
                        zIndex: 100,
                        paddingBottom: "20px",
                        textTransform: 'capitalize',
                        textAlign: "center",
                    },
                }),
                setCellHeaderProps: () => ({
                    style: {
                        left: 0,
                        height: 20,
                        background: "rgba(29, 29, 29, 0.02)",
                        fontSize: '12px',
                        fontWeight: '600',
                        textAlign: "left",
                        zIndex: 20,
                        padding: "8px",
                        borderBottom: "0"
                    },
                }),
            }
        },
        {
            name: "note",
            label: "Note",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (value) => {
                    return (
                        <>
                            {keysWithFalseVerification.map((type, index) => {
                                if (index === value) {
                                    return (
                                        <React.Fragment key={type.ticketIDRDocumentID}>
                                            <textarea
                                                aria-label="empty textarea"
                                                placeholder="Add a reminder note"
                                                className={styles.textAreaStyles}
                                                onChange={(e) => handleTextArea(e.target.value, type.ticketIDRDocumentID)}
                                                value={storeTextAreaValue[type.ticketIDRDocumentID]}
                                                maxLength="256"
                                            />
                                        </React.Fragment>
                                    )
                                }
                                return null; // Ensure to have a return for each iteration
                            })}
                        </>
                    )
                },
                setCellProps: () => ({
                    style: {
                        left: "0",
                        background: "#ffffff",
                        color: '#4A4A4A',
                        zIndex: 100,
                        paddingBottom: "20px",
                        textTransform: 'capitalize',
                        textAlign: "center",
                    },
                }),
                setCellHeaderProps: () => ({
                    style: {
                        left: 0,
                        height: 20,
                        background: "rgba(29, 29, 29, 0.02)",
                        fontSize: '12px',
                        fontWeight: '600',
                        textAlign: "left",
                        zIndex: 20,
                        padding: "8px",
                        borderBottom: "0"
                    },
                }),
            }
        },
        {
            name: "select",
            label: "Select",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (value) => {
                    const checkBoxData = keysWithFalseVerification[value];
                    return (
                        <CheckBox onChange={(e) => handleSendReminder(e.target.checked, checkBoxData)} />
                    )
                },
                setCellProps: () => ({
                    style: {
                        left: "0",
                        background: "#ffffff",
                        color: '#4A4A4A',
                        zIndex: 100,
                        padding: "0!important",
                        textTransform: 'capitalize',
                        textAlign: "center"
                    },
                }),
                setCellHeaderProps: () => ({
                    style: {
                        left: 0,
                        height: 20,
                        background: "rgba(29, 29, 29, 0.02)",
                        fontSize: '12px',
                        fontWeight: '600',
                        textAlign: "center",
                        zIndex: 20,
                        padding: "8px",
                        borderBottom: "0"
                    },
                }),
            }
        }
    ]

    const FrequencyData = [
        {
            name: "Weekly",
            value: "weekly",
            id: 1
        },
        {
            name: "Every 2 weeks",
            value: "every2Week",
            id: 2
        },
        {
            name: "Monthly",
            value: "monthly",
            id: 3
        }
    ]

    /**
     * function below is handling the auto reminders checkbox value.
     * the if else block is reponsible for handliing different scenerious.
     * the if is handling the scenerio wherien if storeReminderRequests is null the continue with the original flow.
     * the else part is responsible for sending the desired payload to call a post api which would give us the updated checkbox value.
     * @returns none
     */
    const handleAutoReminderCheckbox = (value) => {
        const checkboxValue = value;
        const convertToInt = parseInt(user?.UserID);

        if (!checkboxValue) {
            setAutoReminderData((prevState) => ({
                ...prevState,
                dueDate: "",
                radioButtonValue: "",
            }))
        }

        if (storeReminderRequests === null) {
            setAutoReminderData((prevState) => ({
                ...prevState,
                toggleCheckbox: !autoReminderData.toggleCheckbox,
                checkedStatus: checkboxValue
            }))
        } else {
            const requestPayload = {
                "ticketId": parseInt(id),
                "userId": convertToInt,
                "isActive": checkboxValue
            }

            mutateAutoReminderRequestData(requestPayload);
        }

    }

    /**
     * function to handle the onchange for the date picker.
     */

    const handleDueData = (value) => {
        const datePickerValue = value;
        setAutoReminderData((prevState) => ({
            ...prevState,
            dueDate: datePickerValue
        }))
    }

    /**
     * function to handle the onchange for the radio buttons.
     */

    const handleFrequency = (value) => {
        const radioButtonValue = value;
        setAutoReminderData((prevState) => ({
            ...prevState,
            radioButtonValue: radioButtonValue
        }))
    }

    /**
     * handle auto reminders function is responsible for performing various actions.
     * it is first creating  a default object with the name frequency.
     * it is creating another object with the name saved frequency and the values are from the get api
     * the variable convert to int is converting the string to an interger bacause the backend would be expecting an int value.
     * the current date variable is converting the date as per the utc standard using moment.js
     * switch statement is responsible for assigning the respective variables to the saved frequency object or it will assign the boolean true.
     */

    const handleAutoReminderData = () => {

        let frequency = {
            ["every2Week"]: false,
            month: false,
            week: false
        }

        const savedFrquency = {
            every2Week: storeReminderRequests?.every2Week,
            month: storeReminderRequests?.month,
            week: storeReminderRequests?.week,
        };

        const convertToInt = parseInt(user?.UserID);
        const currentDate = moment.utc(autoReminderData.dueDate);
        let reminderDate = currentDate.add(1, 'day').format();

        switch (autoReminderData.radioButtonValue) {
            case "weekly":
                frequency.week = savedFrquency.week || true;
                break;
            case "monthly":
                frequency.month = savedFrquency.month || true;
                break;
            default:
                frequency["every2Week"] = savedFrquency.every2Week || true;
                break;
        }

        const requestPayload = {
            "ticketId": parseInt(id),
            "userId": convertToInt,
            "every2Week": frequency["every2Week"],
            "month": frequency.month,
            "week": frequency.week,
            "reminderDate": storeReminderRequests === null ? reminderDate : storeReminderRequests?.reminderDate,
            "isActive": storeReminderRequests?.isActive || autoReminderData.checkedStatus
        }

        mutateAutoReminderRequestData(requestPayload);
        // console.log(requestPayload, "===== request payload");
    }

    /**
     * the function below is handling mutiple scenerious to render the default value for the radio groups.
     */
    const renderDefaultValue = () => {
        if (storeReminderRequests?.week) {
            return "weekly"
        } else if (storeReminderRequests?.every2Week) {
            return "every2Week"
        } else if (storeReminderRequests?.month) {
            return "monthly"
        } else {
            return false
        }
    }

    /**
     * for handling the toggle state of the auto reminder component.
     * @returns 
     */

    const renderContentBasedOnCheckedValue = () => {
        if (storeReminderRequests === null) {
            return autoReminderData.toggleCheckbox;
        } else {
            return storeReminderRequests.isActive;
        }
    }

    /**
     * for handling the save button disabled states.
     * @returns 
     */
    const renderBtnDisableStates = () => {
        if (storeReminderRequests === null) {
            return autoReminderData.dueDate === '' || autoReminderData.radioButtonValue === ''
        } else {
            if (storeReminderRequests?.week || storeReminderRequests?.month || storeReminderRequests?.every2Week || storeReminderRequests?.reminderDate) {
                return false
            }
        }
    }

    return (
        <div className={styles.customModalWrapper}>
            {getReminderRequestLoader ? <Loader /> : (
                <>
                    <h6>
                        Send Reminder
                        <CloseIcon onClick={handleModalClose} sx={{ cursor: "pointer" }} />
                    </h6>
                    <div className={styles.customModalContent}>
                        <CustomTable
                            data={keysWithFalseVerification}
                            columns={columns}
                            className={styles.customModalTable}
                        />
                    </div>
                    <div className={styles.customModalButtonWrapper}>
                        <div className={styles.inputCheckWrapper}>
                            <CheckBox onChange={(e) => handleAutoReminderCheckbox(e.target.checked)} checked={storeReminderRequests === null ? autoReminderData.checkedStatus : storeReminderRequests.isActive} />
                            {/* <input type='checkbox' onChange={(e) => handleAutoReminderCheckbox(e.target.checked)} checked={storeReminderRequests === null ? autoReminderData.checkedStatus : storeReminderRequests.isActive} /> */}
                            <p>Enable auto-reminders </p>
                        </div>
                        <div className={styles.formButtonWrapper}>
                            <CustomButton
                                onClick={handleSendReminderButton}
                                disabled={disableButton || sendReminderLoader}
                                style={{ width: "100px" }}
                            >
                                {sendReminderLoader ? <Loader type="button" /> : "Send"}
                            </CustomButton>
                        </div>
                    </div>
                    {renderContentBasedOnCheckedValue() && (
                        <div className={styles.autoReminderComponentWrapper}>
                            <Grid container spacing={8}>
                                <Grid md={4}>
                                    <p>Start Date</p>
                                    <BasicDatePicker
                                        placeholderText="Select a Date"
                                        value={storeReminderRequests === null ? autoReminderData.dueDate : new Date(storeReminderRequests?.reminderDate)}
                                        // isClearable
                                        onChange={(e) => {
                                            handleDueData(e);
                                        }}
                                    />
                                </Grid>
                                <Grid md={8}>
                                    <div className={styles.radioBtnWrapper}>
                                        <p>Frequency</p>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue={storeReminderRequests === null ? null : renderDefaultValue()}
                                                name="radio-buttons-group"
                                                row
                                                onChange={(e) => handleFrequency(e.target.value)}
                                            >
                                                {FrequencyData.map((items) => {
                                                    return (
                                                        <FormControlLabel value={items.value} key={items.id} control={<Radio sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 15,
                                                            },
                                                        }} />} label={items.name} />
                                                    )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    <div className={styles.formBtn}>
                        <CustomButton variant="text" onClick={handleModalClose} style={{ color: "#f00", fontWeight: "500", marginRight: "10px" }}>
                            Cancel
                        </CustomButton>
                        <CustomButton onClick={handleAutoReminderData} style={{ width: "100px" }} disabled={renderBtnDisableStates()}>
                            {autoReminderLoader ? <Loader type="button" /> : "Save"}
                        </CustomButton>
                    </div>
                </>
            )}
        </div>
    );
});

Modalcontent.propTypes = {
    handleModalClose: PropTypes.func,
    handleSendReminder: PropTypes.func,
    handleTextArea: PropTypes.func,
    handleSendReminderButton: PropTypes.func
};
export default Modalcontent;