import React from "react";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";

export function BasicModal({ open, close, children, fullScreen, maxWidth }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
      >
        {children}
      </Dialog>
    </div>
  );
}

BasicModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};