import React from "react";
import styles from "./Header.module.scss";
import { useSideBarContext } from "../../utils/hooks";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const Header = React.memo(({ openSidebar, closeSidebar, userConfigData }) => {
  const location = useLocation();
  const { isCompact } = useSideBarContext();
  return (
    <div className={styles.headerComponentWrapper}>
      <div className={styles.sidebar_menu_icon_wrapper}>
        {isCompact ? (
          <MenuIcon
            sx={{
              cursor: "pointer",
              display:
                location.pathname === "/documentrequested" ? "none" : "block",
            }}
            onClick={() => openSidebar()}
          />
        ) : (
          <ArrowBackIcon
            sx={{
              cursor: "pointer",
              display:
                location.pathname === "/documentrequested" ? "none" : "block",
            }}
            onClick={() => closeSidebar()}
          />
        )}
      </div>
      <div className={styles.logoWrapper}>
        <img src={userConfigData?.result?.logoURLReference} alt="Logo" />
        <h6>{userConfigData?.result?.name}</h6>
      </div>
    </div>
  );
});

Header.propTypes = {
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
};

export default Header;
