import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

export const Loader = ({ type = "table", color = "grey", size }) => {
  if (type === "section") {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--secondary-color)",
        }}
      >
        <CircularProgress color={color} size={size} />
      </Box>
    );
  }
  if (type === "table") {
    return (
      <Box
        sx={{
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--secondary-color)",
        }}
      >
        <CircularProgress color={color} size={size} />
      </Box>
    );
  }
  if (type === "button") {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 5px",
        }}
      >
        <CircularProgress color={color} size={24} />
      </Box>
    );
  }
  return null;
};

Loader.propTypes = {
  type: PropTypes.oneOf(["section", "table", "button"]),
  color: PropTypes.string,
  size: PropTypes.number,
};