import React from "react";
import styles from "./Dashboard.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks/Auth";
import CustomButton from "../../components/Button";
import frame from "../../assets/Images/dashboard-images/frame.svg";
import building from "../../assets/Images/dashboard-images/building.svg";
import groupImage from "../../assets/Images/dashboard-images/profile-2user.svg";
import plusImage from "../../assets/Images/add-circle.svg";

export default function Dashboard() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const quickAccessData = [
    {
      id: 3,
      icon: <img src={frame} alt="grid-1 image" />,
      gridheading: "Manage Requests",
      buttonText: "New Request",
      navigate: "/ticket",
      pageName: "Request Management",
    },
    {
      id: 2,
      icon: <img src={building} alt="building image" />,
      gridheading: "Manage Clients",
      buttonText: "New Client",
      navigate: "/client",
      pageName: "Client Management",
    },
    {
      id: 1,
      icon: <img src={groupImage} alt="profile image" />,
      gridheading: "Manage Users",
      buttonText: "New User",
      navigate: "/user",
      pageName: "User Management",
    },
  ];

  const roleAccessData = quickAccessData.filter((item) => user?.Feature.includes(item.pageName));

  return (
    <Grid container spacing={5} className={styles.gridWrapper}>
      {roleAccessData.map((items) => {
        return (
          <Grid xs={12} md={6} lg={4} key={items.id}>
            <div
              className={styles.gridContentWrapper}
              // style={{ opacity: items.opacity }}
              onClick={() => navigate(items.navigate)}
              onKeyDown={() => { }}
            >
              <div className={styles.moduleIconWrapper}>
                <h1 className={styles.gridheading}>{items.gridheading}</h1>
                {items.icon}</div>
              <div className={styles.gridButtonWrapper}>
                <CustomButton
                  disabled={items.disabled}
                  variant="contained"
                  sx={{
                    marginTop: "20px",
                    backgroundColor: "#565E6DFF",
                    padding: "8px",
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Stop propagation here
                    navigate(`${items.navigate}/add`);
                  }}
                >
                  <img src={plusImage} alt="an image with a plus symbol" />
                  {items.buttonText}
                </CustomButton>
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
