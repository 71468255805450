import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import CustomButton from "../../components/Button";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { toast } from "react-toastify";
import { useClientBulkUpload } from '../../utils/hooks/Client/Bulk-upload';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#000',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    justifyContent: "center"
};


export default function BulkUploadCsvFiles() {

    const { mutateCreateClientBulkUpload } = useClientBulkUpload();

    // const acceptedFileTypes = {
    //     "application/csv": [],
    //     // "application/vnd.ms-excel": [],
    //     // "text/csv": [],
    // };

    const { getRootProps, getInputProps } = useDropzone({
        // accept: acceptedFileTypes,
        // // maxSize: maxSize,
        // multiple: true,
        // // maxFiles: 4,
        accept: {
            'text/csv': []
        },
        onDrop: acceptedFiles => {
            const formData = new FormData();
            acceptedFiles.forEach((item) => {
                formData.append("files", item);
            });

            mutateCreateClientBulkUpload(formData);
        },
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles.length > 4) {
                toast.error("Please select maximum 4 files at once");
            } else {
                rejectedFiles.forEach((file) =>
                    toast.error(
                        `${file.file.name} is not a valid file type or exceeds the size limit of 5MB`
                    )
                );
            }
        },
    });

    const style = useMemo(() => ({
        ...baseStyle,
    }), []);

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <>
                    <CustomButton style={{ width: "151px" }}>
                        <UploadFileIcon />
                        Bulk Upload
                    </CustomButton>
                </>
            </div>
        </>
    )
}
