import { postApi } from "../apis";


/**
 * Creates a request to reset the password using the provided data.
 */
export const createResetPasswordRequest = (data) => {
    const urlParams = new URLSearchParams(window.location.search);
    const oneTimeToken = urlParams.get('token');
    const resetPasswordEndpoint = `user/reset`;

    const headers = {
        'Content-Type': 'application/json',
        'token': `${oneTimeToken}`,
        'Authorization': 'Bearer undefined', // Include the one-time token in the Authorization header
    }

    return postApi(resetPasswordEndpoint, data, { headers });
};
