import { getApi, putApi } from "../apis";

export const getSsoStatus = (customerCode) =>
  getApi(`user/customer?customerCode=${customerCode}`, {
    headers: { Authorization: "Bearer undefined" },
  });

export const getConfigData = (customerCode) =>
  getApi(`user/logo?code=${customerCode}`, {
    headers: { Authorization: "Bearer undefined" },
  });

export const updateUserConfigData = ({ name, color, formData }) => {
  return putApi(`Update?name=${name}&color=${color}`, formData);
};
