import React, { createContext, useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createDocumentReceived, checkboxDataReceived, fetchEncryptedURL } from "../../controllers/Document-received";
import { isTokenExpired, getFileType } from "../../helper";
import { TokenExpiry } from "../Auth/TokenExpiry";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";


const CreateDocumentUpdateContext = createContext();

export const UseDocumentUpdateContext = () =>
  useContext(CreateDocumentUpdateContext);

const UseHandleDocumentUpdateData = () => {

  const [fileViewerUrl, setFileViewerUrl] = useState();
  const [qParams] = useSearchParams();

  const getCustomerCode = localStorage.getItem('org_name')?.toUpperCase();

  const organizationCode = qParams.get("code")?.toUpperCase();

  const customerCode = organizationCode || getCustomerCode

  const queryClient = useQueryClient();

  /**
   * Custom hook that uses a mutation to create a document received, with success and error handling.
   * @returns {{
   *   mutate: Function, // Function to trigger the mutation
   *   isLoading: boolean // Flag indicating if the mutation is in progress
   * }}
   */
  const { mutate: mutateDocumentUpdateData, isLoading: isDocumentUpdateDataLoading } =
    useMutation(createDocumentReceived, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else {
          queryClient.refetchQueries("ticketById");
          queryClient.refetchQueries("ticketList");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const { mutate: mutateCheckboxUpdateData, isLoading: isCheckboxUpdateDataLoading } =
    useMutation(createDocumentReceived, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else {
          queryClient.refetchQueries("ticketById");
          queryClient.refetchQueries("ticketList");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const handleCheckboxUpdateData = (data, id) => {
    mutateCheckboxUpdateData({ data, id });
  }

  /**
   * Handles updating the document data with the given data and id.
   * @param {any} data - The data to update the document with.
   * @param {string} id - The id of the document to update.
   */
  const handleDocumentUpdateData = (data, id) => {
    mutateDocumentUpdateData({ data, id });
  };

  const useGetFileUrl = (fileUrl) => useQuery(
    ["file_url", fileUrl],
    () => fetchEncryptedURL(fileUrl, customerCode),
    {
      enabled: !!fileUrl,
      select: (data) => data?.data,
      onSuccess: (data) => {
        TokenExpiry(data?.ResponseCode);
      },
      cacheTime: 0,
    }
  );

  return {
    handleDocumentUpdateData,
    handleCheckboxUpdateData,
    isDocumentUpdateDataLoading,
    isCheckboxUpdateDataLoading,
    useGetFileUrl,
    fileViewerUrl,
    setFileViewerUrl
  };
};

export function ProvideDocumentUpdate({ children }) {
  const documentUpdate = UseHandleDocumentUpdateData();
  return (
    <CreateDocumentUpdateContext.Provider value={documentUpdate}>
      {children}
    </CreateDocumentUpdateContext.Provider>
  );
}

ProvideDocumentUpdate.propTypes = {
  children: PropTypes.node.isRequired,
};