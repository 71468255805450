import React from "react";
import Box from "@mui/material/Box";
import styles from "./document-requested.module.scss";
import CustomTable from "../../components/CustomTable";
import CustomButton from "../../components/Button";
import MultiDocumentUpload from "../TicketManagement/CreateOrEditTicket/MultiDocumentUpload";
import { Controller } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import FileIcon from "../../assets/Icons/FileIcon";
import MessageIcon from "../../assets/Icons/MessageIcon";
import { TextInput } from "../../components/InputBox";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [5, -10],
            },
          },
        ],
      },
    }}
    placement="right"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#565E6DFF",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: "10px",
    padding: "10px",
    boxShadow:
      " 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);",
    zIndex: "50",
  },
}));

export default function DocumentRequested({
  documentList,
  questionList,
  control,
  isTicketDocumentSaveLoading,
  organizationCode,
}) {

  const combinedList = [...documentList, ...questionList];

  const setHeaderProp = () => {
    return {
      style: {
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: "19.5px",
        background: "#efefef",
        borderBottom: "0",
        textAlign: "left",
      },
    };
  };

  const columns = [
    {
      name: "documentName",
      label: "Document Type",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: setHeaderProp,
        customBodyRender: (value, tableMeta) => {
          const rowData = combinedList[tableMeta.rowIndex];
          return (
            <div className={styles.documentType}>
              <div className={styles.documentTypeIcon}>
                {rowData.documentName ? <FileIcon /> : <MessageIcon />}
              </div>
              <p>{rowData.documentName || rowData.question}</p>
              {rowData?.note && (
                <HtmlTooltip
                  title={
                    <div className={styles.tooltipWrapper}>{rowData.note}</div>
                  }
                >
                  <InfoOutlinedIcon
                    sx={{ fontSize: "22px", cursor: "pointer" }}
                  />
                </HtmlTooltip>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "multipleDocumentList",
      label: "Documents",
      options: {
        setCellHeaderProps: setHeaderProp,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const rowData = combinedList[rowIndex];
          return (
            <div className={styles.documentUpload}>
              {rowData?.documentName ? (
                <Controller
                  name={`ticketIDRDocuments[${rowIndex}].multipleDocumentList`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MultiDocumentUpload
                      isMutli
                      isDocumentRequest
                      onChange={onChange}
                      uploadedFile={value}
                      organizationCode={organizationCode}
                    />
                  )}
                />
              ) : (
                <div className={styles.textArea}>
                  <Controller
                    name={`idrQuestions[${
                      rowIndex - documentList.length
                    }].answers`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextInput
                        {...field}
                        placeholder="Enter response..."
                        multiline
                        minRows={3}
                        maxRows={3}
                        error={!!error}
                        helpertext={error?.message || null}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    },
  ];

  return (
    <Box className={styles.documentRequestedWrapper}>
      <p>Document Requested</p>
      <div className={styles.document_list_table}>
        <CustomTable
          key={combinedList}
          data={combinedList}
          columns={columns}
          noDataText="No Documents Found"
          maxHeight="calc(100vh - 250px)"
        />
      </div>
      <div className={styles.reminderButtonWrapper}>
        <CustomButton
          disabled={isTicketDocumentSaveLoading || documentList?.length === 0}
          type="submit"
          size="medium"
          sx={{ marginTop: "10px" }}
        >
          Save
        </CustomButton>
      </div>
    </Box>
  );
}
DocumentRequested.propTypes = {
  documentList: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  isTicketDocumentSaveLoading: PropTypes.bool,
  organizationCode: PropTypes.string,
};
