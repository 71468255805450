export function SearchIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} fill="white" fillOpacity={0.01} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4358 15.085L20.3758 19.095C20.5523 19.2858 20.6476 19.5378 20.6414 19.7976C20.6353 20.0575 20.5283 20.3047 20.3431 20.487C20.1578 20.6693 19.9088 20.7722 19.6489 20.7741C19.389 20.776 19.1386 20.6766 18.9508 20.497L15.0128 16.491C13.4933 17.6357 11.5956 18.1602 9.70403 17.9583C7.81242 17.7564 6.06821 16.8432 4.82457 15.4036C3.58094 13.9641 2.93081 12.1057 3.00584 10.2048C3.08087 8.30394 3.87544 6.5026 5.22867 5.16554C6.5819 3.82848 8.39266 3.05561 10.2943 3.00343C12.1959 2.95126 14.0464 3.62367 15.4709 4.88451C16.8954 6.14536 17.7875 7.90042 17.9667 9.79432C18.1458 11.6882 17.5986 13.5794 16.4358 15.085ZM10.4998 16C11.9584 16 13.3574 15.4206 14.3888 14.3891C15.4203 13.3577 15.9998 11.9587 15.9998 10.5C15.9998 9.04134 15.4203 7.64239 14.3888 6.61094C13.3574 5.57949 11.9584 5.00003 10.4998 5.00003C9.04106 5.00003 7.64212 5.57949 6.61067 6.61094C5.57922 7.64239 4.99976 9.04134 4.99976 10.5C4.99976 11.9587 5.57922 13.3577 6.61067 14.3891C7.64212 15.4206 9.04106 16 10.4998 16Z"
        fill="#717171"
      />
    </svg>
  );
}
