import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

import styles from "../ViewUser/CreateUser.module.scss";
import CustomButton from "../../../components/Button";
import FormLayout from "../FormLayout";
import { useUser } from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../layouts";
import { Loader } from "../../../components/Loader";
import PropTypes from "prop-types";

import link4 from "../../../assets/Images/navigation-images/link-4.svg";
export function AddOrEditUser({ type }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleSubmit, control, reset } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      role: "",
      fName: "",
      lName: "",
      contact: "",
    },
  });

  const {
    useGetUserById,
    handleCreateUser,
    isCreateUserLoading,
    isEditUserLoading,
    handleEditUser,
  } = useUser();

  const { data: userData, isLoading: isUserDataLoading } = useGetUserById(id);

  /**
   * Handles form submission based on the type of operation.
   * If the type is "add", it calls the handleCreateUser function with the provided data.
   * If the type is not "add", it creates a new object with the provided data and id, then calls the handleEditUser function.
   * @param {{object}} data - The data submitted from the form.
   * @returns None
   */
  const onSubmit = (data) => {
    if (type === "add") {
      handleCreateUser(data);
    } else {
      const finalData = {
        ...data,
        id,
      };
      handleEditUser(finalData);
    }
  };

  function headerText() {
    if (type === "edit") {
      return "Edit User";
    }
    if (type === "add") {
      return "Add  User";
    }
  }

  /**
   * useEffect hook that triggers when userData.result changes. If both id and userData are truthy,
   * it resets the form with the userData result and sets the role label and value based on the userData result.
   * @returns None
   */
  useEffect(() => {
    if (id && userData) {
      reset({
        ...userData?.result,
        role: {
          label: userData?.result?.roleName,
          value: userData?.result?.roleId,
        },
      });
    }
  }, [userData?.result]);

  const isButtonDisable = isCreateUserLoading || isEditUserLoading;

  return (
    <Layout>
      {isUserDataLoading ? (
        <Loader />
      ) : (
        <Box>
          <Box className={styles.flex}>
            <Typography className={styles.headerText}>
              {headerText()}
              <img src={link4} alt="Edit User" />
            </Typography>
          </Box>
          <Box>
            <Box className={styles.adduserContainer}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={styles.mainContainer}>
                  <Box className={styles.UserCard}>
                    <Typography className={styles.formHeader}>
                      User Details
                    </Typography>

                    <FormLayout control={control} isEdit={type === "edit"} />

                    <Box>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        wrap="nowrap"
                        gap={2}
                        className={styles.BottomButtons}
                      >
                        <CustomButton
                          variant="text"
                          size="medium"
                          onClick={() => navigate(-1)}
                          disabled={isButtonDisable}
                          style={{ color: "#f00" }}
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          size="medium"
                          disabled={isButtonDisable}
                        >
                          Save
                        </CustomButton>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
AddOrEditUser.propTypes = {
  type: PropTypes.oneOf(["edit", "add"]).isRequired,
};
