import React from "react";
import { Box, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import "./SectionHeader.scss";
import { SearchIcon } from "../../assets/Icons/SearchIcon";
import PlusIcon from "../../assets/Images/add-circle.svg";
import CustomButton from "../Button";
import { useLocation } from "react-router-dom";

const SectionHeader = ({
  searchText,
  handleSearchValue,
  autoFocus,
  buttonText,
  children,
  onButtonClick,
  placeholder = "Search",
}) => {

  const location = useLocation();

  return (
    <Grid container spacing={2} className="section_header_container">
      <Grid item xs={10} sm={8} md={3} lg={2.5} sx={{ paddingLeft: "0" }}>
        <Box className="search_input_container">
          <TextField
            variant="outlined"
            placeholder={placeholder}
            value={searchText}
            onChange={handleSearchValue}
            autoFocus={autoFocus}
            style={{ paddingLeft: "0" }}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={10} sm={8} md={9} lg={9.5}>
        <Box className="section_header_right" style={location.pathname === "/ticket" ? { paddingRight: "50px" } : { paddingRight: "0" }}>
          <Box className="section_header_filters">{children}</Box>
          {buttonText && (
            <CustomButton onClick={onButtonClick}>
              <img src={PlusIcon} />
              {buttonText}
            </CustomButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

SectionHeader.propTypes = {
  searchText: PropTypes.string.isRequired,
  handleSearchValue: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  onButtonClick: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SectionHeader;
