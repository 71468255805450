import { getApi, postApi, putApi } from "../apis";

export const getAllGroupName = () => {
    return getApi('client/all/Group');
}

export const addNewGroupName = (data) => {
    return postApi('client/Group', data);
};

export const noGroupNames = () => {
    return getApi('client/No/Group');
}

export const updateGroup = (groupData) => {
    const { UserID, groupName } = groupData;
    return putApi(`client/update/group?GroupName=${groupName}&userId=${UserID}`, groupData.data)

}
