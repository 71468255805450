import React from "react";
import styles from "./Profile.module.scss";
import CustomButton from "../../components/Button";
import { useProfileContext } from "../../utils/hooks/Profile";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { areAllValuesNull } from "../../utils/helper";
import Grid from "@mui/material/Unstable_Grid2";
import { TextInput } from "../../components/InputBox";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [5, -2],
            },
          },
        ],
      },
    }}
    placement="right"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#565E6DFF",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: "10px",
    padding: "10px",
    boxShadow:
      " 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);",
    zIndex: "50",
  },
}));

export default function Profile({
  handleTogglePasswordField,
  handlePassword,
  savePassword,
  handleFormField,
  togglePasswordIcon,
  toggleConfirmPasswordIcon,
  storeUserDetails,
  inputFieldError,
}) {
  const {
    showPasswordField,
    password,
    passwordMatch,
    toggleIcon,
    inputError,
    enableButton,
  } = useProfileContext();

  return (
    <div className={styles.profileContainer}>
      <h2>User Details</h2>
      <Grid container sm={12} xs={12} md={10} lg={8}>
        <Grid container item spacing={6}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              label="First Name"
              placeholder="Please add first name"
              defaultValue={storeUserDetails?.fName}
              name="fName"
              type="text"
              onChange={handleFormField}
              error={!!inputFieldError.firstNameError}
              helpertext={inputFieldError.firstNameError}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <TextInput
              label="Last Name"
              placeholder="Please enter last name"
              defaultValue={storeUserDetails?.lName}
              type="text"
              name="lName"
              onChange={handleFormField}
              error={!!inputFieldError.lastNameError}
              helpertext={inputFieldError.lastNameError}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6}>
            <TextInput
              label="Phone Number"
              placeholder="Please enter contact no."
              defaultValue={storeUserDetails?.contact}
              type='text'
              name="contact"
              onChange={handleFormField}
              error={!!inputFieldError.phoneNumberError}
              helpertext={inputFieldError.phoneNumberError}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <TextInput
              label="Email"
              placeholder="Please enter an email id."
              defaultValue={storeUserDetails?.email}
              type="email"
              name="email"
              disabled
              onChange={handleFormField}
            />
          </Grid>
        </Grid>
        <div className={styles.togglePasswordWrapper}>
          <h4>Change Password</h4>

          <Grid container item spacing={6} flexDirection={"column"}>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextInput
                label="Enter New Password"
                type={toggleIcon.passwordIcon ? "text" : "password"}
                name="currentPassword"
                onChange={handlePassword}
                value={password.currentPassword}
                postcontent={
                  toggleIcon.passwordIcon ? (
                    <HtmlTooltip title="Hide">
                      <VisibilityOffOutlinedIcon
                        className={styles.passwordVisibleIcon}
                        onClick={() => togglePasswordIcon()}
                        sx={{ color: "gray" }}
                      />
                    </HtmlTooltip>
                  ) : (
                    <HtmlTooltip title="Show">
                      <VisibilityOutlinedIcon
                        className={styles.passwordVisibleIcon}
                        onClick={() => togglePasswordIcon()}
                        sx={{ color: "gray" }}
                      />
                    </HtmlTooltip>
                  )
                }
                error={!!inputError.passwordError}
                helpertext={"Enter a valid password *"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextInput
                label="Confirm Password"
                type={toggleIcon.confirmPasswordIcon ? "text" : "password"}
                name="newPassword"
                onChange={handlePassword}
                value={password.newPassword}
                postcontent={
                  toggleIcon.confirmPasswordIcon ? (
                    <HtmlTooltip title="Hide">
                      <VisibilityOffOutlinedIcon
                        className={styles.passwordVisibleIcon}
                        onClick={() => toggleConfirmPasswordIcon()}
                        sx={{ color: "gray" }}
                      />
                    </HtmlTooltip>
                  ) : (
                    <HtmlTooltip title="Show">
                      <VisibilityOutlinedIcon
                        className={styles.passwordVisibleIcon}
                        onClick={() => toggleConfirmPasswordIcon()}
                        sx={{ color: "gray" }}
                      />
                    </HtmlTooltip>
                  )
                }
                error={!!inputError.confirmPasswordError || !!passwordMatch}
                helpertext={
                  inputError.confirmPasswordError && !passwordMatch
                    ? " Enter a valid password *"
                    : "Passwords don't match"
                }
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <div className={styles.formButtonWrapper}>
        <CustomButton
          onClick={savePassword}
          disabled={enableButton || !areAllValuesNull(inputFieldError)}
        >
          Save
        </CustomButton>
      </div>
    </div>
  );
}

Profile.propTypes = {
  handleTogglePasswordField: PropTypes.func.isRequired,
  handlePassword: PropTypes.func.isRequired,
  savePassword: PropTypes.func.isRequired,
  handleFormField: PropTypes.func.isRequired,
  togglePasswordIcon: PropTypes.func,
  storeUserDetails: PropTypes.object.isRequired,
  toggleConfirmPasswordIcon: PropTypes.func.isRequired,
  inputFieldError: PropTypes.object,
};
