import React, { useState, useRef } from 'react';
import Resetpassword from "./Reset-password";
import styles from "./Resetpassword.module.scss";
import { useResetPassword } from '../../utils/hooks';
import { toast } from "react-toastify";

export default function Resetpasswordwrapper() {
    const { modalOpen, handleResetPasswordRequest, setButtonDisabled } = useResetPassword();

    const oneDigit = useRef(0);
    const oneUppercase = useRef(0);
    const eightNumber = useRef(0);
    const oneSpecialCharacter = useRef(0);
    const fourteenNumber = useRef(0);
    const oneLowercase = useRef(0);


    // states for the input fields.
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    // for adding the error message for the input fields.
    const [inputError, setInputError] = useState({
        passwordError: false,
        confirmPasswordError: false,
    })

    // for adding the error message.
    const [errorMessage, setErrorMessage] = useState(false);

    const [toggleIcon, setToggleIcon] = useState({
        passwordIcon: false,
        confirmPasswordIcon: false
    });
    const [passwordMatch, setPasswordMatch] = useState({
        passwordMatchError: false,
        confirmPasswordMatchError: false
    });

    function hasANumber(string) {
        return Array.prototype.some.call(string, c => !isNaN(c) && c.trim())
    }
    function hasUppercaseCharacter(string) {
        return /[A-Z]/.test(string);
    }
    function hasLowercaseCharacter(string) {
        return /[a-z]/.test(string);
    }

    function hasEightNumber(string) {
        return (string.length >= 8)

    }

    function hasFourteenNumber(string) {
        return (string.length <= 16 && string !== "")

    }

    /**
     * Check if a string contains any special characters.
     * @param {string} string - The string to check for special characters.
     * @returns {boolean} - True if the string contains special characters, false otherwise.
     */
    function hasSpecialCharacter(string) {
        return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string)
    }

    const setIndicatorColor = (ref, condition) => {
      ref.current.style.color = condition ? "green" : "#95969D";
    };

    // Helper functions for validation
    const validatePassword = (value) => {
        const hasDigit = hasANumber(value);
        const hasUppercase = hasUppercaseCharacter(value);
        const hasEightDigits = hasEightNumber(value);
        const hasSpecialChar = hasSpecialCharacter(value);
        const hasFourteenDigits = hasFourteenNumber(value);
        const hasLowercase = hasLowercaseCharacter(value);

        setIndicatorColor(oneDigit, hasDigit);
        setIndicatorColor(oneUppercase, hasUppercase);
        setIndicatorColor(eightNumber, hasEightDigits);
        setIndicatorColor(oneSpecialCharacter, hasSpecialChar);
        setIndicatorColor(fourteenNumber, hasFourteenDigits);
        setIndicatorColor(oneLowercase, hasLowercase);

        return hasDigit && hasUppercase && hasEightDigits && hasSpecialChar && hasFourteenDigits && hasLowercase;
    };


    const confirmPasswordValidation = (value) => {
      if (
        value === formData.password &&
        value.length !== 0 &&
        validatePassword(value)
      ) {
        setButtonDisabled(false);
        setPasswordMatch({
          ...passwordMatch,
          passwordMatchError: false,
          confirmPasswordMatchError: false,
        });
      } else if (value === formData.password) {
        setPasswordMatch({
          ...passwordMatch,
          confirmPasswordMatchError: false,
        });
      } else {
        setButtonDisabled(true);
        setPasswordMatch({
          ...passwordMatch,
          confirmPasswordMatchError: formData.password !== "",
        });
      }
    };

    /**
     * Handles the input change event for the form fields and performs validation checks.
     * @param {Event} e - The input change event object.
     * @returns None
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedInputError = { ...inputError };

    
        // Password matching validation
        const validatePasswordMatch = () => {
            if (name === "confirmPassword") {
                confirmPasswordValidation(value)
            } else if (name === "password" && value !== formData.confirmPassword && formData.confirmPassword !== "") {
                setButtonDisabled(true);
                setPasswordMatch({
                    ...passwordMatch,
                    passwordMatchError: true
                });
            } else {
                if (name === "password" && value === "") {
                    setButtonDisabled(true)
                } else if (name === "password" && value === formData.confirmPassword) {
                    setButtonDisabled(false);
                }
                setPasswordMatch({
                    ...passwordMatch,
                    passwordMatchError: false,
                    confirmPasswordMatchError: false
                })
    
            }
        };
    
        // Validate password and password matching
        if (name === "password") {
            validatePassword(value);
            validatePasswordMatch();
        } else {
            validatePasswordMatch();
        }
    
        // Set error state
        setInputError(updatedInputError);
        // Set form data
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    
        // Reset general error message
        setErrorMessage(false);
    };

    // modal open function
    const handleEmailModal = () => {
        const resetPasswordPayload = {
            password: formData.confirmPassword
        }
        handleResetPasswordRequest(resetPasswordPayload);
    }

    // function for toggling the password show/hide.
    const togglePasswordIcon = () => {
        setToggleIcon({
            ...toggleIcon,
            passwordIcon: !toggleIcon.passwordIcon
        })
    }

    /**
     * Toggles the visibility of the confirm password icon by updating the state.
     * @returns None
     */
    const toggleConfirmPasswordIcon = () => {
        setToggleIcon({
            ...toggleIcon,
            confirmPasswordIcon: !toggleIcon.confirmPasswordIcon
        })
    }

    /**
     * Handles form submission when the Enter key is pressed. 
     * Validates the form data and displays an error message if necessary.
     * If the form data is valid, it triggers the handleEmailModal function.
     * @param {{Event}} event - The event object triggered by the key press.
     * @returns None
     */
    const handleResetFormSubmission = (event) => {
        if (event.key === "Enter") {
            if ((formData.confirmPassword === "") || (formData.password !== formData.confirmPassword) || (formData.password === "")) {
                toast.error("Please fill in all the validations");
            } else {
                handleEmailModal();
            }
        }
    }


    return (
        <div className={styles.componentWrapper}>
            <Resetpassword modalOpen={modalOpen} handleEmailModal={handleEmailModal} formData={formData} handleInputChange={handleInputChange} errorMessage={errorMessage} inputError={inputError} toggleIcon={toggleIcon} togglePasswordIcon={togglePasswordIcon} passwordMatch={passwordMatch} oneDigit={oneDigit} oneUppercase={oneUppercase} oneSpecialCharacter={oneSpecialCharacter} eightNumber={eightNumber} fourteenNumber={fourteenNumber} toggleConfirmPasswordIcon={toggleConfirmPasswordIcon} oneLowercase={oneLowercase} handleResetFormSubmission={handleResetFormSubmission} />
        </div>
    )
}
