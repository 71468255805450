import React from "react";
import PropTypes from "prop-types";
import styles from "./Error-message.module.scss";

export default function Commonerrormessage({ message, marginTop }) {
  return (
    <p className={styles.errorMessageStyles} style={{ marginTop: marginTop }}>
      {message}
    </p>
  );
}

Commonerrormessage.propTypes = {
  message: PropTypes.string.isRequired,
  marginTop: PropTypes.string,
};
