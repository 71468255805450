
import { clearData } from "../../../pages/Login/cookies";
import { toast } from "react-toastify";

export const TokenExpiry = (responseCode) => {
    if ((responseCode === 1004) || (responseCode === 1003) || (responseCode === 1005)) {
        toast.error("Session Expired!", { autoClose: 500 });
        setTimeout(() => {
            clearData();
        }, 1000);
    } else {
        return null
    }
}