import React from "react";
import styles from "./View-ticket.module.scss";
import { useDocumentListContext } from "../../../utils/hooks";
import CustomTable from "../../../components/CustomTable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import CustomButton from "../../../components/Button";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [5, -10],
            },
          },
        ],
      },
    }}
    placement="left"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#565E6DFF",
    color: "#fff",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: "10px",
    padding: "10px",
    boxShadow:
      " 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);",
    zIndex: "50",
  },
}));

const Reminderssent = React.memo(
  ({
    handleToggleButton,
    toggleContent,
    handleDocumentRequested,
    readOnly,
  }) => {
    const { useGetTicketById, storeTableData } = useDocumentListContext();

    const { id } = useParams();
    const { data } = useGetTicketById(id);

    /**
     * Parses the data by mapping over the idrTicketRemindersList and parsing the ticketIDRDocumentString
    
     */
    const parsedData = data?.idrTicketRemindersList?.map((item) => {
      const ticketIDRDocumentString = item.ticketIDRDocument;
      const parsedTicketIDRDocument =
        ticketIDRDocumentString !== "" && JSON.parse(ticketIDRDocumentString);

      return {
        ...item,
        ticketIDRDocument: parsedTicketIDRDocument,
      };
    });

    // extract the reminder date from the history_list array.
    const storeReminderDates = [];
    parsedData?.forEach((items) => {
      const formattedDate = moment(items.reminderDate).format("MM/DD/YYYY");
      const timestamp = moment(items.reminderDate).valueOf(); // Get the timestamp value
      storeReminderDates.push({
        formattedDate,
        timestamp,
      });
    });

    /**
     * Checks if all items in the storeTableData array have the idrDocumentReceived property set to true.
     * @returns {boolean} - true if all items have idrDocumentReceived set to true, false otherwise.
     */
    const checkForTrueVerification = () => {
      const keysWithTrueVerification = storeTableData?.every(
        (items) => items.idrDocumentReceived === true
      );
      return keysWithTrueVerification;
    };

    /**
     * An array of column configurations for a table component.
     * @type {Array}
     */
    const columns = [
      {
        name: "date",
        label: "Date",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            const reminderData = storeReminderDates[value]?.formattedDate;
            const timeStamp = storeReminderDates[value]?.timestamp;

            return (
              <>
                <p className={styles.tableContent}>{reminderData}</p>
                <span>({moment(timeStamp).format("h:mm:ss A")})</span>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              // color: '#4A4A4A',
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
              textAlign: "center",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#1D1D1D05",
              borderBottom: "0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 20,
              padding: "8px",
              color: "var(--primary-color)",
            },
          }),
        },
      },
      {
        name: "note",
        label: "Reminder",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            const deteremineLength = parsedData?.map(
              (items) => items.ticketIDRDocument.reminderList.length
            );

            const truncateText = (text, maxWords) => {
              const words = text.split(" ");
              if (words.length > maxWords) {
                return words.slice(0, maxWords).join(" ") + "...";
              }
              return text;
            };

            return (
              <ul
                className={styles.tableListWrapper}
                style={
                  deteremineLength > 3 ? { height: "100px" } : { height: "auto" }
                }
              >
                {parsedData?.map((items, i) => {
                  return items?.ticketIDRDocument?.reminderList?.map(
                    (docs, index) => {
                      if (i === value) {
                        const truncatedNotes = truncateText(
                          docs?.Notes || "No notes avaliable",
                          2
                        );
                        return (
                          <HtmlTooltip
                            key={`${items.idrTicketReminderID}-${docs.IDRTiecketDocumentId}`}
                            title={
                              <div className={styles.tooltipWrapper}>
                                <h6>
                                  Document Type:
                                  <span>{docs.IDRDocumentName}</span>
                                </h6>
                                <p>
                                  Notes: {docs?.Notes || "No notes available"}
                                </p>
                              </div>
                            }
                          >
                            <li>
                              <span>{index + 1}.</span>
                              <span>{truncatedNotes}</span>
                            </li>
                          </HtmlTooltip>
                        );
                      }
                    }
                  );
                })}
              </ul>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              // color: '#4A4A4A',
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
              textAlign: "center",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#1D1D1D05",
              borderBottom: "0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 20,
              padding: "8px",
              color: "var(--primary-color)",
            },
          }),
        },
      },
    ];
    return (
      <div className={styles.componentWrapper}>
        <div className={styles.tableWrapper}>
          <h4>Reminders</h4>
          <CustomTable
            data={storeReminderDates}
            columns={columns}
            noDataText="No Reminders Found"
          />
          <div
            className={styles.reminderButtonWrapper}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingInline: "10px",
            }}
          >
            <CustomButton
              disabled={checkForTrueVerification() || readOnly}
              sx={{ marginTop: "10px" }}
              onClick={handleDocumentRequested}
            >
              Send Reminder
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }
);
Reminderssent.propTypes = {
  handleToggleButton: PropTypes.func,
  toggleContent: PropTypes.bool,
};

export default Reminderssent;
