import React, { useEffect } from "react";
import styles from "./organization.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import CustomButton from "../../components/Button";
import Commonerrormessage from "../../components/Errormessage";
import { Loader } from "../../components/Loader";
import { useCustomerDetailsContext } from "../../utils/hooks/Auth/Organization";
import PropTypes from 'prop-types';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { changeFavIcon } from "../../utils/helper";

export default function Organization({ handleInputChange, formData, errorMessage, inputError, handleFormSubmission, loginButtonEnabled, handleCustomerCode }) {

    const { dataLoader } = useCustomerDetailsContext();
    const logoUrl = process.env.REACT_APP_CLIENT_LOGO_URL;

    useEffect(() => {
      changeFavIcon(logoUrl, 'alliantDIGITAL Dox')
      document.documentElement.style.setProperty(
        "--secondary-color",
        '#05aaff'
      );
    }, [])

    return (
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }} className={styles.organizationContainer}>
          <Grid container spacing={4}>
            <Grid xs={12} md={5}>
              <div className={styles.imgWrapper}>
                <img src={logoUrl} alt="Placeholder" />
              </div>
            </Grid>
            <Grid xs={12} md={7}>
              <div className={styles.formWrapper}>
                <h1>Welcome to alliantDIGITAL Dox</h1>
                <h4>
                  Continue to your account to manage your client information
                  requests.
                </h4>
                <form className={styles.customFormWrapper}>
                  <div className={styles.inputWrapper}>
                    <input
                      type="text"
                      required
                      placeholder="Enter Organization Code"
                      onChange={handleInputChange}
                      name="organizationName"
                      value={formData.organizationName}
                      onKeyDown={handleFormSubmission}
                    />
                    {inputError.organizationError && (
                      <p className={styles.inputFieldErrorMessage}>
                        Enter a valid organization name *
                      </p>
                    )}
                    <div className={styles.forgotPasswordWrapper}>
                      <CustomButton
                        onClick={() => {
                          handleCustomerCode();
                        }}
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "50px",
                        }}
                        disabled={loginButtonEnabled}
                      >
                        {dataLoader ? <Loader type="button" /> : "Continue"}
                        <ArrowCircleRightOutlinedIcon />
                      </CustomButton>
                    </div>
                  </div>
                </form>
                {errorMessage && (
                  <Commonerrormessage message="All fields are required *" />
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
}

Organization.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    errorMessage: PropTypes.bool,
    inputError: PropTypes.object.isRequired,
    handleFormSubmission: PropTypes.func.isRequired,
    loginButtonEnabled: PropTypes.bool.isRequired,
    handleCustomerCode: PropTypes.func.isRequired
};