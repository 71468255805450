import React, { useState, createContext, useContext } from "react";
import { useQuery } from "react-query";
import { getUserDetails } from "../../controllers/user-details";
import { TokenExpiry } from "../Auth/TokenExpiry";
import PropTypes from "prop-types";

const UserDetailsContext = createContext();

export const useUserDetailsContext = () => useContext(UserDetailsContext);

const useUserDetails = () => {
  const [storeUserDetails, setStoreUserDetails] = useState({});
  const [primaryColor, setPrimaryColor] = useState("");

  /**
   * Custom hook to fetch user details using the provided userId.
   * @param {string} userId - The ID of the user for which details are to be fetched.
   * @returns The result of the query to fetch user details.
   */
  const useGetUserDetails = (usedId) =>
    useQuery(["user-details", usedId], () => getUserDetails(usedId), {
      enabled: !!usedId,
      select: (data) => data.data,
      onSuccess: (data) => TokenExpiry(data?.ResponseCode),
      cacheTime: 0,
    });

  return {
    useGetUserDetails,
    storeUserDetails,
    setStoreUserDetails,
    primaryColor,
    setPrimaryColor,
  };
};

export function ProvideUserDetails({ children }) {
  const userDetailsState = useUserDetails();
  return (
    <UserDetailsContext.Provider value={userDetailsState}>
      {children}
    </UserDetailsContext.Provider>
  );
}
ProvideUserDetails.propTypes = {
  children: PropTypes.node.isRequired,
};
