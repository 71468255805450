import React from 'react'
import styles from "./renderfields.module.scss"
const RenderField = ({icon,iconColor,height,width,label,value,display}) => {
  return (
    <div className={styles.renderFieldContainer} style={{width:width}}>
        <span className={styles.labelHolder} >{label}</span>
        <div className={styles.renderFieldHolder} style={{border: display == false &&  "solid 0px" }}>{icon}{value}</div>
    </div>
  )
}

export default RenderField