import React, { useEffect } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { BasicModal } from "../../../components/Modal/Modal";
import PropTypes from "prop-types";

import styles from "../ViewUser/CreateUser.module.scss";
import CustomButton from "../../../components/Button";
import FormLayout from "../FormLayout";
import ClearIcon from "@mui/icons-material/Clear";
import { Loader } from "../../../components/Loader";
import { useUser } from "../../../utils/hooks";

export function ViewUser({ open, handleClose, maxwidth, fullScreen }) {
  const { useGetUserById, userId } = useUser();

  const { data: userData, isLoading: isUserDataLoading } =
    useGetUserById(userId);
  const { control, reset } = useForm({
    mode: "all",
  });

  /**
   * useEffect hook that resets form data when userId and userData are both truthy.
   * It resets the form data with the user data result and sets the role label and value
   * based on the user data result's roleName.
   * @returns None
   */
  useEffect(() => {
    if (userId && userData) {
      reset({
        ...userData?.result,
        role: {
          label: userData?.result?.roleName,
          value: userData?.result?.roleName,
        },
      });
    }
  }, [userData?.result]);

  return (
    <BasicModal
      open={open}
      close={handleClose}
      fullScreen={fullScreen}
      maxWidth={maxwidth}
    >
      <Box className={styles.viewUserContainer}>
        {isUserDataLoading ? (
          <Loader />
        ) : (
          <Box>
            <Box className={styles.header}>
              <Box className={styles.flex}>
                <Typography variant="h4" className={styles.headerText}>
                  View User
                </Typography>
              </Box>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Box>

            <Box>
              <Box>
                <form>
                  <Box className={styles.mainContainer}>
                    <Box className={styles.UserCard}>
                      <Typography variant="h6" className={styles.formHeader}>
                        User Details
                      </Typography>

                      <FormLayout control={control} view />

                      <Box>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                          wrap="nowrap"
                          className={styles.BottomButtons}
                        >
                          <CustomButton
                            variant="text"
                            size="medium"
                            onClick={handleClose}
                          >
                            Close
                          </CustomButton>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </BasicModal>
  );
}
ViewUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  maxwidth: PropTypes.string, 
  fullScreen: PropTypes.bool
};
