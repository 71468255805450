import React from 'react';
import ModuleWrapper from '../../components/ModuleWrapper';
import TrashImage from "../../assets/Images/trash.svg";
import ArchiveDashboard from "./Archive";
import styles from "./Archive.module.scss";

export default function Archive() {
    return (
        <ModuleWrapper moduleHeader='Archive' moduleImage={TrashImage}>
            <div className={styles.archiveDashboardWrapper}>
                <ArchiveDashboard />
            </div>
        </ModuleWrapper>
    )
}
