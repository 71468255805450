export function ThreeDots(props) {
  return (
    <svg
      width={4}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM2 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM2 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="#111111"
      />
      <path
        d="M2.25 9a.25.25 0 0 1-.25.25v1.5A1.75 1.75 0 0 0 3.75 9h-1.5ZM2 9.25A.25.25 0 0 1 1.75 9H.25c0 .966.784 1.75 1.75 1.75v-1.5ZM1.75 9A.25.25 0 0 1 2 8.75v-1.5A1.75 1.75 0 0 0 .25 9h1.5ZM2 8.75a.25.25 0 0 1 .25.25h1.5A1.75 1.75 0 0 0 2 7.25v1.5ZM2.25 16a.25.25 0 0 1-.25.25v1.5A1.75 1.75 0 0 0 3.75 16h-1.5Zm-.25.25a.25.25 0 0 1-.25-.25H.25c0 .966.784 1.75 1.75 1.75v-1.5ZM1.75 16a.25.25 0 0 1 .25-.25v-1.5A1.75 1.75 0 0 0 .25 16h1.5Zm.25-.25a.25.25 0 0 1 .25.25h1.5A1.75 1.75 0 0 0 2 14.25v1.5ZM2.25 2a.25.25 0 0 1-.25.25v1.5A1.75 1.75 0 0 0 3.75 2h-1.5ZM2 2.25A.25.25 0 0 1 1.75 2H.25c0 .966.784 1.75 1.75 1.75v-1.5ZM1.75 2A.25.25 0 0 1 2 1.75V.25A1.75 1.75 0 0 0 .25 2h1.5ZM2 1.75a.25.25 0 0 1 .25.25h1.5A1.75 1.75 0 0 0 2 .25v1.5Z"
        fill="#111111"
      />
    </svg>
  );
}
