import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CreateTicketModal.module.scss";
import { BasicModal } from "../../../components/Modal/Modal";
import { IconButton } from "@mui/material";
import CustomButton from "../../../components/Button";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";
import { TrashIcon } from "../../../assets/Icons/TrashIcon";

const AddQuestionModal = ({
  open,
  handleClose,
  setValue,
  addedQuestionList,
}) => {
  /**
   * Initializes the form with the specified mode and default values.
   * @param {{string}} mode - The mode of the form (e.g., "all").
   * @param {{object}} defaultValues - The initial values for the form fields.
   * @returns An object containing functions for handling form submission, controlling form fields, setting field values, resetting the form, and watching form field changes.
   */
  const { handleSubmit, control } = useForm({
    mode: "all",
    defaultValues: {
      requestQuestions: [
        {
          question: "",
        },
      ],
    },
  });

  /**
   * Destructures the 'fields' and 'remove' properties from the result of calling the useFieldArray hook
   * with the provided control and name parameters.
   * @param {{control}} control - The control object from the useForm hook.
   * @param {{string}} name - The name of the field array to be used.
   * @returns An object containing the 'documentList' and 'remove' properties.
   */
  const {
    fields: questionList,
    remove,
    append,
  } = useFieldArray({
    control,
    name: "requestQuestions",
  });

  const onSubmit = (data) => {
    const idrQuestions = data.requestQuestions.map((item) => ({
      question: item.question,
      answered: false,
    }));
    setValue("idrQuestions", [...addedQuestionList, ...idrQuestions]);
    handleClose();
  };

  return (
    <BasicModal open={open} close={handleClose} maxWidth={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.documentTypeModal}>
          <div className={styles.documentTypeModalHeader}>
            <p>Add Questions</p>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.questionsContainer}>
            {questionList.map((item, index) => (
              <div key={item.id} className={styles.individualQuestion}>
                <Controller
                  name={`requestQuestions[${index}].question`}
                  control={control}
                  rules={{
                    required: "Question is required",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      ref={field.ref}
                      placeholder="Enter Question"
                      type="text"
                      size="medium"
                      error={!!error}
                      helpertext={error?.message || null}
                    />
                  )}
                />
                {questionList.length > 1 && (
                  <IconButton onClick={() => remove(index)}>
                    <TrashIcon />
                  </IconButton>
                )}
              </div>
            ))}
            <CustomButton
              size="medium"
              onClick={() =>
                append({
                  question: "",
                })
              }
            >
              {" "}
              Add
            </CustomButton>
          </div>
          <div className={styles.buttonContainer}>
            <CustomButton
              variant="text"
              size="medium"
              onClick={handleClose}
              style={{ color: "#f00" }}
            >
              Cancel
            </CustomButton>
            <CustomButton type="submit" size="medium">
              Save
            </CustomButton>
          </div>
        </div>
      </form>
    </BasicModal>
  );
};

export default AddQuestionModal;
