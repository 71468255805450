import { postApi } from "../apis";

/**
 * Creates a reset request by sending a POST request to the specified API endpoint.
 */
export const createResetRequest = (data) => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer undefined', // Include the one-time token in the Authorization header
    }

    return postApi(`user/reset-request`, data, { headers });

} 