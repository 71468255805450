import { getApi, postApi, putApi } from "../apis";

//get ticket list
export const getTicketList = (
  mode,
  page,
  count,
  search,
  filterData,
  sortOrder
) =>
  postApi(
    `Ticket/all?mode=${mode}&page=${page}&count=${count}&search=${search}&searchColumn=${
      sortOrder.name === "ticketStatus" ? "status" : sortOrder.name
    }&sortType=${sortOrder.direction}`,
    filterData
  );

// view ticket
export const viewTicketDetails = (ticketId) => {
  return getApi(`Ticket?ticketId=${ticketId}`);
};

// create ticket
export const createTicket = (data) => {
  return postApi(`Ticket`, data);
};

// edit ticket
export const editTicket = (data) => {
  const { ticketID, ...post } = data;
  return putApi(`Ticket?ticketId=${ticketID}`, post);
};

// send reminder.
export const sendReminder = (ticketId) => {
  return postApi(`Ticket/reminder?ticketId=${ticketId}`);
};

//document update
export const ticketDocumentUpdate = (data) => {
  const { ticketId, ...post } = data;
  return putApi(`Ticket/document?TicketId=${ticketId}`, post);
};

//document save
export const ticketDocumentSave = (data) => {
  const { token, ...post } = data;
  const headers = {
    token,
    Authorization: "Bearer undefined", // Include the one-time token in the Authorization header
  };

  return postApi(`Ticket/client/docuement/save`, post, { headers });
};

//document list by token
export const documentRequestlist = (token) => {
  const headers = {
    token,
    Authorization: "Bearer undefined", // Include the one-time token in the Authorization header
  };
  return postApi("Ticket/client/document/getall", {}, { headers });
};

export const getTicketQuestions = (ticketId) =>
  getApi(`Ticket/Questions?ticketid=${ticketId}`);

export const updateTicketQuestions = (data) => {
  const { ticketId, ...post } = data;
  return postApi(`Ticket/Questions?TicketId=${ticketId}`, post);
};

export const updateQuestionAnswer = (data) => putApi(`Ticket/Answers`, data);

export const requestQuestionList = (token) => {
  const headers = {
    token,
    Authorization: "Bearer undefined", // Include the one-time token in the Authorization header
  };
  return getApi("Ticket/client/IDRQuestion", { headers });
};

export const getZippedDocument = (ticketId) =>
  getApi(`Ticket/zip?ticketId=${ticketId}`, {
    responseType: "arraybuffer",
  });

//document save
export const clientUpdateAnswer = (data) => {
  const { token, ...post } = data;
  const headers = {
    token,
    Authorization: "Bearer undefined", // Include the one-time token in the Authorization header
  };

  return putApi(`Ticket/ClientAnswers`, post, { headers });
};
