import { postApi } from "../apis";

/**
 * Creates a new user login by sending a POST request to the 'user/login' endpoint with the provided data.
 */
export const createNewUserLogin = (data) =>
  postApi(`user/login`, data, {
    headers: { Authorization: "Bearer undefined" },
  });

/**
 * post request for user otp login
 */
export const userOtpLogin = (data) =>
  postApi(`user/login/otp`, data, {
    headers: { Authorization: "Bearer undefined" },
  });

/**
 * post request for resend otp
 */
export const resendOtp = (data) =>
  postApi(`user/Resend/otp`, data, {
    headers: { Authorization: "Bearer undefined" },
  });
