import React from "react";
import { BasicModal } from "../../components/Modal/Modal";
import styles from "./document-requested.module.scss";
import { GreenTickIcon } from "../../assets/Icons/GreenTickIcon";
import { RedCrossIcon } from "../../assets/Icons/RedCrossIcon";
import PropTypes from "prop-types";

const DocumentRequestModal = ({ open, type, title, message }) => {
  return (
    <BasicModal open={open}>
      <div className={styles.modalContainer}>
        {type === "success" ? <GreenTickIcon /> : <RedCrossIcon />}
        <div className={styles.content}>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalMessage}>{message}</div>
        </div>
      </div>
    </BasicModal>
  );
};

DocumentRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default DocumentRequestModal;
