import React from "react";
import Routes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextContainer from "../src/context";
import {
  BrowserRouter as Router,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <ContextContainer>
        <div className="App">
          <Routes />
          <ToastContainer position="top-center" />
        </div>
      </ContextContainer>
    </Router>
  );
}

export default App;
