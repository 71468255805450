import React, { useContext, createContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { userBulkUpload } from "../../controllers/user";
import { toast } from "react-toastify";


const UserBulkUploadContext = createContext();
export const useUserBulkUpload = () => useContext(UserBulkUploadContext);

function ProvideUserBulkUpload() {
    const [bulkUploadData, setBulkUploadData] = useState({
        showBulkUploadModal: false,
        storeCsvFile: null
    });

    const queryClient = useQueryClient();

    const { mutate: mutateCreateUserBulkUpload, isLoading: isCreateClientLoading } =
        useMutation(userBulkUpload, {
            onSuccess: (data) => {
                const status = data.data;
                if (status.responseCode === 3010 && status.result === "Successfully Added.") {
                    toast.success("All clients added successfully");
                    setBulkUploadData((prevState) => ({
                        ...prevState,
                        showBulkUploadModal: false,
                        storeCsvFile: null,
                    }));
                    queryClient.refetchQueries("userList");
                } else if ((status.responseCode === 3018) || (status.responseCode === 422)) {
                    toast.error(status.errors[0]);
                } else {
                    setBulkUploadData((prevState) => ({
                        ...prevState,
                        showBulkUploadModal: true,
                        storeCsvFile: status.result,
                    }));
                    queryClient.refetchQueries("userList");
                }
            },
            onError: (err) => {
                console.log(err);
            },
        });


    return {
        mutateCreateUserBulkUpload,
        bulkUploadData,
        setBulkUploadData
    }


}

export function ProviderUserBulkUpload({ children }) {
    const UserBulkUploadData = ProvideUserBulkUpload();
    return (
        <UserBulkUploadContext.Provider value={UserBulkUploadData}>
            {children}
        </UserBulkUploadContext.Provider>
    );
}
