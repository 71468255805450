import React from "react";
import styles from "./DeleteModal.module.scss";
import { BasicModal } from "../../components/Modal/Modal";
import CustomButton from "../../components/Button";
import PropTypes from "prop-types";

const DeleteModal = ({
  open,
  handleCancel,
  handleDeleteClick,
  deleteText = "Delete",
  cancelText = "Cancel",
  primaryText,
  secondaryText,
  isButtonDisabled,
}) => {
  return (
    <BasicModal open={open} close={handleCancel}>
      <div className={styles.deleteModalContainer}>
        <div>
          <p className={styles.primaryText}>{primaryText}</p>
          <p className={styles.secondaryText}>{secondaryText}</p>
        </div>
        <div className={styles.deleteModalButtons}>
          <CustomButton
            onClick={handleCancel}
            variant="text"
            style={{ color: "#f00" }}
          >
            {cancelText}
          </CustomButton>
          <CustomButton onClick={handleDeleteClick} disabled={isButtonDisabled}>
            {deleteText}
          </CustomButton>
        </div>
      </div>
    </BasicModal>
  );
};
DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  cancelText: PropTypes.string,
  deleteText: PropTypes.string,
  isButtonDisabled: PropTypes.bool,
};

export default DeleteModal;
