import React, { useState, createContext, useContext } from 'react';
import { createResetPasswordRequest } from "../../controllers/reset-password";
import { useMutation } from 'react-query';
import PropTypes from "prop-types";

const createResetPasswordContext = createContext();

export const useResetPassword = () => useContext(createResetPasswordContext);

const useHandleResetPasswordData = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [errorResponseCode, setErrorResponseCode] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);


    // use use mutation for post and put/edit api.
    const { mutate: mutateResetPasswordRequest, isLoading: resetPasswordLoader } =
        useMutation(createResetPasswordRequest, {
            onSuccess: (data) => {
                setModalOpen(true)
                if (data && (data.data.responseCode === 1005 || data.data.responseCode === 1001)) {
                    setErrorResponseCode(true)
                } else {
                    setErrorResponseCode(false)
                }
            },
            onError: (err) => {
                console.log(err);
            },
        });

    /**
     * Handles the reset password request by calling the mutateResetPasswordRequest function with the provided resetPasswordData.
     */
    const handleResetPasswordRequest = (resetPasswordData) => {
        mutateResetPasswordRequest(resetPasswordData)
    }

    return {
        modalOpen,
        handleResetPasswordRequest,
        resetPasswordLoader,
        errorResponseCode,
        buttonDisabled,
        setButtonDisabled
    }
}



export function ProvideResetPassword({ children }) {
    const resetPasswordData = useHandleResetPasswordData();
    return <createResetPasswordContext.Provider value={resetPasswordData}>{children}</createResetPasswordContext.Provider>
}
ProvideResetPassword.propTypes = {
    children: PropTypes.node.isRequired,
};