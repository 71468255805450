import { Box } from "@mui/material";
import React from "react";

import ModuleWrapper from "../../components/ModuleWrapper";
import ClientManagementTable from "./ClientManagement";
import { useClient } from "../../utils/hooks";
import { ViewClient } from "./ViewClient";
import building from "../../assets/Images/dashboard-images/building.svg";


const ClientManagement = () => {
  const { viewDetails, setViewDetails, setClientId } = useClient();
  function handleClose() {
    setViewDetails(false)
    setClientId('')
  }


  return (
    <Box>
      <ModuleWrapper moduleHeader="Manage Clients" moduleImage={building}>
        <ClientManagementTable />
      </ModuleWrapper>

      {viewDetails && (
        <ViewClient
          open={viewDetails}
          handleClose={handleClose}
          maxwidth={false}
        />
      )}
    </Box>
  );
};

export default ClientManagement;
