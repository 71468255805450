import axios from "axios";
import { getStorageValue } from "../pages/Login/cookies";

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

const accessLoginToken = getStorageValue("access_token");

axios.defaults.headers.common.Authorization = `Bearer ${accessLoginToken}`;

/**
 * Gets the API data from the server.
 * @param url - the url to get the data from.
 */
export const getApi = (url,config) => axios.get(API_SERVER_URL + url,config);

/**
 * Posts data to the API server.
 * @param url - the url to post to.
 * @param data - the data to post.
 * @param shortUrl - whether or not to use the short url.
 */
export const postApi = (url, data, config, shortUrl = true) =>
  axios.post((shortUrl ? API_SERVER_URL : "") + url, data, config);

/**
 * Sends a PUT request to the API server.
 * @param url - the url to upadte.
 * @param data - the data to update.
 * @param shortUrl - whether or not to use the short url.
 */
export const putApi = (url, data, config, shortUrl = true) =>
  axios.put((shortUrl ? API_SERVER_URL : "") + url, data, config);

/**
 * Deletes the given url from the API server.
 * @param url - the url to delete from the API server.
 */
export const deleteApi = (url) => axios.delete(API_SERVER_URL + url);
