import React, { useEffect } from "react";
import { useAuth } from "../../utils/hooks";
import { useForm } from "react-hook-form";
import OtpLoginContainer from "./OtpLogin";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getStorageValue } from "../Login/cookies";

const OtpLogin = () => {
  const navigate = useNavigate();
  // const [isResendDisabled, setIsResendDisabled] = useState(true);
  // const [timer, setTimer] = useState(60);
  const accessLoginToken = getStorageValue("access_token");
  const {
    isUserOtpLoginLoading,
    mutateUserOtpLogin,
    // mutateResendOtp,
    organizationCode,
    isResendOtpLoading,
  } = useAuth();
  const location = useLocation();
  const qrCode = location?.state?.qrCode

  const [qParams] = useSearchParams();
  const token = qParams.get("token");

  const { control, handleSubmit, watch, setValue } = useForm();
  const digits = watch("digits") || ["", "", "", "", "", ""];

  const onSubmit = (data) => {
    const finalData = {
      token,
      otp: data.digits.join(""),
    };
    mutateUserOtpLogin(finalData);
  };

  useEffect(() => {
    if (organizationCode) {
      if (accessLoginToken) {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, [accessLoginToken, organizationCode]);

  useEffect(() => {
    if (digits.every((digit) => digit && digit !== "")) {
      handleSubmit(onSubmit)();
    }
  }, [digits, handleSubmit]);

  // useEffect(() => {
  //   let interval;
  //   if (isResendDisabled) {
  //     interval = setInterval(() => {
  //       setTimer((prevTimer) => {
  //         if (prevTimer <= 1) {
  //           clearInterval(interval);
  //           setIsResendDisabled(false);
  //           return 60;
  //         }
  //         return prevTimer - 1;
  //       });
  //     }, 1000);
  //   }
  //   return () => clearInterval(interval);
  // }, [isResendDisabled]);

  const handleChange = (event, index) => {
    const value = event.target.value;
    if (/^\d?$/.test(value)) {
      const newDigits = [...digits];
      newDigits[index] = value;
      setValue("digits", newDigits);

      // Move focus to the next input box if not the last box
      if (value && index < 5) {
        document.getElementById(`digit-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !digits[index]) {
      if (index > 0) {
        const newDigits = [...digits];
        newDigits[index - 1] = "";
        setValue("digits", newDigits);
        document.getElementById(`digit-${index - 1}`).focus();
      }
    }
  };

  // const handleResendOTP = () => {
  //   mutateResendOtp({ token });
  //   setIsResendDisabled(true);
  // };

  return (
    <form>
      <OtpLoginContainer
        // isResendDisabled={isResendDisabled}
        control={control}
        // handleResendOTP={handleResendOTP}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        // timer={timer}
        isApiLoading={isUserOtpLoginLoading || isResendOtpLoading}
        qrCode={qrCode}
      />
    </form>
  );
};

export default OtpLogin;
