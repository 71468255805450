import React from "react";
import { Divider, Popover } from "@mui/material";
import PropTypes from "prop-types";
import "./ThreeDotMenu.scss";

const ThreeDotMenu = ({
  open,
  onClose,
  anchorEl,
  id,
  menuItem,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
}) => {
  const filteredMenuItem = menuItem.filter((menu) => !menu.hide);
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      className="menuPopover"
    >
      <div onClick={onClose} onKeyDown={() => {}} className="menuContainer">
        {filteredMenuItem.map((menu, index) => (
          <div key={menu.name}>
            <div
              className="menuItem"
              onKeyDown={() => {}}
              onClick={menu.onClickFunction}
            >
              {menu.icon}
              <p>{menu.name}</p>
            </div>
            {menuItem?.length - 1 !== index && <Divider />}
          </div>
        ))}
      </div>
    </Popover>
  );
};

ThreeDotMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  anchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  id: PropTypes.string,
  menuItem: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClickFunction: PropTypes.func.isRequired,
      icon: PropTypes.element.isRequired,
      hide: PropTypes.bool,
    })
  ).isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf([
      "top",
      "center",
      "bottom",
      "bottom-end",
      "bottom-start",
      "top-end",
      "top-start",
    ]),
    horizontal: PropTypes.oneOf([
      "left",
      "center",
      "right",
      "left-end",
      "right-end",
      "left-start",
      "right-start",
    ]),
  }),
};

export default ThreeDotMenu;
