import React from "react";
import upload from "../../assets/Images/imgupload.svg";
import CustomButton from "../../components/Button";
import styles from "./Settings.module.scss";
import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { TextInput } from "../../components/InputBox";

const Settings = ({ colorRef, fileRef, control, watch }) => {
  const image = watch("logo");

  const imageUrl =
    typeof image === "object" ? URL.createObjectURL(image) : image;

  return (
    <div>
      <div className={styles.settingTitle}>
        <p>Company Details</p>
      </div>
      <Grid container spacing={3}>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6} md={5} lg={4}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Company name is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  label="Company Name"
                  type="text"
                  size="medium"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6} md={5} lg={4}>
            <Controller
              name="logo"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div className={styles.logoInputContainer}>
                  <span className={styles.commonLabel}>Company Logo</span>
                  <div
                    className={styles.imgInputContainer}
                    onKeyDown={() => {}}
                    onClick={() => fileRef.current.click()}
                  >
                    <input
                      {...field}
                      type="file"
                      contentEditable={false}
                      accept="image/png, image/jpeg, image/gif"
                      value=""
                      ref={fileRef}
                      className={styles.fileInput}
                      onChange={(e) => field.onChange(e.target.files[0])}
                    />
                    <p className={styles.fileInputLabel}>
                      Upload / Drag and Drop
                    </p>
                    <img src={upload} alt="" className={styles.uploadImg} />
                  </div>
                  <span className={styles.fileInfoLabel}>
                    Supports JPG, PNG & GIF (200px x 200px min dimensions)
                  </span>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6} md={5} lg={4}>
            <Controller
              name="color"
              control={control}
              rules={{
                required: "Button color is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <div className={styles.colorInputContainer}>
                  <span className={styles.commonLabel}>Button Color</span>
                  <div
                    className={styles.colorValueHolder}
                    onKeyDown={() => {}}
                    onClick={() => colorRef.current.click()}
                  >
                    <p>{field.value}</p>
                    <input
                      {...field}
                      className={styles.colorInput}
                      type="color"
                      ref={colorRef}
                    />
                  </div>
                </div>
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <div className={styles.yourImage}>
        {imageUrl && (
          <img src={imageUrl} alt="" className={styles.settingsImgHolder} />
        )}
      </div>

      <div className={styles.settingsButtonContainer}>
        {/* <CustomButton variant="text" size="medium" style={{ color: "#f00" }}>
          Cancel
        </CustomButton> */}
        <CustomButton type="submit" style={{ width: "100px" }}>
          Save
        </CustomButton>
      </div>
    </div>
  );
};

export default Settings;
