import React, { useState, useEffect } from "react";
import Profile from "./Profile";
import ModuleWrapper from "../../components/ModuleWrapper";
import { useProfileContext } from "../../utils/hooks/Profile";
import { useUserDetailsContext } from "../../utils/hooks/UserDetails";
import { Loader } from "../../components/Loader";
import { useAuth } from "../../utils/hooks";
import { pattern } from "../../utils/validationPattern";
import profileImage from "../../assets/Images/profile-circle.svg";

export default function Userprofile() {
  const { user } = useAuth();
  const {
    password,
    setPassword,
    showPasswordField,
    setShowPasswordField,
    formField,
    setFormField,
    inputError,
    setInputError,
    setPasswordMatch,
    toggleIcon,
    setToggleIcon,
    handleUserProfileData,
    setPasswordRequired,
    setEnableButton,
  } = useProfileContext();

  const [storeUserDetails, setStoreUserDetails] = useState({});

  const [inputFieldError, setInputFieldError] = useState({
    firstNameError: null,
    lastNameError: null,
    phoneNumberError: null,
  });

  const { useGetUserDetails } = useUserDetailsContext();

  const {
    data,
    isSuccess,
    isLoading: profileLoader,
  } = useGetUserDetails(user?.UserID);

  /**
   * useEffect hook that triggers when isSuccess is true.
   * If isSuccess is true, it sets the storeUserDetails state to the result from the data object.
   * It then sets the enableButton state to true.
   * Additionally, it returns a cleanup function that resets various states and flags.
   * @param {{boolean}} isSuccess - Flag indicating if the operation was successful.
   * @param {{object}} data - The data object containing the result.
   * @param {{object}} storeUserDetails - The user details stored in the state.
   * @returns None
   */
  useEffect(() => {
    if (isSuccess) {
      setStoreUserDetails(data?.result);
    }
    setEnableButton(true);
    return () => {
      setShowPasswordField(false);
      setInputError((prevData) => ({
        ...prevData,
        passwordError: false,
        confirmPasswordError: false,
      }));
      setPassword((prevData) => ({
        ...prevData,
        currentPassword: "",
        newPassword: "",
      }));
    };
  }, [data, storeUserDetails]);

  /**
   * Handles the toggle functionality of the password field.
   * Toggles the visibility of the password field.
   * Resets input errors for password and confirm password fields.
   * Resets the current password and new password fields.
   * @returns None
   */
  const handleTogglePasswordField = () => {
    setShowPasswordField(!showPasswordField);
    setInputError((prevData) => ({
      ...prevData,
      passwordError: false,
      confirmPasswordError: false,
    }));
    setPassword((prevData) => ({
      ...prevData,
      currentPassword: "",
      newPassword: "",
    }));
  };

  /**
   * useEffect hook that toggles the password requirement based on the value of showPasswordField.
   * If showPasswordField is true, sets passwordRequired to false, otherwise sets it to true.
   * @param {Function} callback - The function to execute when the dependency array changes.
   * @param {Array} dependencies - An array of values that the effect depends on.
   * @returns None
   */
  useEffect(() => {
    if (showPasswordField) {
      setPasswordRequired(false);
    } else {
      setPasswordRequired(true);
    }
  }, [showPasswordField]);

  const validateInput = (errorType, errorMessage) => {
    setInputFieldError((prevData) => ({
      ...prevData,
      [errorType]: errorMessage,
    }));
    setEnableButton(true);
  };

  const clearError = (errorType) => {
    setInputFieldError((prevData) => ({
      ...prevData,
      [errorType]: null,
    }));
  };

  const handleInputChange = (name, value) => {
    setFormField((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setEnableButton(value === "");
  };

  const fNameValidation = (name, value) => {
    if (value === "") {
      validateInput("firstNameError", "First name is required");
    } else if (!pattern.name.test(value)) {
      validateInput("firstNameError", "Enter a valid name");
    } else if (value.length < 3) {
      validateInput(
        "firstNameError",
        "First name must contain min 3 characters"
      );
    } else if (value.length > 50) {
      validateInput("firstNameError", "First name cannot exceed 50 characters");
    } else if (!pattern.noSpace.test(value)) {
      validateInput("firstNameError", "No space allowed");
    } else {
      clearError("firstNameError");
      handleInputChange(name, value);
    }
  };

  const lNameValidation = (name, value) => {
    if (value === "") {
      validateInput("lastNameError", "Last name is required");
    } else if (!pattern.name.test(value)) {
      validateInput("lastNameError", "Enter a valid name");
    } else if (value.length > 50) {
      validateInput("lastNameError", "Last name cannot exceed 50 characters");
    } else if (!pattern.noSpace.test(value)) {
      validateInput("lastNameError", "No space allowed");
    } else {
      clearError("lastNameError");
      handleInputChange(name, value);
    }
  };

  const contactValidation = (name, value) => {
    if (value === "") {
      validateInput("phoneNumberError", "Phone number is required");
    } else if (!pattern.phoneRegex.test(value)) {
      validateInput("phoneNumberError", "Enter a valid phone number");
    } else if (!pattern.noSpace.test(value)) {
      validateInput("phoneNumberError", "No space allowed");
    } else {
      clearError("phoneNumberError");
      handleInputChange(name, value);
    }
  };

  /**
   * Handles form field validation based on the input event.
   * @param {Event} e - The input event triggering the validation.
   * @returns None
   */
  const handleFormField = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "fName":
        fNameValidation(name, value);
        break;
      case "lName":
        lNameValidation(name, value);
        break;
      case "contact":
        contactValidation(name, value);
        break;
      default:
        break;
    }
  };

  /**
   * Handles the input change event for password fields, validates the password based on regex pattern,
   * and updates the error state and password state accordingly.
   * @param {Event} e - The event object containing information about the input change.
   * @returns None
   */
  const handlePassword = (e) => {
    const { name, value } = e.target;
    let errorState = { ...inputError };

    // Validation rules
    if (name === "currentPassword") {
      errorState.passwordError =
        value !== "" ? !pattern.password.test(value) : false;
      setPasswordRequired(false);
      setEnableButton(true);
      setPasswordMatch(false);
    } else if (name === "newPassword") {
      errorState.confirmPasswordError =
        value !== "" ? !pattern.password.test(value) : false;
      setPasswordMatch(false);
      setPasswordRequired(false);
      setEnableButton(true);
      if (password.currentPassword !== value) {
        setPasswordMatch(true);
        setEnableButton(true);
        setPasswordRequired(false);
      }
      if (password.currentPassword === value && pattern.password.test(value)) {
        setEnableButton(false);
      }
    }

    // Set error state
    setInputError(errorState);

    setPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  /**
   * Toggles the visibility of the password icon by updating the state.
   * @returns None
   */
  const togglePasswordIcon = () => {
    setToggleIcon({
      ...toggleIcon,
      passwordIcon: !toggleIcon.passwordIcon,
    });
  };

  /**
   * Toggles the visibility of the confirm password icon by updating the state.
   * @returns None
   */
  const toggleConfirmPasswordIcon = () => {
    setToggleIcon({
      ...toggleIcon,
      confirmPasswordIcon: !toggleIcon.confirmPasswordIcon,
    });
  };

  /**
   * Saves the user's password and updates the user profile data accordingly.
   * If the password fields are empty or do not match, appropriate error flags are set.
   * @returns None
   */
  const savePassword = () => {
    const getLoginFormDetails = JSON.parse(
      localStorage.getItem("saveFormData")
    );

    // Check if both current and new passwords are provided
    if (
      showPasswordField &&
      (password.currentPassword === "" || password.newPassword === "")
    ) {
      setPasswordRequired(true);
    } else if (
      showPasswordField &&
      password.currentPassword !== password.newPassword
    ) {
      setPasswordMatch(true);
    } else {
      // Proceed with sending the form data as the paylaod.
      const requestPayload = {
        roles: [],
        fName: formField.fName || storeUserDetails.fName,
        lName: formField.lName || storeUserDetails.lName,
        contact: formField.contact || storeUserDetails.contact,
        isActive: true,
        password: password?.newPassword || getLoginFormDetails?.password,
      };
      //   if (showPasswordField === false) {
      //     delete requestPayload.password;
      //   }
      handleUserProfileData(requestPayload, user?.UserID);
    }
  };

  return (
    <ModuleWrapper moduleHeader="My Profile" moduleImage={profileImage}>
      {profileLoader ? (
        <Loader />
      ) : (
        <Profile
          handleTogglePasswordField={handleTogglePasswordField}
          handlePassword={handlePassword}
          savePassword={savePassword}
          handleFormField={handleFormField}
          togglePasswordIcon={togglePasswordIcon}
          toggleIcon={toggleIcon}
          storeUserDetails={data?.result}
          toggleConfirmPasswordIcon={toggleConfirmPasswordIcon}
          inputFieldError={inputFieldError}
        />
      )}
    </ModuleWrapper>
  );
}
