import React from "react";
import PropTypes from "prop-types";
import CustomTable from "../../../components/CustomTable";
import { Controller } from "react-hook-form";
import { IconButton } from "@mui/material";
import MultiDocumentUpload from "./MultiDocumentUpload";
import styles from "./CreateTicketModal.module.scss";
import { useAuth, useTicket } from "../../../utils/hooks";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { HtmlTooltip } from "../../../components/Tooltip";
import { TrashIcon } from "../../../assets/Icons/TrashIcon";
import FileIcon from "../../../assets/Icons/FileIcon";
import MessageIcon from "../../../assets/Icons/MessageIcon";
import { TextInput } from "../../../components/InputBox";
import DeleteModal from "../../../components/DeleteModal";

const DocumentRequested = ({
  documentList,
  questionList,
  control,
  remove,
  removeQuestion,
  setValue,
  disabled,
}) => {
  const {
    deleteDocumentData,
    setDeleteDocumentData,
    openDeleteModal,
    setOpenDeleteModal,
    mutateDeleteTicketDocument,
    isDeleteTicketDocumentLoading,
    deleteQuestion,
    setDeleteQuestion,
    mutateUpdateQuestionAnswers,
  } = useTicket();
  const { id } = useParams();

  const { organizationCode } = useAuth();

  const combinedList = [...documentList, ...questionList];

  const setHeaderProp = () => {
    return {
      style: {
        background: "#efefef",
        borderBottom: "0",
        textAlign: "left",
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: "19.5px",
        height: "40px",
      },
    };
  };

  const columns = [
    {
      name: "documentName",
      label: "Document Type",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div className={styles.documentType}>
            <div className={styles.documentTypeIcon}>
              {combinedList[rowIndex].documentName ? (
                <FileIcon />
              ) : (
                <MessageIcon />
              )}
            </div>
            <p>
              {combinedList[rowIndex].documentName ||
                combinedList[rowIndex].question}
            </p>
          </div>
        ),
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "documents",
      label: "Upload Document",
      options: {
        setCellHeaderProps: setHeaderProp,
        customBodyRenderLite: (value, index) => {
          const receivedElsewhere = documentList[index]?.elseWhere;
          return (
            <div className={styles.documentUpload}>
              {combinedList[index].documentName ? (
                <Controller
                  name={`ticketIDRDocuments[${index}].documents`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MultiDocumentUpload
                      isMutli
                      onChange={(e) => {
                        onChange(e);
                        if (e.length === 0) {
                          setValue(
                            `ticketIDRDocuments[${index}].received`,
                            false
                          );
                        }
                      }}
                      uploadedFile={value}
                      disabled={disabled || receivedElsewhere}
                      organizationCode={organizationCode}
                    />
                  )}
                />
              ) : (
                <div className={styles.textArea}>
                  <Controller
                    name={`idrQuestions[${index - documentList.length
                      }].answers`}
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        placeholder="Enter response"
                        multiline
                        minRows={1}
                        maxRows={4}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        setCellHeaderProps: setHeaderProp,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const rowData = combinedList[rowIndex];
          const onDeleteClick = () => {
            if (rowData.question) {
              if (rowData.questionID) {
                setDeleteQuestion({ ...rowData, rowIndex });
                setOpenDeleteModal(true);
              } else {
                removeQuestion(rowIndex - documentList.length);
              }
            }
            if (rowData.ticketIDRDocumentID) {
              setDeleteDocumentData({ ...rowData, rowIndex });
              setOpenDeleteModal(true);
            } else {
              remove(rowIndex);
            }
          };
          const isButtonDisabled =
            disabled ||
            rowData?.received ||
            (documentList?.length === 1 &&
              combinedList[rowIndex].documentName) ||
            combinedList[rowIndex].answered;
          return (
            <div className={styles.actionContainer}>
              <HtmlTooltip title="Delete">
                <IconButton disabled={isButtonDisabled} onClick={onDeleteClick}>
                  <TrashIcon />
                </IconButton>
              </HtmlTooltip>
            </div>
          );
        },
      },
    },
  ];

  /**
   * Handles the deletion of a ticket document by setting isActive to false and status to "Deleted".
   * @param {{Object}} data - The data object containing information about the ticket document.
   * @returns None
   */
  const handleDelete = async (data) => {
    const deleteData = {
      ticketId: id,
      list: [
        {
          ticketDocumentID: data.ticketIDRDocumentID,
          isActive: false,
          status: "Deleted",
        },
      ],
    };
    try {
      await mutateDeleteTicketDocument(deleteData);
      remove(data.rowIndex);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteQuestion = async (data) => {
    const deleteData = {
      answers: [
        {
          questionID: data.questionID,
          question: data.question,
          ticketID: id,
          answered: data.answered,
          isActive: false,
        },
      ],
    };
    try {
      await mutateUpdateQuestionAnswers(deleteData);
      removeQuestion(data.rowIndex - documentList.length);
    } catch (err) {
      console.log(err);
    }
  };

  if (isDeleteTicketDocumentLoading) {
    return <Loader />;
  }

  return (
    <div>
      {combinedList?.length > 0 && (
        <CustomTable
          key={combinedList}
          data={combinedList || []}
          columns={columns}
        />
      )}
      {openDeleteModal && deleteDocumentData && (
        <DeleteModal
          open={openDeleteModal}
          handleCancel={() => setOpenDeleteModal(false)}
          primaryText={`Are you sure want to delete this document type?-"${deleteDocumentData.documentName}"`}
          handleDeleteClick={() => handleDelete(deleteDocumentData)}
        />
      )}
      {openDeleteModal && deleteQuestion && (
        <DeleteModal
          open={openDeleteModal}
          handleCancel={() => setOpenDeleteModal(false)}
          primaryText={`Are you sure want to delete this question?`}
          handleDeleteClick={() => handleDeleteQuestion(deleteQuestion)}
        />
      )}
    </div>
  );
};

DocumentRequested.propTypes = {
  documentList: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  organizationCode: PropTypes.string,
};

export default DocumentRequested;
