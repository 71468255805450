import React, { useEffect } from 'react';
import styles from "./groups.module.scss";
import { Typography } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import plusIcon from "../../../assets/Images/add-circle.svg";
import { useNavigate } from 'react-router-dom';
import { UseGroupContext } from '../../../utils/hooks/Group';

export default function GroupDetails() {

    const { showBtn, setShowBtn, isCreateGroupNameLoading, mutateStoreInputValue, useGetNoGroupNames } = UseGroupContext();

    const { data } = useGetNoGroupNames();


    useEffect(() => {
        setShowBtn((prevState) => ({
            ...prevState,
            storeClientData: data?.results
        }))

    }, [data, showBtn.storeClientData])


    const navigate = useNavigate();

    const { register, handleSubmit, control, watch } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: "groupClients", // name for your Field Array
    });

    const handleGroupNameInput = (e) => {
        const value = e.target.value;
        setShowBtn((prevState) => ({
            ...prevState,
            storeInputValue: value
        }))

        if (value !== "") {
            setShowBtn((prevState) => ({
                ...prevState,
                formSubmitBtn: false
            }))
        } else {
            setShowBtn((prevState) => ({
                ...prevState,
                formSubmitBtn: true
            }))
        }
    }

    const handleDefaultSelectValue = (e) => {
        const value = e.target.value;
        setShowBtn((prevState) => ({
            ...prevState,
            storeDynamicSelectValues: [...showBtn.storeDynamicSelectValues, {
                clientID: parseInt(value)
            }]
        }))
    }
    const handleDynamicSelectValue = (e) => {
        const value = e.target.value;
        setShowBtn((prevState) => ({
            ...prevState,
            storeDynamicSelectValues: [...showBtn.storeDynamicSelectValues, {
                clientID: parseInt(value)
            }]
        }))
    }

    /**
     * the on submit function to handle the form submission.
     * @returns
     */
    const onSubmit = data => {

        const requestBody = {
            groupName: showBtn.storeInputValue
        }

        mutateStoreInputValue(requestBody)
    };

    /**
     * this function is used to handle button visbility based on the state value
     * @returns
     */
    const handleDefaultBtn = () => {
        append({ groupId: '' });
        setShowBtn((prevState) => ({
            ...prevState,
            addClientBtn: fields.length === 0 && false
        }))
    };

    const handleDynamicSelectValues = (field) => {
        append({ groupId: '' });

    }


    return (
        <section className={styles.groupContainerWrapper}>
            <Typography variant='h6'>Group Details</Typography>
            <div className={styles.inputWrapper}>
                <label>Group Name</label>
                <input type='text' placeholder='Enter group name' onChange={handleGroupNameInput} />
            </div>
            <div className={styles.groupClientsWrapper}>
                <Typography variant='h6'>Group Clients</Typography>
                <label>Client Name</label>
                {/* Render one select field by default */}
                <div className={styles.defaultSelectBoxWrapper}>
                    <select onChange={handleDefaultSelectValue}>
                        <option value=""> - Please Select - </option>
                        {showBtn.storeClientData && showBtn.storeClientData.map((clients) => {
                            return (
                                <option value={clients.clientID} key={clients.clientID}>{clients.clientName}</option>
                            )
                        })}
                    </select>
                    {showBtn.addClientBtn && <button type="button" onClick={() => handleDefaultBtn()}>
                        <img src={plusIcon} alt='an image with an add icon.' />
                        Add
                    </button>}
                </div>
                {/* Use map to render additional select fields */}
                {fields.map((field, index) => (
                    <div key={field.id} className={styles.dynamicSelectBoxWrapper}>
                        <select {...register(`groupClients[${index}].groupId`)} defaultValue={field.groupId} onChange={handleDynamicSelectValue}>
                            <option value=""> - Please Select - </option>
                            {showBtn.storeClientData && showBtn.storeClientData.map((clients) => {
                                return (
                                    <option value={clients.clientID} key={clients.clientID}>{clients.clientName}</option>
                                )
                            })}
                        </select>
                        {/* Render "Add Client" button next to each select box */}
                        {field.groupId === '' && <button type="button" onClick={() => handleDynamicSelectValues(field)}>
                            <img src={plusIcon} alt='an image with an add icon.' />
                            Add
                        </button>}
                    </div>
                ))}
                <form onSubmit={handleSubmit(onSubmit)} className={styles.formBtnWrapper}>
                    <p onKeyDown={() => {}} onClick={() => navigate(-1)}>Cancel</p>
                    <input type="submit" name='Save' value='Save' disabled={showBtn.formSubmitBtn} className={showBtn.formSubmitBtn ? styles.inactiveInput : styles.activeInput} />
                </form>
            </div>
        </section>
    )
}
