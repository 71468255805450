// Hook (use-auth.js)
import React, { useContext, createContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createNewUser,
  editUser,
  getAllUserList,
  getUserById,
  getUserListPagination,
} from "../../controllers/user";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenExpiry } from "../Auth/TokenExpiry";
import { isTokenExpired } from "../../helper";
import PropTypes from "prop-types";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

function useProvideUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [viewUser, setViewUser] = useState(false);
  const [userId, setUserId] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errorText, setErrorText] = useState("");

  /**
   * Custom hook to fetch a paginated user list based on the provided parameters.
   * @param {number} page - The page number of the user list to fetch.
   * @param {number} count - The number of users to fetch per page.
   * @param {string} search - The search query to filter users.
   * @param {string} roleName - The role name to filter users.
   * @returns The result of the query containing the user list data.
   */
  const useGetUserList = (page, count, search, roleName) =>
    useQuery(
      ["userList", page, count, search, roleName],
      () => getUserListPagination(page, count, search, { roleName }),
      {
        enabled: true,
        cacheTime: 0,
        select: (data) => data.data,
        onSuccess: (data) => TokenExpiry(data?.ResponseCode),
      }
    );

  /**
   * Custom hook to fetch all user data using useQuery from React Query.
   * @returns The result of the useQuery hook for fetching all user data.
   */
  const useGetAllUser = () =>
    useQuery(["allUserList"], () => getAllUserList(), {
      enabled: true,
      select: (data) => data.data,
      onSuccess: (data) => TokenExpiry(data?.ResponseCode),
    });

  /**
   * Custom React query hook to fetch user data by ID.
   * @param {number} id - The ID of the user to fetch.
   * @returns The user data based on the provided ID.
   */
  const useGetUserById = (id) =>
    useQuery(["userById", id], () => getUserById(id), {
      enabled: !!id,
      select: (data) => data.data,
      onSuccess: (data) => TokenExpiry(data?.ResponseCode),
    });

  /**
   * Custom hook that uses a mutation to create a new user.
   * @returns {{
   *   mutateCreateUser: Function, // Function to create a new user
   *   isCreateUserLoading: boolean // Loading state of creating a new user
   * }}
   */
  const { mutate: mutateCreateUser, isLoading: isCreateUserLoading } =
    useMutation(createNewUser, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data?.data.success) {
          queryClient.refetchQueries("userList");
          queryClient.refetchQueries("allUserList");
          toast.success("Successfully created a user");
          if (location.pathname.includes("/ticket")) {
            navigate(-1);
          } else {
            navigate("user");
          }
        } else {
          toast.error(data.data.errors[0]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  /**
   * Custom hook that uses a mutation to edit a user and handles success and error cases.
   * @param {{Function}} editUser - The function to edit a user.
   * @param {{Object}} options - Additional options for the mutation.
   * @param {{Function}} options.onSuccess - Function to handle success case after editing user.
   * @param {{Function}} options.onError - Function to handle error case after editing user.
   * @returns {{Function}} mutateEditUser - Function to trigger the mutation to edit user.
   * @returns {{boolean}} isEditUserLoading - Boolean flag indicating if the mutation is in loading state.
   */
  const { mutate: mutateEditUser, isLoading: isEditUserLoading } = useMutation(
    editUser,
    {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data.data.success) {
          queryClient.refetchQueries("userList");
          queryClient.refetchQueries("allUserList");
          queryClient.invalidateQueries("userById");
          toast.success("Successfully updated a user");
          navigate(-1);
        } else {
          toast.error(data?.data?.errors[0]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    }
  );

  /**
   * Custom hook that uses a mutation to edit a user and handles success and error cases.
   * @param {{Function}} editUser - The function to edit a user.
   * @param {{Object}} options - Additional options for the mutation.
   * @param {{Function}} options.onSuccess - Function to handle success case after editing user.
   * @param {{Function}} options.onError - Function to handle error case after editing user.
   * @returns {{Function}} mutateEditUser - Function to trigger the mutation to edit user.
   * @returns {{boolean}} isEditUserLoading - Boolean flag indicating if the mutation is in loading state.
   */
  const { mutate: mutateDeleteUser, isLoading: isDeleteUserLoading } =
    useMutation(editUser, {
      onSuccess: (data) => {
        setOpenDeleteModal(false);
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data.data.success) {
          queryClient.refetchQueries("userList");
          queryClient.refetchQueries("allUserList");
          toast.success("Successfully deleted a user");
        } else {
          setErrorText(data?.data?.errors[0]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  /**
   * Handles the creation of a user by updating the user data with a role label and then
   * calls the mutateCreateUser function to create the user.
   * @param {{object}} data - The data object containing user information.
   * @returns None
   */
  const handleCreateUser = (data) => {
    const finalData = {
      ...data,
      role: [data.role.label],
    };
    mutateCreateUser(finalData);
  };

  /**
   * Handles editing a user by modifying the user data and calling the mutateEditUser function.
   * @param {{object}} data - The user data to be edited.
   * @returns None
   */
  const handleEditUser = (data) => {
    const finalData = {
      ...data,
      roles: [`${data.role.label}`],
      isActive: true,
      status: "Active",
    };
    delete finalData.roleName;
    delete finalData.roleId;
    delete finalData.role;
    // delete finalData.email
    mutateEditUser(finalData);
  };

  return {
    //view user state
    viewUser,
    setViewUser,

    //user id state
    userId,
    setUserId,

    //useQuery functions
    useGetUserList,
    useGetAllUser,
    useGetUserById,

    //create user
    isCreateUserLoading,
    handleCreateUser,

    //edit user
    isEditUserLoading,
    handleEditUser,

    //delete user
    openDeleteModal,
    setOpenDeleteModal,
    mutateDeleteUser,
    isDeleteUserLoading,
    errorText,
    setErrorText,
  };
}

export function ProvideUser({ children }) {
  const UserData = useProvideUser();
  return (
    <UserContext.Provider value={UserData}>{children}</UserContext.Provider>
  );
}

ProvideUser.propTypes = {
  children: PropTypes.node.isRequired,
};
