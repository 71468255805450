import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";
import styles from "../ViewClient/CreateClient.module.scss";
import CheckBox from "../../../components/Checkbox";
import { useClient } from "../../../utils/hooks";
import { pattern } from "../../../utils/validationPattern";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PropTypes from "prop-types";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import CustomButton from "../../../components/Button";
import addCircle from "../../../assets/Images/add-circle.svg";
import { Link, useLocation } from "react-router-dom";
import { UseGroupContext } from "../../../utils/hooks/Group";
import people from "../../../assets/Images/people.svg";
import trash from "../../../assets/Images/trash-2.svg";
import DeleteModal from "../../../components/DeleteModal";
import ErrorModal from "../../../components/DeleteModal/ErrorModal";

function ClientFormLayout({
  control,
  contactList,
  type,
  remove,
  view,
  watch,
  setValue,
}) {
  const location = useLocation();
  const [deactivate, setDeactivate] = useState(false);
  const [contact, setContact] = useState([]);
  const isRowDisabled = (index) => {
    const rowWatch = watch(`contact[${index}].clientContactID`);
    return (!!rowWatch || view) && type !== "editContact";
  };
  const {
    handledeletetClientContact,
    openDeleteModal,
    setOpenDeleteModal,
    clientArchiveErrorText,
    setClientArchiveErrorText,
  } = useClient();

  const { useGetAllGroup } = UseGroupContext();

  const { data: groupList } = useGetAllGroup();

  const groupNames =
    groupList?.results?.map((items) => ({
      label: items.groupName,
      value: items.groupID,
    })) || [];

  /**
   * Handles the deletion of a client contact by calling the handleDeleteClientContact function
   * and then closes the delete modal.
   * @returns None
   */
  const handleDelete = () => {
    if (!deactivate) {
      handledeletetClientContact(contact);
    } else {
      setOpenDeleteModal(false);
    }
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
    if (deactivate) {
      setValue("contact[0].isActive", true);
    }
  };

  const helperText = (error) => {
    return error?.message || null;
  };

  /**
   * Handles the click event when deleting an item from the list.
   * If the item has a clientContactID, it opens a delete modal and sets the contact data.
   * If the item does not have a clientContactID, it removes the item from the list at the specified index.
   * @param {{object}} data - The data of the item being deleted.
   * @param {{number}} index - The index of the item in the list.
   * @returns None
   */
  const onDeleteClick = (data, index) => {
    if (data.clientContactID) {
      setOpenDeleteModal(true);
      setContact(data);
    } else {
      remove(index);
    }
  };

  /**
   * Validates the input value based on the specified type.
   */
  const fieldValidation = (value, type) => {
    if (value) {
      if (!pattern.noSpace.test(value)) {
        return "Space is not allowed";
      }
      if (!pattern.email.test(value) && type === "email") {
        return "Invalid email address";
      }
    }
    return undefined;
  };

  const isAddGroupVisible = !(
    type === "editContact" ||
    view ||
    location.pathname.includes("/ticket")
  );

  return (
    <Box className={styles.Form}>
      <div className={styles.clientDetailsFormWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={8} md={5} lg={4}>
            <Controller
              name="clientName"
              control={control}
              rules={{
                required: "Client Name is Required",
                maxLength: {
                  value: 50,
                  message: "Text cannot exceed 50 characters",
                },
                validate: (value) => fieldValidation(value, "name"),
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextInput
                    {...field}
                    ref={field.ref}
                    label="Client Name"
                    placeholder="Enter Client Name"
                    type="text"
                    size="medium"
                    disabled={type === "editContact" || view}
                    error={!!error}
                    helpertext={helperText(error)}
                    precontent={
                      <PeopleOutlinedIcon sx={{ color: "#171A1FFF" }} />
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={8} md={5} lg={4}>
            <Controller
              name="address"
              control={control}
              rules={{
                validate: (value) => fieldValidation(value, "address"),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  ref={field.ref}
                  label="Address Line 1"
                  placeholder="Enter Address Line 1"
                  type="text"
                  size="medium"
                  disabled={type === "editContact" || view}
                  precontent={<HomeOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                  error={!!error}
                  helpertext={helperText(error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={8} md={5} lg={4}>
            <p className={styles.dropdownLabel}>
              Group <span>(Optional)</span>
            </p>
            <Controller
              name="group"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <SearchableDropDown
                  {...field}
                  options={groupNames}
                  precontent={
                    <img
                      src={people}
                      style={{ width: "20px", paddingRight: "5px" }}
                    />
                  }
                  placeholder={
                    type === "editContact" || view ? "" : "- Please Select -"
                  }
                  isDisabled={type === "editContact" || view}
                />
              )}
            />
          </Grid>
        </Grid>
        {isAddGroupVisible && (
          <div className={styles.groupBtnWrapper}>
            <CustomButton
              variant="contained"
              // onClick={handleAddClick}
            >
              <Link to="/group">
                <img src={addCircle} alt="an image with a plus icon." />
                Add Group
              </Link>
            </CustomButton>
          </div>
        )}
      </div>
      <Typography variant="h6" className={styles.Subtypography}>
        Client Contacts
      </Typography>

      {contactList.map((data, index) => (
        <Box key={data.id} className={styles.contactListContainer}>
          <Grid container spacing={3}>
            <Grid item xs={5.6} md={3.6} lg={2.4}>
              <Controller
                name={`contact[${index}].firstName`}
                control={control}
                rules={{
                  required: "First Name is Required",
                  maxLength: {
                    value: 50,
                    message: "Text cannot exceed 50 characters",
                  },
                  validate: (value) => fieldValidation(value, "name"),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    label="First Name"
                    placeholder="Enter First Name"
                    type="text"
                    size="medium"
                    precontent={
                      <PersonOutlinedIcon sx={{ color: "#171A1FFF" }} />
                    }
                    disabled={isRowDisabled(index)}
                    error={!!error}
                    helpertext={helperText(error)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5.6} md={3.6} lg={2.3}>
              <Controller
                name={`contact[${index}].lastName`}
                control={control}
                rules={{
                  required: "Last Name is Required",
                  maxLength: {
                    value: 50,
                    message: "Text cannot exceed 50 characters",
                  },
                  validate: (value) => fieldValidation(value, "name"),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    label="Last Name"
                    placeholder="Enter Last Name"
                    type="text"
                    size="medium"
                    disabled={isRowDisabled(index)}
                    precontent={
                      <PersonOutlinedIcon sx={{ color: "#171A1FFF" }} />
                    }
                    error={!!error}
                    helpertext={helperText(error)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={5.6} md={3.6} lg={2.3}>
              <Controller
                name={`contact[${index}].phone`}
                control={control}
                rules={{
                  pattern: {
                    value: pattern.phoneRegex,
                    message: "Enter a valid phone number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    label=" Phone Number"
                    placeholder="Enter Phone Number"
                    size="medium"
                    disabled={isRowDisabled(index)}
                    onChange={(e) => {
                      if (pattern.phoneFieldRegex.test(e.target.value)) {
                        field.onChange(e);
                      }
                    }}
                    precontent={
                      <LocalPhoneOutlinedIcon sx={{ color: "#171A1FFF" }} />
                    }
                    error={!!error}
                    helpertext={helperText(error)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5.6} md={3.6} lg={2.3}>
              <Controller
                name={`contact[${index}].email`}
                control={control}
                rules={{
                  required: " Email is Required",
                  validate: (value) => fieldValidation(value, "email"),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    label=" Email Address"
                    placeholder="Enter Email"
                    precontent={
                      <EmailOutlinedIcon sx={{ color: "#171A1FFF" }} />
                    }
                    type="text"
                    size="medium"
                    disabled={type === "editContact" || isRowDisabled(index)}
                    error={!!error}
                    helpertext={helperText(error)}
                  />
                )}
              />
            </Grid>

            {type !== "add" && (
              <Grid
                item
                xs={2}
                lg={1}
                className={styles.ActionButtons}
                marginTop={2}
              >
                <Controller
                  name={`contact[${index}].isActive`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CheckBox
                      {...field}
                      label="Active"
                      checked={field.value}
                      onClick={() => {
                        if (field.value) {
                          setOpenDeleteModal(true);
                        }
                        setDeactivate(true);
                      }}
                      disabled={isRowDisabled(index) || !data.clientContactID}
                      helpertext={helperText(error)}
                    />
                  )}
                />
              </Grid>
            )}
            {contactList.length > 1 && !view && (
              <Grid item xs={2} lg={1} className={styles.ActionButtons}>
                <IconButton
                  onClick={() => onDeleteClick(data, index)}
                  style={{ color: "red" }}
                >
                  <img src={trash} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      ))}

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          handleCancel={handleCancel}
          handleDeleteClick={handleDelete}
          deleteText={deactivate ? "Yes" : "Delete"}
          primaryText={`Are you sure want to ${
            deactivate ? "inactive" : "delete"
          } this contact?`}
          secondaryText="All the tickets related to this client contact will be deleted."
        />
      )}
      {clientArchiveErrorText && (
        <ErrorModal
          open={!!clientArchiveErrorText}
          handleClose={() => setClientArchiveErrorText("")}
          errorText={clientArchiveErrorText}
        />
      )}
    </Box>
  );
}

ClientFormLayout.propTypes = {
  control: PropTypes.object.isRequired,
  contactList: PropTypes.array,
  type: PropTypes.string,
  remove: PropTypes.func,
  view: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

export default ClientFormLayout;
