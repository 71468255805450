import React from "react";

const MessageIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08301 8.75H12.9163"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83366 15.3568H9.16699L12.8753 17.8235C13.4253 18.1901 14.167 17.7984 14.167 17.1318V15.3568C16.667 15.3568 18.3337 13.6901 18.3337 11.1901V6.1901C18.3337 3.6901 16.667 2.02344 14.167 2.02344H5.83366C3.33366 2.02344 1.66699 3.6901 1.66699 6.1901V11.1901C1.66699 13.6901 3.33366 15.3568 5.83366 15.3568Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
