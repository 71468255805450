import React, { useContext, createContext, useState } from "react";
import PropTypes from "prop-types";

const SidebarContext = createContext();

export const useSideBarContext = () => useContext(SidebarContext);

function useProvideSideBar() {
    const [isCompact, setIsCompact] = useState(true);
    const [roleName, setRoleName] = useState("");


    return {
        setIsCompact,
        isCompact,
        roleName,
        setRoleName
    }

}

export function ProvideSideBar({ children }) {
    const sideBarState = useProvideSideBar();
    return (
        <SidebarContext.Provider value={sideBarState}>
            {children}
        </SidebarContext.Provider>
    );
}

ProvideSideBar.propTypes = {
    children: PropTypes.node.isRequired,
  };