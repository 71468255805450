import { useNavigate } from 'react-router-dom';
import Img404 from '../../assets/Images/pageNotFound.png';
import CustomButton from '../Button';
import styles from './404Page.module.scss'

/**
 * Not found page components
 * @returns NotFoundPage components
 */
function NotFoundPage() {
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <div className={styles.not_found_img}>
        <img src={Img404} alt="" />
      </div>
      <p className={styles.page_title}>Page not found</p>
      <CustomButton onClick={() => navigate('/dashboard')}>
        Go Home
      </CustomButton>
    </div>
  );
}

export default NotFoundPage;
