import React, { useContext, createContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getUserArchiveListPagination, unarchiveUser, fetchArchivedClientList, fetchArchivedClientContactsList, getArchiveTicketList, unarchiveClientData, editClientContactArchiveData, unarchiveTicket } from "../../controllers/Archive";
import { TokenExpiry } from "../Auth/TokenExpiry";
import { isTokenExpired } from "../../helper";
import { toast } from "react-toastify";

const createUserArchiveListContext = createContext();

export const UseUserArchiveListContext = () => useContext(createUserArchiveListContext);

const useUserArchiveListDetails = () => {

    const [storeArchiveData, setStoreArchiveData] = useState({
        userArchiveData: [],
        userName: "",
        userDetails: {},
        clientDetails: {},
        clientContactDetails: {},
        ticketDetails: {},
        modalOpen: false,
        clientArchiveList: [],
        clientContactArchiveList: [],
        totalActiveRecord: "",
        ticketArchiveListData: [],
        page: 0,
        count: 10,
        search: ""


    });

    const queryClient = useQueryClient();

    /**
      * Custom hook to fetch a paginated user list based on the provided parameters.
      * @param {number} page - The page number of the user list to fetch.
      * @param {number} count - The number of users to fetch per page.
      * @param {string} search - The search query to filter users.
      * @returns The result of the query containing the user list data.
      */
    const useGetUserArchiveList = (page, count, search, roleName) =>
        useQuery(
            ["userArchiveList", page, count, search],
            () => getUserArchiveListPagination(page, count, search, { roleName }),
            {
                enabled: true,
                select: (data) => data.data,
                cacheTime: 0,
                onSuccess: (data) => TokenExpiry(data?.ResponseCode),
            }
        );

    /**
* Custom hook that uses a mutation to unarchive a user and handles success and error cases.
* @param {{Function}} unarchive - The function to unarchive a user.
* @param {{Object}} options - Additional options for the mutation.
* @param {{Function}} options.onSuccess - Function to handle success case after editing user.
* @param {{Function}} options.onError - Function to handle error case after editing user.
* @returns {{Function}} mutateEditUser - Function to trigger the mutation to edit user.
* @returns {{boolean}} isEditUserLoading - Boolean flag indicating if the mutation is in loading state.
*/
    const { mutate: mutateUnarchiveUser, isLoading: isEditUserLoading } = useMutation(
        unarchiveUser,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    queryClient.refetchQueries("userArchiveList");
                    toast.success("User unarchived successfully.");
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        userName: "",
                        userDetails: {},
                        modalOpen: false
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    /**
 * Handles the user unarchiving by calling the mutateUnarchiveUser function with the provided data and userId.
 * @param {any} data - The user unarchiving data to be handled.
 * @param {string} userId - The ID of the user whose unarchiving data is being handled.
 */
    const handleUserUnarchivingData = (data, userId) => {
        mutateUnarchiveUser({ data, userId });
    };

    /**
     * Custom hook to fetch a paginated user list based on the provided parameters.
     * @param {number} page - The page number of the user list to fetch.
     * @param {number} count - The number of users to fetch per page.
     * @param {string} search - The search query to filter users.
     * @returns The result of the query containing the user list data.
     */
    const { mutate: mutateUnarchiveClient, isLoading: isEditClientLoading } = useMutation(
        fetchArchivedClientList,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        clientArchiveList: data?.data?.results,
                        totalActiveRecord: data?.data?.totalActiveRecords
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    /**
    * Custom hook to fetch a paginated user list based on the provided parameters.
    * @param {number} page - The page number of the user list to fetch.
    * @param {number} count - The number of users to fetch per page.
    * @param {string} search - The search query to filter users.
    * @returns The result of the query containing the user list data.
    */
    const { mutate: mutateUnarchiveClientContact, isLoading: isEditClientContactLoading } = useMutation(
        fetchArchivedClientContactsList,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        clientContactArchiveList: data?.data?.results,
                        totalActiveRecord: data?.data?.totalActiveRecords
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    const handleClientList = (data) => {
        mutateUnarchiveClient({ data });
    }

    const handleClientContactList = (data) => {
        mutateUnarchiveClientContact({ data });
    }

    /**
 * Custom hook to fetch a list of archived tickets based on the provided parameters.
 * @param {string} mode - The mode of fetching tickets.
 * @param {number} page - The page number of the ticket list.
 * @param {number} count - The number of tickets to fetch per page.
 * @param {string} search - The search query for filtering tickets.
 * @param {string} filter - The filter criteria for fetching tickets.
 * @param {string} sortOrder - The order in which tickets should be sorted.
 * @returns The result of the query to fetch ticket list.
 */
    const { mutate: mutateArchiveTicketList, isLoading: isArchiveTicketListLoading } = useMutation(
        getArchiveTicketList,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        ticketArchiveListData: data?.data?.results,
                        totalActiveRecord: data?.data?.totalActiveRecords
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    const handleArchiveTicketListData = (mode, ticketPage, ticketPageCount, ticketSortOrder, debouncedSearchValue, filterData) => {
        const finalData = {
            mode,
            ticketPage,
            ticketPageCount,
            ticketSortOrder,
            debouncedSearchValue,
            ...filterData
        }

        mutateArchiveTicketList({ finalData });
    }

    /**
* Custom hook that uses a mutation to unarchive a client and handles success and error cases.
* @param {{Function}} unarchive - The function to unarchive a user.
* @param {{Object}} options - Additional options for the mutation.
* @param {{Function}} options.onSuccess - Function to handle success case after editing user.
* @param {{Function}} options.onError - Function to handle error case after editing user.
* @returns {{Function}} mutateEditUser - Function to trigger the mutation to edit user.
* @returns {{boolean}} isEditUserLoading - Boolean flag indicating if the mutation is in loading state.
*/
    const { mutate: mutateUnarchiveClientList } = useMutation(
        unarchiveClientData,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    toast.success("Client unarchived successfully.");
                    const data = {
                        page: storeArchiveData.page + 1,
                        tableCount: storeArchiveData.count,
                        searchValue: storeArchiveData.search
                    }
                    handleClientList(data);
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        userName: "",
                        clientDetails: {},
                        modalOpen: false
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    /**
* Custom hook that uses a mutation to unarchive a client and handles success and error cases.
* @param {{Function}} unarchive - The function to unarchive a user.
* @param {{Object}} options - Additional options for the mutation.
* @param {{Function}} options.onSuccess - Function to handle success case after editing user.
* @param {{Function}} options.onError - Function to handle error case after editing user.
* @returns {{Function}} mutateEditUser - Function to trigger the mutation to edit user.
* @returns {{boolean}} isEditUserLoading - Boolean flag indicating if the mutation is in loading state.
*/
    const { mutate: mutateUnarchiveClientContactList } = useMutation(
        editClientContactArchiveData,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    toast.success("Client contact unarchived successfully.");
                    const data = {
                        page: storeArchiveData.page + 1,
                        tableCount: storeArchiveData.count,
                        searchValue: storeArchiveData.search
                    }
                    handleClientContactList(data);
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        userName: "",
                        clientContactDetails: {},
                        modalOpen: false
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    /**
* Custom hook that uses a mutation to unarchive a ticket and handles success and error cases.
* @param {{Function}} unarchive - The function to unarchive a user.
* @param {{Object}} options - Additional options for the mutation.
* @param {{Function}} options.onSuccess - Function to handle success case after editing user.
* @param {{Function}} options.onError - Function to handle error case after editing user.
* @returns {{Function}} mutateEditUser - Function to trigger the mutation to edit user.
* @returns {{boolean}} isEditUserLoading - Boolean flag indicating if the mutation is in loading state.
*/
    const { mutate: mutateUnarchiveTicketList } = useMutation(
        unarchiveTicket,
        {
            onSuccess: (data) => {
                if (isTokenExpired(data?.data?.ResponseCode)) {
                    TokenExpiry(data?.data?.ResponseCode);
                } else if (data.data.success) {
                    toast.success("Ticket unarchived successfully.");
                    const filterData = {
                        clientName: null,
                        ticketStatus: null,
                        dueDate: null,
                        assignee: null,
                    };

                    const mode = "list";
                    const ticketPage = storeArchiveData.page + 1;
                    const ticketPageCount = storeArchiveData.count;
                    const ticketSortOrder = { name: "dueDate", direction: "asc" };

                    handleArchiveTicketListData(mode, ticketPage, ticketPageCount, ticketSortOrder, storeArchiveData.search, filterData);
                    setStoreArchiveData((prevState) => ({
                        ...prevState,
                        userName: "",
                        ticketDetails: {},
                        modalOpen: false
                    }))
                } else {
                    toast.error(data?.data?.errors[0]);
                }
            },
            onError: (err) => {
                toast.error(err.message);
            },
        }
    );

    const handleClientUnarchving = (data, clientId) => {
        mutateUnarchiveClientList({ data, clientId });
    }

    const handleClientContactUnarchiving = (data, clientId) => {
        mutateUnarchiveClientContactList({ data, clientId })
    }

    const handleTicketUnarchiving = (data, ticketId) => {
        mutateUnarchiveTicketList({ data, ticketId })
    }

    return {
        storeArchiveData,
        setStoreArchiveData,
        useGetUserArchiveList,
        isEditUserLoading,
        handleUserUnarchivingData,
        isEditClientLoading,
        handleClientList,
        handleClientContactList,
        handleArchiveTicketListData,
        isArchiveTicketListLoading,
        handleClientUnarchving,
        handleClientContactUnarchiving,
        handleTicketUnarchiving,
        isEditClientContactLoading
    }
}

export function ProviderUserArchiveListDetails({ children }) {
    const userArchiveList = useUserArchiveListDetails();
    return (
        <createUserArchiveListContext.Provider value={userArchiveList}>
            {children}
        </createUserArchiveListContext.Provider>
    );
}
