import React, { useContext, createContext } from "react";
import PropTypes from "prop-types";
import {
  getDocumentTypeList,
  getRolesList,
  getTicketStatusList,
} from "../../controllers/master";
import { useQuery } from "react-query";
import { TokenExpiry } from "../Auth/TokenExpiry";

const masterContext = createContext();

export const useMaster = () => useContext(masterContext);

function useProvideMaster() {

  /**
   * Custom hook that uses useQuery to fetch roles list data.
   * @returns Query result object with roles list data.
   */
  const useGetRoles = () =>
    useQuery(["roles"], () => getRolesList(), {
      enabled: true,
      select: (data) => data.data,
      onSuccess: (data) => TokenExpiry(data?.ResponseCode),
    });

  /**
   * Custom React hook that fetches the document type list using useQuery from React Query.
   * @returns The document type list data.
   */
  const useGetDocumentType = () =>
    useQuery(["documentType"], () => getDocumentTypeList(), {
      enabled: true,
      select: (data) => data.data.results,
    });

  /**
   * Custom React hook that uses useQuery to fetch ticket status list data.
   * @returns The ticket status list data.
   */
  const useGetTicketStatus = () =>
    useQuery(["ticketStatus"], () => getTicketStatusList(), {
      enabled: true,
      select: (data) => data.data.results,
    });

  return {
    useGetRoles,
    useGetDocumentType,
    useGetTicketStatus,
  };
}

export function ProvideMaster({ children }) {
  const master = useProvideMaster();
  return (
    <masterContext.Provider value={master}>{children}</masterContext.Provider>
  );
}
ProvideMaster.propTypes = {
  children: PropTypes.node.isRequired,
};
