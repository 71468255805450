import React from "react";

const RequestIcon = ({ width = 35, height = 35 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4893 13.1992C16.0851 14.1471 18.9143 14.1471 21.5101 13.1992"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5293 2.91797H10.471C7.36471 2.91797 4.8418 5.45547 4.8418 8.54714V29.0951C4.8418 31.7201 6.72305 32.8284 9.02721 31.5596L16.1439 27.6076C16.9022 27.1846 18.1272 27.1846 18.871 27.6076L25.9876 31.5596C28.2918 32.843 30.173 31.7346 30.173 29.0951V8.54714C30.1585 5.45547 27.6355 2.91797 24.5293 2.91797Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5293 2.91797H10.471C7.36471 2.91797 4.8418 5.45547 4.8418 8.54714V29.0951C4.8418 31.7201 6.72305 32.8284 9.02721 31.5596L16.1439 27.6076C16.9022 27.1846 18.1272 27.1846 18.871 27.6076L25.9876 31.5596C28.2918 32.843 30.173 31.7346 30.173 29.0951V8.54714C30.1585 5.45547 27.6355 2.91797 24.5293 2.91797Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RequestIcon;
