import React, { useEffect } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";

import { BasicModal } from "../../../components/Modal/Modal";

import styles from "./CreateClient.module.scss";
import CustomButton from "../../../components/Button";
import ClientFormLayout from "../ClientFormLayout";
import { useClient } from "../../../utils/hooks";
import ClearIcon from "@mui/icons-material/Clear";
import { Loader } from "../../../components/Loader";

export function ViewClient({ open, handleClose, maxwidth, fullScreen }) {
  const { clientId, useGetClientByID } = useClient();
  const { data: clientData, isLoading } = useGetClientByID(clientId);

  const { control, setValue, watch, reset } = useForm({
    mode: "all",
    defaultValues: {
      isActive: false,
    }
  });

  const { fields: contactList, remove } = useFieldArray({
    control,
    name: "contact",
  });

  /**
   * useEffect hook that triggers when clientData changes. If clientId and clientData are both truthy,
   * it creates a new object editClientData based on clientData with additional modifications.
   * It then resets the form with the updated editClientData.
   * @returns None
   */
  useEffect(() => {
    if (clientId && clientData) {
      const editClientData = {
        ...clientData.result,
        isActive: clientData.result?.status === "Active",
        group: clientData?.result?.groupName
          ? {
              label: clientData?.result?.groupName,
              value: clientData?.result?.groupID,
            }
          : "",
        contact: clientData.result?.clientContacts.map((item) => ({
          ...item,
          isActive: item?.status === "Active",
        })),
      };
      reset({ ...editClientData });
    }
  }, [clientData]);


  return (
    <BasicModal
      open={open}
      close={handleClose}
      fullScreen={fullScreen}
      maxWidth={maxwidth}
    >
      {isLoading ? (
        <Box className={styles.loaderContainer}>
          <Loader />
        </Box>
      ) : (
        <Box>
          <Box className={styles.header}>
            <Box className={styles.flex}>
              <Typography className={styles.headerText}>View Client</Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>
          <Box>
            <Box>
              <form>
                <Box className={styles.mainContainer}>
                  <Box className={styles.ViewCard}>
                    <Typography className={styles.formHeader}>
                      Client Details
                    </Typography>

                    <ClientFormLayout
                      control={control}
                      contactList={contactList}
                      view
                      isView
                      remove={remove}
                      watch={watch}
                      setValue={setValue}
                    />

                    <Box>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        wrap="nowrap"
                        className={styles.BottomButtons}
                      >
                        <CustomButton
                          variant="text"
                          size="medium"
                          onClick={handleClose}
                        >
                          Close
                        </CustomButton>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </BasicModal>
  );
}
ViewClient.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  maxwidth: PropTypes.string, 
  fullScreen: PropTypes.bool 
};