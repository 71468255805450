import moment from "moment";
import { toast } from "react-toastify";

/**
 * Transforms an input array of objects into an array of objects with specified label and value keys.
 * @param {Array} inputArray - The input array of objects to transform.
 * @param {string} labelKey - The key to use for the label in the transformed objects.
 * @param {string} valueKey - The key to use for the value in the transformed objects.
 * @returns {Array} An array of objects with label and value keys based on the input array.
 */
export const dropDownOptionArray = (inputArray, labelKey, valueKey) => {
  // Check if the inputArray is an array and not empty
  if (!Array.isArray(inputArray) || inputArray.length === 0) {
    return [];
  }

  // Map the inputArray to a new array of objects with "label" and "value" keys
  const transformedArray = inputArray.map((item) => {
    return {
      label: item[labelKey] || "",
      value: item[valueKey] || "",
    };
  });

  return transformedArray;
};

/**
 * Formats a given date into a specific format.
 * @param {Date} date - The date to be formatted.
 * @returns {string | null} The formatted date in the format "YYYY-MM-DDT00:00:00.000[Z]",
 * or null if the input date is falsy.
 */
export const dateFormatter = (date) => {
  if (date) {
    const newDate = new Date(date);
    const formattedDate = moment(newDate).format("YYYY-MM-DDT00:00:00.000[Z]");
    return formattedDate;
  }
  return null;
};

/**
 * Converts a given date from one format to another.
 * @param {string} date - The date to be converted.
 * @param {string} presentFormat - The format of the input date.
 * @param {string} [requiredFormat="MM/DD/YYYY"] - The format to which the date should be converted.
 * @returns {string} The date converted to the required format, or an empty string if input date is falsy.
 */
export const covertDate = (
  date,
  presentFormat,
  requiredFormat = "MM/DD/YYYY"
) => {
  if (date) {
    if (presentFormat) {
      const dateObj = moment(date, presentFormat);
      const formattedDate = moment(dateObj).format(requiredFormat);
      return formattedDate;
    } else {
      const dateObj = new Date(date);
      const formattedDate = moment(dateObj).format(requiredFormat);
      return formattedDate;
    }
  }
  return "";
};

/**
 * Checks if all values in the given object are null.
 * @param {Object} obj - The object to check for null values.
 * @returns {boolean} True if all values in the object are null, false otherwise.
 */
export const areAllValuesNull = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if a given response code indicates that the token is expired.
 * @param {number} responseCode - The response code to check
 * @returns {boolean} True if the response code indicates token expiration, false otherwise
 */
export const isTokenExpired = (responseCode) =>
  responseCode === 1004 || responseCode === 1003 || responseCode === 1005;

export const checkForUniqueEmail = (data) => {
  // Create a Set to store unique emails
  const uniqueEmails = new Set();

  // Flag to indicate if there are duplicates
  let hasDuplicates = false;

  // Iterate through the array of objects
  data.forEach((item) => {
    // Check if the email already exists in the Set
    if (uniqueEmails.has(item.email)) {
      hasDuplicates = true;
      toast.error(`Duplicate email found: ${item.email}`);
    } else {
      // If it doesn't exist, add it to the Set
      uniqueEmails.add(item.email);
    }
  });

  return hasDuplicates;
};

export const getFileType = (url) => {
  if (url) {
    return url.split(".").pop().toLowerCase();
  }
  return "";
};

export const changeFavIcon = (logo,title) => {
  const obj = document.querySelector("#favIcon");   
  const titleObj = document.querySelector("#PageTitle");
  if(obj != null && titleObj != null){
    titleObj.innerText = title || "alliantDIGITAL Dox";
    obj.href = logo;
  }
}

export const setHeaderProp = () => {
  return {
    style: {
      left: 0,
      height: 20,
      background: "#efefef",
      borderBottom: "0",
      fontSize: "12px",
      fontWeight: "600",
      textAlign: "center",
      zIndex: 20,
      padding: "12px",
      color: "var(--primary-color)",
    },
  }
}