import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const setStorageValue = (key, value) => {
    document.cookie = `${key}=${value}; path=/;cookie=ok;`;
    return value;
};

export const getStorageValue = (key) => {
    const value = cookies.get(key);
    return value;
};

export const clearData = () => {
    localStorage.removeItem("responseCode");
    localStorage.removeItem("forceResetResponseCode");
    localStorage.removeItem("user-details");
    sessionStorage.clear();
    localStorage.removeItem("org_name");
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    window.location.reload();
};
