import React from "react";
import { Grid } from "@mui/material";
import { useAuth } from "../../utils/hooks";
import { Loader } from "../../components/Loader";
import { Controller } from "react-hook-form";
import styles from "./OtpLogin.module.scss";
import CustomButton from "../../components/Button";

const OtpLoginContainer = ({
  control,
  // handleResendOTP,
  isApiLoading,
  // isResendDisabled,
  // timer,
  handleKeyDown,
  handleChange,
  qrCode,
}) => {
  const { useGetConfigData } = useAuth();
  const { data: userConfigData, isLoading: isUserConfigDataLoading } =
    useGetConfigData();

  if (isUserConfigDataLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <div className={styles.logoWrapper}>
            <img
              src={userConfigData?.result?.logoURLReference}
              alt="Placeholder"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={7} alignContent="center">
          <div className={styles.formContainer}>
            <p className={styles.heading}>Multi-Factor Authentication</p>
            {qrCode && (
              <>
                <p className={styles.subHeading}>
                  Scan the QR code on your authenticator app
                </p>
                <img className={styles.qrCode} src={qrCode} alt="qrCode" />
              </>
            )}
            <p className={styles.subHeading}>
              Please enter the 6-digit verification code.
            </p>
            <div className={styles.form}>
              {Array.from({ length: 6 }).map((_, index) => (
                <Controller
                  key={index}
                  name={`digits.${index}`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      maxLength="1"
                      id={`digit-${index}`}
                      autoComplete="off"
                      onChange={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      disabled={isApiLoading}
                    />
                  )}
                />
              ))}
            </div>
            {/* {!isGoogleAuth && (
              <CustomButton
                type="button"
                onClick={handleResendOTP}
                disabled={isResendDisabled || isApiLoading}
              >
                {isResendDisabled ? `Resend OTP (${timer}s)` : "Resend OTP"}
              </CustomButton>
            )} */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OtpLoginContainer;
