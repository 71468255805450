import React from "react";
import PropTypes from "prop-types";
import styles from "./TicketManagementTable.module.scss";
import { useAuth, useMaster } from "../../../utils/hooks";
import { Loader } from "../../../components/Loader";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { covertDate } from "../../../utils/helper";
import openStatus from "../../../assets/Images/ticket-status/open.svg";
import needsReviewStatus from "../../../assets/Images/ticket-status/needs-review.svg";
import followupStatus from "../../../assets/Images/ticket-status/follow-up.svg";
import completedStatus from "../../../assets/Images/ticket-status/completed.svg";
import ticketEdit from "../../../assets/Images/ticket-edit.svg";
import CalendarIcon from "../../../assets/Icons/CalendarIcon";
import TicketCheckIcon from "../../../assets/Icons/TicketCheckIcon";
import ProfileIcon from "../../../assets/Icons/ProfileIcon";
import {
  ticketLabelMappingColor,
  ticketlabelMappingBackground,
} from "../../../utils/contants";
import EllipsisText from "react-ellipsis-text/lib/components/EllipsisText";
import { TrashIcon } from "../../../assets/Icons/TrashIcon";

export default function Kanbanview({
  ticketKanbanData,
  isLoading,
  handleDeleteClick,
  isDeleteVisible,
}) {
  const navigate = useNavigate();
  const { useGetTicketStatus } = useMaster();
  const { user } = useAuth();

  const { data: ticketStatusList, isLoading: isTicketStatusListLoading } =
    useGetTicketStatus();

  const renderStatusImage = (item) => {
    const statusMap = {
      Open: openStatus,
      "Needs Review": needsReviewStatus,
      "Follow Up Request": followupStatus,
      "Completed": completedStatus,
      "Cancelled": openStatus,
    };

    return <img src={statusMap[item.status]} alt="" />;
  };

  const isEdit = (ticketData) => {
    if (
      ticketData?.ticketStatus === "Cancelled" ||
      ticketData?.ticketStatus === "Completed"
    ) {
      return false;
    } else if (
      user?.RoleName === "Admin" ||
      ticketData.createdByUserId === user?.UserID ||
      ticketData.assigees.includes(Number(user?.UserID))
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.kanbanComponentWrapper}>
      {isLoading || isTicketStatusListLoading ? (
        <Loader />
      ) : (
        <div className={styles.kanbanContainer}>
          {ticketStatusList?.map((item) => {
            const mapDataWithParentArray = ticketKanbanData?.find(
              (ticketData) => ticketData.status === item.status
            );

            return (
              <div key={item.statusID} className={styles.kanbanGrid}>
                <div className={styles.gridContentWrapper}>
                  <div className={styles.ticketStatus}>
                    <div className={styles.kanbanHeader}>
                      <div className={styles.kanbanHeaderLeft}>
                        {renderStatusImage(item)}
                        <span className={styles.statusName}>{item.status}</span>
                      </div>
                      <span className={styles.itemLength}>
                        {mapDataWithParentArray === undefined
                          ? "0"
                          : mapDataWithParentArray?.status === item?.status &&
                            mapDataWithParentArray?.list?.length}
                      </span>
                    </div>
                  </div>
                  <div className={styles.cardContentContainer}>
                    {mapDataWithParentArray === undefined ? (
                      <div className={styles.cardContent}>
                        <p> Not Avaliable.</p>
                      </div>
                    ) : (
                      <>
                        {mapDataWithParentArray?.list?.map((innerData) => {
                          return (
                            <div
                              className={styles.cardContent}
                              key={innerData.ticketID}
                              onKeyDown={() => {}}
                              onClick={() =>
                                navigate(`view/${innerData.ticketID}`)
                              }
                            >
                              <div className={styles.cardFirstData}>
                                <div
                                  className={styles.ticketLabel}
                                  style={{
                                    color:
                                      ticketLabelMappingColor[
                                        innerData.ticketLabel
                                      ],
                                    backgroundColor:
                                      ticketlabelMappingBackground[
                                        innerData.ticketLabel
                                      ] || "transparent",
                                  }}
                                >
                                  {innerData.ticketLabel}
                                </div>
                                <div>
                                  {isEdit(innerData) && (
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`edit/${innerData.ticketID}`);
                                      }}
                                    >
                                      <img src={ticketEdit} />
                                    </IconButton>
                                  )}
                                  {isDeleteVisible(innerData) && (
                                    <IconButton
                                      onClick={(e) =>
                                        handleDeleteClick(e, innerData)
                                      }
                                    >
                                      <TrashIcon />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                              <div className={styles.cardRestData}>
                                <p className={styles.requestedClient}>
                                  {innerData.clientName}
                                </p>
                                <div className={styles.RequestItem}>
                                  <CalendarIcon />
                                  <p>
                                    {covertDate(
                                      innerData.dueDate,
                                      "MM/DD/YYYY HH:mm:ss"
                                    )}
                                  </p>
                                </div>
                                <div className={styles.RequestItem}>
                                  <TicketCheckIcon />
                                  <p>{innerData.checkList}</p>
                                </div>
                                <div className={styles.RequestItem}>
                                  <ProfileIcon />
                                  <p>
                                    <EllipsisText
                                      text={innerData.assignedTo}
                                      length={30}
                                      tooltip={innerData.assignedTo}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

Kanbanview.propTypes = {
  ticketKanbanData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isDeleteVisible: PropTypes.func,
  handleDeleteClick: PropTypes.func,
};
