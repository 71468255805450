import React from "react";
import { Navigate } from "react-router-dom";
import { getStorageValue } from "../pages/Login/cookies";
import PropTypes from "prop-types";

const PrivateRoute = ({ children }) => {
  const accessLoginToken = getStorageValue("access_token");

  if (!accessLoginToken) {
    sessionStorage.clear();
    return <Navigate to="/" />;
  }

  return children;
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
