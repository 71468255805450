export function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <title>plus</title>
      <g
        strokeWidth="1.5"
        fill="none"
        stroke="#FFFFFF"
        className="nc-icon-wrapper"
      >
        <line
          x1="9"
          y1="3.25"
          x2="9"
          y2="14.75"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#FFFFFF"
        ></line>
        <line
          x1="3.25"
          y1="9"
          x2="14.75"
          y2="9"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
      </g>
    </svg>
  );
}
