import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import styles from "../ViewClient/CreateClient.module.scss";
import CustomButton from "../../../components/Button";
import { PlusIcon } from "../../../assets/Icons/PlusIcon";
import { useClient } from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router";
import Layout from "../../../layouts";
import ClientFormLayout from "../ClientFormLayout";
import { Loader } from "../../../components/Loader";
import PropTypes from "prop-types";
import { checkForUniqueEmail } from "../../../utils/helper";
import building from "../../../assets/Images/dashboard-images/building.svg";
import addCircle from "../../../assets/Images/add-circle.svg";

export function AddOrEditClient({ type }) {
  const {
    setClientContact,
    handleEditClient,
    handleEditClientContact,
    useGetClientByID,
    useGetClientByContactID,
    isCreateClientContactListLoading,
    isEditClientLoading,
    isCreateClientLoading,
    handleDublicateEmail,
    setClientDataStored,
  } = useClient();
  const navigate = useNavigate();
  /**
   * Initializes form control functions and values using the useForm hook from react-hook-form library.
   * @param {{mode: string, defaultValues: object}} - Configuration object for the useForm hook.
   * @returns {{handleSubmit: function, control: object, setValue: function, watch: function, reset: function}} - Object containing form control functions and values.
   */
  const { handleSubmit, control, setValue, watch, reset } = useForm({
    mode: "all",
    defaultValues: useMemo(
      () => ({
        clientName: "",
        address: "",
        isActive: true,
        contact: [
          {
            lastName: "",
            email: "",
            firstName: "",
            phone: "",
            isActive: true,
          },
        ],
      }),
      []
    ),
  });

  const {
    fields: contactList,
    append,
    remove,
  } = useFieldArray({ control, name: "contact" });

  const { id, contactId } = useParams();

  const { data: clientData, isLoading: isClientLoading } = useGetClientByID(id);
  const { data: clientConatctData, isLoading } =
    useGetClientByContactID(contactId);

  /**
   * useEffect hook that triggers when clientData or clientContactData changes.
   * If both id and clientData are truthy, it creates a new object editClientData
   * by spreading clientData.result and setting isActive based on the status.
   * It also maps through clientData.result.clientContacts to update isActive based on status.
   * Then it resets the form with the edited client data.
   * If contactId and clientContactData are truthy, it creates a new object editClientContactData
   * by spreading clientContactData.result.client and setting isActive based on the status.
   * It then resets the form with the edited client contact data.
   * @returns None
   */
  useEffect(() => {
    if (id && clientData) {
      const editClientData = {
        ...clientData.result,
        isActive: clientData?.result?.status === "Active",
        group: clientData?.result?.groupName
          ? {
              label: clientData?.result?.groupName,
              value: clientData?.result?.groupID,
            }
          : "",

        contact: clientData?.result?.clientContacts.map((item) => ({
          ...item,
          isActive: item?.status === "Active",
        })),
      };

      reset(editClientData);
    }
    if (contactId && clientConatctData) {
      const editClientContactData = {
        ...clientConatctData?.result?.client,
        group: clientData?.result?.client?.groupName
          ? {
              label: clientData?.result?.client.groupName,
              value: clientData?.result?.client.groupID,
            }
          : "",

        contact: [
          {
            ...clientConatctData?.result,
            isActive: clientConatctData?.result?.status === "Active",
          },
        ],
      };

      reset(editClientContactData);
    }
  }, [clientData, clientConatctData]);

  /**
   * Handles the click event for adding a new entry to the form.
   * It appends a new entry with default values for lastName, email, firstName, phone, and isActive.
   * @returns None
   */
  const handleAddClick = () => {
    append({
      lastName: "",
      email: "",
      firstName: "",
      phone: "",
      isActive: true,
    });
  };
  /**
   * Handles form submission based on the type of operation.
   * If the type is "add", it sets the client data, client contact, and checks for duplicate email.
   * If the type is "edit", it creates edited data and calls handleEditClient.
   * If the type is "editContact", it calls handleEditClientContact.
   * @param {{object}} data - The data submitted from the form.
   * @returns None
   */
  const onSubmit = (data) => {
    /**
     * this object would add a new key and value pair to the existing data object.
     * @returns none
     */
    const updatedClientData = {
      ...data,
      groupID: data?.group?.value || 0,
    };

    delete updatedClientData.group;

    if (type === "add") {
      if (!checkForUniqueEmail(data?.contact)) {
        setClientDataStored(updatedClientData);
        setClientContact(data?.contact);
        handleDublicateEmail(data?.contact);
      }
    } else if (type === "edit") {
      const editedData = {
        id,
        ...updatedClientData,
      };
      handleEditClient(editedData);
    } else if (type === "editContact") {
      handleEditClientContact(data);
    }
  };

  function headerText() {
    if (type === "edit") {
      return "Edit Client";
    }
    if (type === "editContact") {
      return "Edit Client Contact";
    } else if (type === "add") {
      return "Add Client ";
    }
  }

  return (
    <Layout>
      <Box>
        <Box className={styles.flex}>
          <Typography className={styles.headerText}>
            {headerText()} <img src={building} alt="an image with a building" />
          </Typography>
        </Box>
        <Box>
          <Box>
            {isLoading || isClientLoading ? (
              <Box className={styles.loaderContainer}>
                <Loader />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={styles.mainContainer}>
                  <Box className={styles.UserCard}>
                    <Typography variant="h6" className={styles.formHeader}>
                      Client Details
                    </Typography>

                    <ClientFormLayout
                      key={isLoading}
                      type={type}
                      control={control}
                      contactList={contactList}
                      remove={remove}
                      watch={watch}
                      setValue={setValue}
                    />
                    {type !== "editContact" && (
                      <div className={styles.addContactBtnWrapper}>
                        <CustomButton
                          // className={styles.right}
                          variant="contained"
                          onClick={handleAddClick}
                        >
                          <img
                            src={addCircle}
                            alt="an image with a plus icon."
                          />
                          Add Contact
                        </CustomButton>
                      </div>
                    )}

                    <Box>
                      <Grid
                        container
                        alignItems="center"
                        gap={2}
                        justifyContent="flex-end"
                        wrap="nowrap"
                        className={styles.BottomButtons}
                      >
                        <CustomButton
                          variant="text"
                          size="medium"
                          onClick={() => navigate(-1)}
                          style={{ color: "#f00" }}
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          size="medium"
                          disabled={
                            isCreateClientContactListLoading ||
                            isEditClientLoading ||
                            isCreateClientLoading
                          }
                        >
                          Save
                        </CustomButton>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

AddOrEditClient.propTypes = {
  type: PropTypes.oneOf(["edit", "add", "editContact"]).isRequired,
};
