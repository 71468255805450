import React from "react";
import "./Button.scss";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = ({ children, variant = 'contained', ...props }) => {
  return (
    <div className="customButtonContainer">
      <Button variant={variant} {...props}>{children}</Button>
    </div>
  );
};
CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

export default CustomButton;
