import React from 'react';
import PropTypes from "prop-types";
import styles from "./LoadingOverlay.module.scss";
import { Loader } from '../Loader';

const LoadingOverlay = ({ isLoading = false }) => {
  return isLoading ? (
    <div className={styles.overlay}>
      <Loader />
    </div>
  ) : null;
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool,
};

export default LoadingOverlay;