import React, { useEffect } from 'react';
import { useSideBarContext } from "../../utils/hooks";
import Sidebar from "./Sidebar";

export default function Sidebarcomponent() {
    const { setIsCompact } = useSideBarContext();



    useEffect(() => {
        // Check local storage for the previous state
        const storedState = localStorage.getItem('sidebarState');
        setIsCompact(storedState ? JSON.parse(storedState) : true);
    }, []);

    return (
            <Sidebar />
    )
}
