import React, { forwardRef } from "react";
import {
  TextField,
  InputAdornment,
  Box,
  FormHelperText,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import "./inputBox.scss";

export const TextInput = forwardRef((props, ref) => {
  const {
    type,
    size,
    label,
    placeholder,
    name,
    value,
    defaultValue,
    helpertext,
    error,
    variant,
    disabled,
    required,
    multiline,
    rows,
    minRows,
    maxRows,
    precontent,
    postcontent,
    onChange,
    autoComplete,
    sx,
    labelsx,
    preContentSx,
    postContentSx,
    onFocus,
    onBlur,
    inputRef,
    autoFocus,
    view,
  } = props;
  return (
    <Box
      className="FormControlComponent"
      disabled={disabled}
      data-testid="text-input"
    >
      {label && (
        <Box
          className="InputLabelComponent"
          htmlFor={`text-input-${name || "box"}`}
          required={required}
          sx={labelsx}
        >
          {label}
        </Box>
      )}
      <TextField
        className="TextFieldComponent"
        {...props}
        ref={ref}
        autoComplete={autoComplete}
        sx={sx}
        fullWidth
        hiddenLabel
        label=""
        id={`text-input-${name || "box"}`}
        type={type || "text"}
        size={size || "small"}
        variant={variant || "filled"}
        placeholder={placeholder}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        InputProps={{
          disableUnderline: true,
          readOnly: view,
          startAdornment: precontent ? (
            <InputAdornment position="start" sx={preContentSx}>
              {precontent}
            </InputAdornment>
          ) : null,
          endAdornment: postcontent ? (
            <InputAdornment position="end" sx={postContentSx}>
              {postcontent}
            </InputAdornment>
          ) : null,
        }}
        inputRef={inputRef}
      />
      {error && helpertext && (
        <FormHelperText className="HelperText">
          <Typography variant="body2 Danger">{helpertext}</Typography>
        </FormHelperText>
      )}
    </Box>
  );
});

TextInput.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpertext: PropTypes.string,
  error: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  precontent: PropTypes.node,
  postcontent: PropTypes.node,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  sx: PropTypes.object,
  labelsx: PropTypes.object,
  preContentSx: PropTypes.object,
  postContentSx: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  autoFocus: PropTypes.bool,
  view: PropTypes.bool,
};
