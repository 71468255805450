import React from "react";

const UploadIcon = ({ color = "#CCCCCC" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5V6L10.5 4.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6L7.5 4.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 9C2.25 9 2.25 10.3425 2.25 12V12.75C2.25 14.82 2.25 16.5 6 16.5H12C15 16.5 15.75 14.82 15.75 12.75V12C15.75 10.3425 15.75 9 12.75 9C12 9 11.79 9.1575 11.4 9.45L10.635 10.26C9.75 11.205 8.25 11.205 7.3575 10.26L6.6 9.45C6.21 9.1575 6 9 5.25 9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 9.00125V6.00125C3.75 4.49375 3.75 3.24875 6 3.03125"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 9.00125V6.00125C14.25 4.49375 14.25 3.24875 12 3.03125"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
