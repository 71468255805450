import React from "react";

const ProfileIcon = ({ color = "#111111" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/profile-circle">
        <g id="profile-circle">
          <path
            id="Vector"
            d="M15.1504 15.9766C15.0629 15.9641 14.9504 15.9641 14.8504 15.9766C12.6504 15.9016 10.9004 14.1016 10.9004 11.8891C10.9004 9.62656 12.7254 7.78906 15.0004 7.78906C17.2629 7.78906 19.1004 9.62656 19.1004 11.8891C19.0879 14.1016 17.3504 15.9016 15.1504 15.9766Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M23.4252 24.2242C21.2002 26.2617 18.2502 27.4992 15.0002 27.4992C11.7502 27.4992 8.8002 26.2617 6.5752 24.2242C6.7002 23.0492 7.4502 21.8992 8.7877 20.9992C12.2127 18.7242 17.8127 18.7242 21.2127 20.9992C22.5502 21.8992 23.3002 23.0492 23.4252 24.2242Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ProfileIcon;
