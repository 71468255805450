import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import SectionHeader from "../../../components/SectionHeader";
import ViewSwitchButton from "../../../components/ViewSwitchButton";
import { IconButton } from "@mui/material";
import ThreeDotMenu from "../../../components/ThreeDotMenu";
import { ThreeDots } from "../../../assets/Icons/ThreeDot";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { EditUnderLineIcon } from "../../../assets/Icons/EditIcon";
import styles from "./TicketManagementTable.module.scss";
import { hooks } from "../../../utils/index";
import Kanbanview from "./Kanbanview";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../utils/useDebounce";
import TicketFilter from "./TicketFilter";
import {
  areAllValuesNull,
  covertDate,
  dateFormatter,
  setHeaderProp,
} from "../../../utils/helper";
import {
  ticketLabelMappingColor,
  ticketlabelMappingBackground,
} from "../../../utils/contants";
import { useAuth } from "../../../utils/hooks";
import { TrashIcon } from "../../../assets/Icons/TrashIcon";
import DeleteModal from "../../../components/DeleteModal";
import ErrorModal from "../../../components/DeleteModal/ErrorModal";

const TicketManagementTable = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const [listView, setListView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    name: "dueDate",
    direction: "asc",
  });
  const [ticketFilter, setTicketFilter] = useState({
    clientName: "",
    ticketStatus: "",
    dueDate: null,
    assignee: "",
    partner: "",
  });
  const [search, setSearch] = useState("");

  const { user } = useAuth();
  const {
    useGetTicketList,
    openDeleteModal,
    setOpenDeleteModal,
    isDeleteRequestLoading,
    errorText,
    setErrorText,
    mutateDeleteRequest,
  } = hooks.useTicket();
  const debouncedSearchValue = useDebounce(search, 1000);

  /**
   * Extracts relevant filter data from the ticketFilter object and assigns default values if necessary.

   */
  const filterData = {
    clientName: ticketFilter.clientName?.label || null,
    ticketStatus: ticketFilter.ticketStatus?.value || null,
    dueDate: dateFormatter(ticketFilter.dueDate) || null,
    assignee: `${ticketFilter.assignee?.value || ""}` || null,
  };

  const mode = listView ? "list" : "tab";
  const ticketPage = listView ? page + 1 : 1;
  const ticketPageCount = listView ? count : 1000;
  const ticketSortOrder = listView
    ? sortOrder
    : { name: "dueDate", direction: "asc" };

  const { data: ticketListData, isLoading: isTicketListLoading } =
    useGetTicketList(
      mode,
      ticketPage,
      ticketPageCount,
      debouncedSearchValue,
      filterData,
      ticketSortOrder
    );

  useEffect(() => {
    if (page > 0) {
      setPage(0);
    }
  }, [debouncedSearchValue, ticketFilter]);

  const handleOpenRowMenu = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseThreeDotMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const isDeleteVisible = (ticketData) => {
    return (
      user?.RoleName === "Admin" &&
      (ticketData?.ticketStatus === "Cancelled" ||
        ticketData?.ticketStatus === "Completed")
    );
  };

  const isEditDisabled = (ticketData) => {
    if (
      ticketData?.ticketStatus === "Cancelled" ||
      ticketData?.ticketStatus === "Completed"
    ) {
      return true;
    } else if (
      user?.RoleName === "Admin" ||
      ticketData?.createdByUserId === user?.UserID ||
      ticketData?.assigees?.includes(Number(user?.UserID))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const threeDotMenuItem = [
    {
      name: "View Details",
      onClickFunction: () => {
        setAnchorEl(null);
        navigate(`view/${selectedRow?.ticketID}`);
      },
      icon: (
        <RemoveRedEyeOutlinedIcon
          sx={{ color: "var(--primary-color)", fontSize: "20px" }}
        />
      ),
    },
    {
      name: "Edit Details",
      onClickFunction: () => {
        setAnchorEl(null);
        navigate(`edit/${selectedRow?.ticketID}`);
      },
      icon: <EditUnderLineIcon />,
      hide: isEditDisabled(selectedRow),
    },
  ];

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedRow(row);
    setOpenDeleteModal(true);
  };

  let changeBackGround = 8,
    prev = 0,
    toogle = 0;
  const setCellProp = (e) => {
    if (prev === changeBackGround) {
      prev = 0;
      toogle++;
    }
    prev++;
    return {
      style: {
        left: "0",
        background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
        zIndex: 100,
        padding: "0!important",
        textTransform: "capitalize",
        textAlign: "center",
        color: "var(--primary-color)",
        fontWeight: "500",
        maxWidth: "100px",
      },
    };
  };

  const cellOptions = {
    setCellProps: setCellProp,
    setCellHeaderProps: setHeaderProp,
  };

  const columns = [
    {
      name: "ticketID",
      label: "ID",
      options: {
        ...cellOptions,
        customBodyRender: (value) => {
          return <div>#{value}</div>;
        },
      },
    },
    {
      name: "clientName",
      label: "Client Name",
      options: cellOptions,
    },
    {
      name: "ticketStatus",
      label: "Ticket Status",
      options: cellOptions,
    },
    {
      name: "checkList",
      label: "Checklist",
      options: cellOptions,
    },
    {
      name: "dueDate",
      label: "Due Date",

      options: {
        ...cellOptions,
        customBodyRender: (value) => {
          return <div>{covertDate(value, "MM/DD/YYYY HH:mm:ss")}</div>;
        },
      },
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      options: cellOptions,
    },
    {
      name: "ticketLabel",
      label: "Ticket Labels",
      options: {
        ...cellOptions,
        customBodyRender: (value) => {
          return (
            value && (
              <div
                className={styles.ticketLabel}
                style={{
                  color: ticketLabelMappingColor[value],
                  backgroundColor:
                    ticketlabelMappingBackground[value] || "transparent",
                }}
              >
                {value}
              </div>
            )
          );
        },
      },
    },
    {
      name: "Action",
      label: "",
      options: {
        ...cellOptions,
        customBodyRender: (value, tableMeta) => {
          const rowData = ticketListData?.results[tableMeta.rowIndex];
          return (
            <div className={styles.action}>
              {isDeleteVisible(rowData) && (
                <IconButton onClick={(e) => handleDeleteClick(e, rowData)}>
                  <TrashIcon />
                </IconButton>
              )}
              <IconButton onClick={(e) => handleOpenRowMenu(e, rowData)}>
                <ThreeDots />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const onKanbanClick = () => {
    setListView(false);
  };
  const onListClick = () => {
    setListView(true);
  };

  const handleChangePage = (_page) => {
    setPage(_page);
  };
  const handleChangePageCount = (_pageCount) => {
    setCount(_pageCount);
    const totalPages = Math.ceil(ticketListData?.totalActiveRecords / _pageCount)

    if(page >= totalPages) {
      setPage(totalPages - 1)
    }
  };

  const onTableChange = (action, tableState) => {
    if (action === "sort") {
      setSortOrder(tableState.sortOrder);
    }
  };

  const handleArchive = () => {
    const finalData = {
      ticketID: selectedRow?.ticketID,
      status: "Archived",
      ticketOwnersDetails: [],
    };
    mutateDeleteRequest(finalData);
  };

  return (
    <div className={styles.ticketManagementDashboard}>
      <div className={styles.viewSwitchButtonContainer}>
        <ViewSwitchButton
          buttonClicked={listView}
          onKanbanClick={onKanbanClick}
          onListClick={onListClick}
        />
      </div>
      <SectionHeader
        buttonText="New Request"
        onButtonClick={() => {
          navigate("add");
        }}
        placeholder="Search"
        searchText={search}
        handleSearchValue={(e) => setSearch(e.target.value)}
      >
        <TicketFilter
          ticketFilter={ticketFilter}
          setTicketFilter={setTicketFilter}
        />
      </SectionHeader>

      {listView ? (
        <CustomTable
          data={ticketListData?.results || []}
          columns={columns}
          isLoading={isTicketListLoading}
          pagination
          paginationServer
          totalCount={ticketListData?.totalActiveRecords || 0}
          noDataText={
            debouncedSearchValue || !areAllValuesNull(filterData)
              ? "No Tickets Found"
              : "No Tickets Added"
          }
          page={page}
          onTableChange={onTableChange}
          countPerPage={count}
          onChangePage={handleChangePage}
          onChangeCountPerPage={handleChangePageCount}
          sortOrder={sortOrder}
        />
      ) : (
        <Kanbanview
          ticketKanbanData={ticketListData?.results || []}
          isLoading={isTicketListLoading}
          handleDeleteClick={handleDeleteClick}
          isDeleteVisible={isDeleteVisible}
        />
      )}
      <ThreeDotMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseThreeDotMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        menuItem={threeDotMenuItem}
      />
      {openDeleteModal && selectedRow && (
        <DeleteModal
          open={openDeleteModal}
          handleCancel={() => setOpenDeleteModal(false)}
          handleDeleteClick={handleArchive}
          primaryText={`Are you sure want to delete this request?"`}
          isButtonDisabled={isDeleteRequestLoading}
        />
      )}
      {errorText && (
        <ErrorModal
          open={!!errorText}
          handleClose={() => setErrorText("")}
          errorText={errorText}
        />
      )}
    </div>
  );
};

export default TicketManagementTable;
