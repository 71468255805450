import React from "react";
import ModuleWrapper from "../../components/ModuleWrapper";
import TicketManagementTable from "./TicketManagementTable";
import moduleImage from "../../assets/Images/dashboard-images/frame.svg";

const TicketManagement = () => {
  return (
    <ModuleWrapper moduleHeader="Manage Requests" moduleImage={moduleImage}>
      <TicketManagementTable />
    </ModuleWrapper>
  );
};

export default TicketManagement;
