import React, { useState, createContext, useContext } from "react";
import { createReminderRequest, saveAutoReminderRequest, getReminderRequest } from "../../controllers/reminders";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { isTokenExpired } from "../../helper";
import { TokenExpiry } from "../Auth/TokenExpiry";
import PropTypes from "prop-types";

const Reminderrequestcontext = createContext();

export const UseReminderRequestContext = () =>
  useContext(Reminderrequestcontext);

const UseHandleReminderRequestData = () => {
  const [storeReminderData, setStoreReminderData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [storeTextAreaValue, setStoreTextAreaValue] = useState([]);
  const [storeCheckBoxData, setStoreCheckBoxData] = useState([]);
  const [autoReminderData, setAutoReminderData] = useState({
    dueDate: "",
    radioButtonValue: "",
    toggleCheckbox: false,
    checkedStatus: false
  });
  const [storeReminderRequests, setStoreReminderRequests] = useState(null);

  const queryClient = useQueryClient();

  /**
   * Custom hook that uses a mutation to create a reminder request. Handles success and error cases.
   * @param {Function} createReminderRequest - The function to create a reminder request.
   * @returns {{
   *   mutateReminderRequestData: Function, 
   *   isLoading: boolean
   * }} An object containing the function to mutate reminder request data and a boolean indicating loading state.
   */
  const { mutate: mutateReminderRequestData, isLoading: sendReminderLoader } =
    useMutation(createReminderRequest, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else {
          toast.success("Reminders Sent Successfully!");
          setModalOpen(false);
          setStoreTextAreaValue([]);
          setDisableButton(true);
          setStoreCheckBoxData([]);
          queryClient.refetchQueries("ticketById");
        }
      },
      onError: (err) => {
        console.log(err);
        toast.error(err.message);
      },
    });

  /**
   * use mutation hook to mutate the auto reminder data.
   * @returns {{
   * mutateAutoReminderRequest: Function,
   * isLoading: boolean
   * }}
   */
  const { mutate: mutateAutoReminderRequestData, isLoading: autoReminderLoader } =
    useMutation(saveAutoReminderRequest, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else {
          if (!autoReminderData.checkedStatus && autoReminderData.radioButtonValue === '') {
            toast.success("Auto Reminder Turned Off!");
            setModalOpen(false);
            setStoreTextAreaValue([]);
            setDisableButton(true);
            setStoreCheckBoxData([]);
            setAutoReminderData((prevState) => ({
              ...prevState,
              dueDate: "",
              radioButtonValue: "",
              checkedStatus: false,
              toggleCheckbox: false
            }))
          } else {
            toast.success(data?.data.messages[0]);
            setModalOpen(false);
            setStoreTextAreaValue([]);
            setDisableButton(true);
            setStoreCheckBoxData([]);
            setAutoReminderData((prevState) => ({
              ...prevState,
              dueDate: "",
              radioButtonValue: "",
              checkedStatus: false,
              toggleCheckbox: false
            }))
            queryClient.refetchQueries("ticketById");
          }
        }
      },
      onError: (err) => {
        console.log(err);
        toast.error(err.message);
      },
    });

  /**
   * custom react query hook to call the server and fetch auto reminder requests.
   */


  const { mutate: mutateGetReminderRequest, isLoading: getReminderRequestLoader } = useMutation(
    getReminderRequest,
    {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else {
          const reminderRequests = data?.data.result;
          setStoreReminderRequests(reminderRequests);
          setAutoReminderData((prevState) => ({
            ...prevState,
            dueDate: "",
            radioButtonValue: "",
            checkedStatus: false,
            toggleCheckbox: false
          }))
        }

      },
      cacheTime: 0,
      onError: (err) => {
        console.log(err, "===== error");
      },
    }
  );



  /**
   * Handles the reminder request data by calling the mutateReminderRequestData function with the provided data and id.
   * @param {any} data - The data to be handled.
   * @param {string} id - The identifier for the reminder request data.
   */
  const handleReminderRequestsData = (data, id) => {
    mutateReminderRequestData({ data, id });
  };

  return {
    handleReminderRequestsData,
    storeReminderData,
    setStoreReminderData,
    modalOpen,
    setModalOpen,
    sendReminderLoader,
    disableButton,
    setDisableButton,
    storeTextAreaValue,
    setStoreTextAreaValue,
    storeCheckBoxData,
    setStoreCheckBoxData,
    autoReminderData,
    setAutoReminderData,
    mutateAutoReminderRequestData,
    autoReminderLoader,
    mutateGetReminderRequest,
    getReminderRequestLoader,
    storeReminderRequests,
    setStoreReminderRequests
  };
};

export function ProvideReminderRequest({ children }) {
  const reminderRequestData = UseHandleReminderRequestData();
  return (
    <Reminderrequestcontext.Provider value={reminderRequestData}>
      {children}
    </Reminderrequestcontext.Provider>
  );
}

ProvideReminderRequest.propTypes = {
  children: PropTypes.node.isRequired,
};
