import React, { useEffect } from 'react';
import CustomTable from '../../components/CustomTable';
import styles from "./Archive.module.scss";
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { covertDate, setHeaderProp } from "../../utils/helper";
import { UseUserArchiveListContext } from "../../utils/hooks/Archive";
import CustomButton from "../../components/Button";
import { BasicModal } from '../../components/Modal/Modal';
import { Box } from "@mui/material";

export default function ClientArchiveTable({ onChangePage, onChangeCountPerPage, handleClientList, debouncedSearchValue }) {

    const { storeArchiveData, setStoreArchiveData, isEditClientLoading, handleClientUnarchving } = UseUserArchiveListContext();

    useEffect(() => {
        const data = {
            page: storeArchiveData.page + 1,
            tableCount: storeArchiveData.count,
            searchValue: debouncedSearchValue
        }
        handleClientList(data);

    }, [debouncedSearchValue, storeArchiveData.page])


    let changeBackGround = 5,
        prev = 0,
        toogle = 0;
    const setCellProp = (e) => {
        if (prev === changeBackGround) {
            prev = 0;
            toogle++;
        }
        prev++;

        return {
            style: {
                left: "0",
                background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
                zIndex: 100,
                padding: "0!important",
                textTransform: "capitalize",
                textAlign: "center",
                color: "var(--primary-color)",
                marginLeft: "10px",
                marginRight: "10px",
                fontWeight: "500",
            },
        };
    };

    const handleClientUnarchiveModal = (value, clientDetails) => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            userName: clientDetails.name,
            clientDetails,
            modalOpen: value + 1
        }))
    }

    const handleModalClose = () => {
        setStoreArchiveData((prevState) => ({
            ...prevState,
            // userName: "",
            modalOpen: false
        }))
    }

    const handleClientListUnarchiving = () => {
        const clientListData = storeArchiveData?.clientDetails;

        const requestPayload = {
            "clientName": clientListData?.name,
            "isActive": true,
            "status": "Active",
        }

        handleClientUnarchving(requestPayload, clientListData.id);

    }


    const columns = [
        {
            name: "id",
            label: "Client Id",

            options: {
                customBodyRenderLite: (value, tableMeta) => {
                    const clientId = storeArchiveData?.clientArchiveList?.[value]?.id;
                    return <p>{clientId}</p>;
                },

                setCellProps: setCellProp,
                setCellHeaderProps: setHeaderProp,
            },
        },
        {
            name: "name",
            label: "Client Name",

            options: {
                customBodyRenderLite: (value, tableMeta) => {
                    const clientName = storeArchiveData?.clientArchiveList?.[value]?.name;
                    return <p>{clientName}</p>;
                },
                setCellProps: setCellProp,
                setCellHeaderProps: setHeaderProp,
            },
        },
        {
            name: "groupName",
            label: "Group Name",

            options: {
                customBodyRenderLite: (value, tableMeta) => {
                    const groupName = storeArchiveData?.clientArchiveList?.[value]?.groupName;
                    return <p>{groupName === null ? "NA" : groupName}</p>;
                },
                setCellProps: setCellProp,
                setCellHeaderProps: setHeaderProp,
            },
        },
        {
            name: "deletedAt",
            label: "Archived Date",

            options: {
                customBodyRender: (value) => {
                    return <p>{covertDate(value)}</p>;
                },
                setCellProps: setCellProp,
                setCellHeaderProps: setHeaderProp,
            },
        },
        {
            name: "clientName",
            label: "Unarchive",
            options: {
                customBodyRenderLite: (value, tableMeta) => {
                    const clientDetails = storeArchiveData?.clientArchiveList?.[value];
                    return (
                        <div className={styles.actionItemWrapper}>
                            <UnarchiveOutlinedIcon onClick={() => handleClientUnarchiveModal(value, clientDetails)} sx={{ cursor: "pointer" }} />
                        </div>
                    );
                },
                sort: false,
                setCellProps: setCellProp,
                setCellHeaderProps: setHeaderProp,
            },
        },
    ];

    return (
        <>
            <BasicModal open={storeArchiveData.modalOpen} maxWidth="lg">
                <div className={styles.modalContent}>
                    <p>Are you sure you want to unarchive this user - <span>"{storeArchiveData.userName}"</span>?</p>
                    <Box sx={{ display: "flex" }}>
                        <CustomButton
                            sx={{ marginTop: "10px" }}
                            variant="text"
                            style={{ color: "#f00", fontWeight: "500", marginRight: "10px" }}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            sx={{ marginTop: "10px" }}
                            onClick={handleClientListUnarchiving}
                        >
                            Yes
                        </CustomButton>
                    </Box>
                </div>
            </BasicModal>
            <CustomTable
                data={storeArchiveData?.clientArchiveList || []}
                columns={columns}
                noDataText="No Data Found"
                isLoading={isEditClientLoading}
                pagination
                paginationServer
                totalCount={storeArchiveData?.totalActiveRecord || 0}
                page={storeArchiveData.page}
                countPerPage={storeArchiveData.count}
                onChangePage={onChangePage}
                onChangeCountPerPage={onChangeCountPerPage}
            />
        </>
    )
}
