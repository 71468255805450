import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import { useAuth } from "../../utils/hooks/Auth";
import { getStorageValue } from "./cookies";
import { pattern } from "../../utils/validationPattern";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";

export default function Loginwrapper() {
  const {
    handleUserLogin,
    checkedStatus,
    setCheckedStatus,
    handleLoginWithSso,
    organizationCode,
  } = useAuth();
  const { instance, inProgress } = useMsal();
  const { result } = useMsalAuthentication();
  const accessLoginToken = getStorageValue("access_token");
  const navigate = useNavigate();
  const [toggleIcon, setToggleIcon] = useState(false);

  /**
   * use effect hook to call the sso login api.
   * the condition checks if result is not null and it has the id token.
   * then it triggers the api call by sending the request payload as a paramter.
   * @param result - result is an object which consists of various properties taken from the useMsalAuthentication hook.
   * @return none
   */

  useEffect(() => {
    if (result?.idToken) {
      const requestPayload = {
        id_token: result?.idToken,
        customercode: organizationCode,
      };
      handleLoginWithSso(requestPayload);
    }
  }, [result]);

  /**
   * state to store the input data in the on change function.
   */

  const [formData, setFormData] = useState({
    organizationName: "",
    email: "",
    password: "",
  });

  /**
   * this state has been added to store all the respective fields error messages.
   */

  const [inputError, setInputError] = useState({
    organizationError: false,
    emailError: false,
    invalidDomain: false,
    passwordError: false,
  });
  // for adding the error message.
  const [errorMessage, setErrorMessage] = useState(false);

  // for button enable/disable.
  const [loginButtonEnabled, setLoginButtonEnabled] = useState(false);

  // function for toggling the password show/hide.
  const togglePasswordIcon = () => {
    setToggleIcon(!toggleIcon);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedEmail = value.trim();

    // Validation rules
    if (name === "email") {
      if (!pattern.email.test(trimmedEmail) && value !== "") {
        setInputError((prevData) => ({
          ...prevData,
          emailError: true,
        }));
        setLoginButtonEnabled(true);
      } else {
        setInputError((prevData) => ({
          ...prevData,
          emailError: false,
        }));
      }
    }
    if (name === "password") {
      if (!pattern.password.test(value) && value !== "") {
        setInputError((prevData) => ({
          ...prevData,
          passwordError: true,
        }));
        setLoginButtonEnabled(true);
      } else {
        setInputError((prevData) => ({
          ...prevData,
          passwordError: false,
        }));
      }
    }
    // Set form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset general error message
    setErrorMessage(false);
  };

  // use effect to check for empty fields.
  useEffect(() => {
    if (
      formData.email === "" ||
      formData.password === "" ||
      inputError.emailError ||
      inputError.passwordError
    ) {
      setLoginButtonEnabled(true);
    } else {
      setLoginButtonEnabled(false);
    }
  }, [formData, inputError]);

  const handleLoginForm = useCallback(() => {
    const cookieStorageKey = `${organizationCode}_${formData.email}`.toLowerCase()
    const token = getStorageValue(cookieStorageKey);
    const loginFormData = {
      email: formData.email,
      customerCode: organizationCode,
      password: formData.password,
      token,
    };
    const saveFormData = {
      email: formData.email,
      password: formData.password,
    };

    if (formData.email === "" || formData.password === "") {
      setErrorMessage(true);
    } else {
      if (checkedStatus) {
        localStorage.setItem("saveFormData", JSON.stringify(saveFormData));
      } else {
        localStorage.removeItem("saveFormData");
      }
      // Encrypt the payload
      // const encryptedPayload = CryptoJS.AES.encrypt(
      //     JSON.stringify(loginFormData),
      //     encryptionKey
      // ).toString();
      // console.log(encryptedPayload, "===== encryptedPayload");

      handleUserLogin(loginFormData);
    }
  }, [formData, checkedStatus]);

  const handleFormSubmission = useCallback(
    (event) => {
      // Disallow space at the beginning
      if (event.key === " " && formData.email.trim() === "") {
        event.preventDefault();
        return false;
      } else if (event.key === "Enter") {
        handleLoginForm();
      }
    },
    [formData]
  );

  // to handle the page redirection.
  useEffect(() => {
    if (organizationCode) {
      if (accessLoginToken) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    } else {
        navigate('/')
    }
  }, [accessLoginToken, organizationCode]);

  const fetchSsoDetails = JSON.parse(localStorage.getItem("sso_details"));

  useEffect(() => {
    const loginWithSSO = fetchSsoDetails?.sso;
    // Effect for checkedStatus
    const storedState = localStorage.getItem("checked");
    setCheckedStatus(storedState ? JSON.parse(storedState) : false);

    // Effect for loginFormData
    const storedFormData = localStorage.getItem("saveFormData");
    if (storedFormData) {
      // Parse and set the stored data in the form
      const parsedFormData = JSON.parse(storedFormData);
      setFormData({
        email: loginWithSSO ? "" : parsedFormData.email || "",
        organizationName: loginWithSSO ? "" : parsedFormData.customerCode || "",
        password: loginWithSSO ? "" : parsedFormData.password || "",
      });
    }
  }, [fetchSsoDetails?.sso]);

  // to handle the remember me functionality.
  const handleRememberMe = useCallback(
    (e) => {
      setCheckedStatus(e.target.checked);
      localStorage.setItem("checked", e.target.checked);
    },
    [checkedStatus]
  );

  const handleMicrosoftLogin = async () => {
    if (inProgress === "none") {
      try {
        await instance.loginPopup();
      } catch (err) {
        console.log(err, "====== error");
      }
    }
  };

  return (
    <div>
      <Login
        togglePasswordIcon={togglePasswordIcon}
        toggleIcon={toggleIcon}
        handleInputChange={handleInputChange}
        formData={formData}
        errorMessage={errorMessage}
        handleLoginForm={handleLoginForm}
        inputError={inputError}
        handleRememberMe={handleRememberMe}
        handleFormSubmission={handleFormSubmission}
        loginButtonEnabled={loginButtonEnabled}
        handleMicrosoftLogin={handleMicrosoftLogin}
      />
    </div>
  );
}
