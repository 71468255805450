import React from 'react';
import CustomButton from '../../components/Button';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useUserBulkUpload } from '../../utils/hooks/User/BulkUpload';
import styles from "./UserManagement.module.scss";

export default function BulkUploadUserModal() {
    const { bulkUploadData, setBulkUploadData } = useUserBulkUpload();

    const base64Data = bulkUploadData.storeCsvFile;
    // Decode base64 string to binary data
    const binaryData = atob(base64Data);

    // Convert binary data to string (CSV content)
    const csvContent = binaryData;

    const handleCsvDownload = () => {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'filename.csv'; // Set desired file name
        downloadLink.click();
    };


    return (
        <>
            <div className={styles.bulkUploadModalContainer}>
                <ErrorIcon sx={{ fontSize: "80px", color: "#f00" }} />
                <h1>Some users were not added. Download the <span>rejected list</span> to reupload.</h1>
            </div>
            <div className={styles.modalBtnWrapper}>
                <CustomButton onClick={() => {
                    setBulkUploadData((prevState) => ({
                        ...prevState,
                        showBulkUploadModal: false
                    }))
                }}>Close</CustomButton>
                <CustomButton onClick={handleCsvDownload}>
                    Download Rejected List <ArrowRightAltIcon />
                </CustomButton>
            </div>
        </>
    )
}
